import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { setUserImageMapping, setImage, setImageLawyer } from "../../pages/UserAuthentication/AuthenticationSlice";
import {
    updateSaveAsTemplateChats, updateLegalReviewRequestChats, updateViewChatLinks, updateUserMessageCount,
    updateMessagingWithAiMessage, getAdditionalMessages, updateMessagingDisabled,
    setLastConversationalMessage, setMessagingSentAt
} from "../../pages/chatpage/chatSlice";
import { markThreadRead, addUnreadDraftThread, addUnreadAskThread } from "../../pages/chatpage/threadsSlice";

import { collection, onSnapshot, query, where, orderBy } from "firebase/firestore";
import { firestore } from "../../firebase";


const SendMessagesService = ({ chatId, toggleOpen, image }) => {
    const dispatch = useDispatch();

    const [messagesReadOnce, setMessagesReadOnce] = useState(false);

    const user = useSelector((state) => state.auth.user);
    const lawyerDetails = useSelector((state) => state.chat.lawyerDetails);

    const threadId = useSelector((state) => state.chat.threadId);
    const feedbackStatus = useSelector((state) => state.chat.feedbackStatus);
    const messages = useSelector((state) => state.chat.messages);
    // const image = useSelector((state) => state.auth.image);
    const imageLawyer = useSelector((state) => state.auth.imageLawyer);
    const lastMessageId = useSelector((state) => state.chat.lastMessageId);

    const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);

    useEffect(() => {
        if (feedbackStatus === "success") {
            toggleOpen();
            alert("Your feedback has been recorded. Thank you for your feedback.");
        }
    }, [feedbackStatus]);

    // useEffect(() => {
    //     if (user?.profileImage) {
    //         // debugger;
    //         dispatch(setImage(user.profileImage));
    //     } else {
    //         const firstNameInitial = user?.first_name ? user.first_name[0].toUpperCase() : "";
    //         const lastNameInitial = user?.last_name ? user.last_name[0].toUpperCase() : "";
    //         const name = user?.first_name ? firstNameInitial + lastNameInitial : user?.user_email?.[0]?.toUpperCase();
    //         dispatch(setImage(name))
    //     }
    // }, [user]);
    // set lawyer-image based on the lawyer profile image
    useEffect(() => {
        // debugger;
        if (lawyerDetails?.profileImage) {
            // setImageLawyer(lawyerDetails.profileImage);
            dispatch(setImageLawyer(lawyerDetails.profileImage))
        } else {
            const name = lawyerDetails?.first_name
                ? lawyerDetails.first_name[0].toUpperCase()
                : lawyerDetails?.user_email?.[0]?.toUpperCase();
            const path = `../../images/white/${name}.png`;
            dispatch(setImageLawyer(path))
        }
    }, [lawyerDetails]);


    useEffect(() => {
        var userImageMapping = {};
        userImageMapping["lexxa"] = require(`../../images/i-h-logo.png`);
        if (user) {
            // debugger;
            userImageMapping[user.user_id] = image;
        }
        if (messages.length > 0) {
            var countUserMessage = 0;
            messages.forEach(function (message) {
                console.log("image", image)
                if (message.user != null) {
                    if (message.user.user_type === "lawyer") {
                        userImageMapping[message.user.user_id] = imageLawyer;
                    } else {
                        userImageMapping[message.user.user_id] = image;
                    }
                }
                if (message.message_type === "conversation" && message.user === null && !messagesReadOnce) {
                    dispatch(setLastConversationalMessage(message.id));
                }
                if (message.user?.user_id === user?.user_id) {
                    countUserMessage += 1;
                }
                if (message.quick_action_buttons) {
                    var qabs = message.quick_action_buttons;
                    var templateButtons = [];
                    var legalButtons = [];
                    var hyperlinkButtons = [];
                    qabs.forEach(function (qab) {
                        // debugger;
                        if (qab.type === "hyperlink") {
                            hyperlinkButtons.push(message.id)
                            // dispatch(updateViewChatLinks(message.id))
                        }
                        if (qab.type === "save_as_template") {
                            templateButtons.push(message.id);
                            // dispatch(updateSaveAsTemplateChats(message.id));
                        }
                        if (qab.type === "legal_review_request") {
                            legalButtons.push(message.id);
                            // dispatch(updateLegalReviewRequestChats(message.id));
                        }
                    })
                    if (templateButtons) {
                        dispatch(updateSaveAsTemplateChats(templateButtons));
                    }
                    if (legalButtons) {
                        dispatch(updateLegalReviewRequestChats(legalButtons));
                    }
                    if (hyperlinkButtons) {
                        dispatch(updateViewChatLinks(hyperlinkButtons));
                    }
                }
            });
            // debugger;
            dispatch(updateUserMessageCount(countUserMessage));
        }
        dispatch(setUserImageMapping(userImageMapping))
        if (messages.length > 0) {
            setMessagesReadOnce(true)
        }
    }, [messages, image]); // eslint-disable-line


    // useEffect(() => {
    //     // debugger;
    //     if (user && user.user_email) {
    //         // debugger;
    //         var cid = chatId ? chatId : threadId;
    //         const collectionRef = collection(firestore, `frontend-updates-client/${user.user_email}/new-messages`);
    //         var date = new Date();
    //         var q = query(collectionRef, where("timestamp", ">", date.toISOString()), orderBy("timestamp", "asc"));
    //         // console.log(
    //         //   "adding listener for thread " + cid + " timestamp: " + date.toISOString() + " time: " + new Date().toISOString()
    //         // );

    //         const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //             querySnapshot.docChanges().forEach((doc) => {

    //                 // console.log(doc.doc.data());
    //                 var thread_id = doc.doc.data()["thread_id"];
    //                 var aiMessage = doc.doc.data()["ai_message"];
    //                 var isMessagingDisabled = doc.doc.data()["messaging_disabled"];
    //                 var newMessageAvailable = doc.doc.data()["new_message_available"];
    //                 // var isNewMessageAvailable = doc.doc.data()["new_message_available"];
    //                 var notificationMode = doc.doc.data()["mode"];
    //                 if (doc.type === "added") {
    //                     if (thread_id !== cid) {
    //                         if (notificationMode === "ask") {
    //                             dispatch(addUnreadAskThread(thread_id));
    //                         } else if (notificationMode === "draft") {
    //                             dispatch(addUnreadDraftThread(thread_id));
    //                         }
    //                     } else if (lastMessageId && cid && thread_id === cid && getThreadStatus === "success") {
    //                         // if this is the current thread
    //                         if (aiMessage) {
    //                             dispatch(updateMessagingWithAiMessage({ message: aiMessage }));
    //                             localStorage.setItem("newAiMessage", aiMessage.id);
    //                         } else if (newMessageAvailable) {
    //                             dispatch(getAdditionalMessages({ chatId: cid, messageId: lastMessageId }));
    //                         }
    //                         if (aiMessage && aiMessage.message_type === "conversation") {
    //                             dispatch(setLastConversationalMessage(aiMessage.id));
    //                         }
    //                         dispatch(updateMessagingDisabled(isMessagingDisabled));
    //                         if (isMessagingDisabled) {
    //                             dispatch(setMessagingSentAt(new Date()));
    //                         }
    //                         else {
    //                             dispatch(setMessagingSentAt(null));
    //                         }
    //                         // if this is the current thread, mark thread as read
    //                         dispatch(markThreadRead({ threadId: thread_id }));
    //                     }
    //                 }
    //             });
    //         });
    //         return () => {
    //             unsubscribe();
    //         };
    //     }
    //     // getThreadStatus == list-messages fetch status
    // }, [getThreadStatus, user?.user_email]); // eslint-disable-line

    useEffect(() => {
        if (feedbackStatus === "success") {
            toggleOpen();
            alert("Your feedback has been recorded. Thank you for your feedback.");
        }
    }, [feedbackStatus]);
};

export default SendMessagesService;
