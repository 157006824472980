import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getSharedThread } from "./chatSlice";
import ChatLayout from "../../components/chat/ChatLayout";

import file_plus from "../../images/file-plus.svg";
import paper_plane from "../../images/paper-plane.svg";
import Chat from "../../components/chat/Chat";

import TextArea from "antd/es/input/TextArea";

function Shared() {
  const [messageList, setMessageList] = useState([]);
  const [image, setImage] = useState(null);
  const [imageLawyer, setImageLawyer] = useState("../../images/lawyer.png");

  // const images = require("../../images/", true);
  const messages = useSelector((state) => state.chat.messages);
  const sendMessageStatus = useSelector((state) => state.chat.sendMessageStatus);

  const getSharedThreadStatus = useSelector((state) => state.chat.getSharedThreadStatus);

  const messagingDisabled = useSelector((state) => state.chat.messagingDisabled);

  const newThreadCreated = useSelector((state) => state.chat.newThreadCreated);

  const textExtractionStatus = useSelector((state) => state.chat.textExtractionStatus);

  const messageListRef = useRef(null);

  let { chatId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const path = `../../images/white/A.png`;
    setImage(path);
    const pathLawyer = require(`../../images/lawyer.png`);
    setImageLawyer(pathLawyer);
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      setMessageList(messages);
    }
  }, [messages]); // eslint-disable-line

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getSharedThread(chatId));
  }, [chatId]); // eslint-disable-line

  useEffect(() => {
    // Scroll to the bottom when messageList or sendMessageStatus changes
    scrollContainerToBottom();
  }, [messages, sendMessageStatus, messageList]);

  const scrollContainerToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  return (
    <ChatLayout>
      <section className="chat-body h-full w-full flex ">
        <div className="flex fex-row  w-full h-full">
          <div className=" h-full w-full flex flex-col justify-between items-center">
            {!chatId ? (
              // case 1
              <div className="h-full w-full max-w-[913px]">
                {sendMessageStatus === "loading" ? (
                  <div className="h-full w-full  m-auto pt-[50px] overflow-y-scroll">
                    <div className="w-full h-full flex justify-center items-center">
                      {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                      <div class="rotating-cursor"></div>
                    </div>
                  </div>
                ) : (
                  !newThreadCreated && (
                    <div className="w-full h-full flex flex-col justify-center items-center gap-[70px] "></div>
                  )
                )}
              </div>
            ) : getSharedThreadStatus === "loading" ? (
              // case 2
              <div className="h-full w-full max-w-[913px] m-auto pt-[50px] overflow-y-scroll">
                <div className="w-full h-full flex justify-center items-center">
                  {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                  <div class="rotating-cursor"></div>
                </div>
              </div>
            ) : (
              <div ref={messageListRef} className="h-full w-full max-w-[913px] m-auto pt-[50px] overflow-y-scroll">
                {messageList.length === 0 ? (
                  <div className="w-full h-full flex justify-center items-center">No messages to show</div>
                ) : null}

                <>
                  {messageList.length > 0
                    ? messageList.map((message, index) => (
                      <Chat
                        clickCopy={() => {
                          navigator.clipboard.writeText(message.text);
                        }}
                        isLastReply={index === messageList.length - 1}
                        isLastAiReply={
                          index === messageList.length - 1 ||
                          (index === messageList.length - 2 && message.user === null)
                        }
                        key={message.id}
                        isAIReply={message.user_type == "ai" ? true : false}
                        imageUrl={
                          message.user_type == "client"
                            ? image
                            : message.user_type === "lawyer"
                              ? imageLawyer
                              : require(`../../images/lexxa-icon.png`)
                        }
                        chatInfo={message}
                        viewerMode="client"
                      />
                    ))
                    : null}
                </>
              </div>
            )}

            <div className="w-full flex flex-col items-center justify-center">
              <div className="w-full py-2 justify-center items-center bg-[#F7F7F7]">
                <p className="text-center text-[#757575] text-[12px] font-[500]">
                  Always consult your lawyer before relying on InHouse AI
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ChatLayout>
  );
}

export default Shared;
