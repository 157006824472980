import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NonCASubscription from "./NonCaliforniaSubscription";
import CASubscription from "./CaliforniaSubscription";
import CALegalReviewRequest from "./CaliforniaLegalReviewRequest";
// import { refreshSubscriptionStatus } from "../../pages/UserAuthentication/AuthenticationSlice"
import Modal from '../../components/Modal/index';
import { getCheckoutUrl, setShowSubscriptionModal } from "../../pages/UserAuthentication/AuthenticationSlice";
import SelfHelpFullServiceModalContent from "./selfHelpFullServiceModalContent"


import MarathanLogo from "../../images/marathan-logo.png";
import PresenceLogo from "../../images/PresenceLogo.png";
import RanavatLogo from "../../images/RanavatLogo.png";
import HadrianLogo from "../../images/HadrianLogo.png";


const SubscriptionModal = ({ user, userAction }) => {
    const dispatch = useDispatch();
    const checkoutUrl = useSelector((state) => state.auth.checkoutUrl);
    const showSubscriptionModal = useSelector((state) => state.auth.showSubscriptionModal);

    return (
        <Modal
            isOpen={showSubscriptionModal}
            // isOpen={true}
            checkoutUrl={checkoutUrl}
            onClose={() => { dispatch(setShowSubscriptionModal(false)) }}

        >
            <div className=" w-[896px] overflow-scroll flex flex-col pb-[20px]">
                <div className="flex flex-col items-start w-full ">
                    <SelfHelpFullServiceModalContent setShowSubscriptionModal={setShowSubscriptionModal} checkoutUrl={checkoutUrl} />
                    {/* 
                        action type: new-thread:
                            if the state == california ===> show california subscription content
                            else:
                                show non california subscription content
                        
                        action type: legal-review-request:
                            if the state == california ===> show california legal review request content
                            else:
                                show non california legal review request modal, not this subscription modal

                    */}
                    {/*
                    {
                        userAction === "new-thread" &&
                        <>
                            {user?.state !== "California" ?
                                <NonCASubscription setShowSubscriptionModal={setShowSubscriptionModal} />
                                :
                                <CASubscription setShowSubscriptionModal={setShowSubscriptionModal} />}
                        </>
                    }

                    {
                        userAction === "legal-review-request" && user?.state === "California" &&
                        <CALegalReviewRequest setShowSubscriptionModal={setShowSubscriptionModal} />
                    }
                     <div class="w-100 pr-8 mt-3 flex flex-col items-center justify-between">
                        <div class="flex flex-col">
                            <a href={checkoutUrl} className="rounded w-fit  font-[FoundersGrotesk-medium] bg-[#5E59FF] my-3 pt-[16px] pb-[12px] px-[26px] text-[#fff] text-[20px] "
                                onClick={() => { window.analytics.track("Checkout Button Clicked", { "subscriptionType": "AI-Only-Plan-USD-Monthly", user_email: user?.user_email }) }}
                            // data-cb-type="checkout" data-cb-item-0="AI-Only-Plan-USD-Monthly" 
                            >
                                Try one month of unlimited for $99
                            </a>
                        </div>
                        <p className="text-[#626262] text-[28px] font-[FoundersGrotesk-light] top-[200px] leading-[33.6px] mt-0 align-middle pt-2">
                            Cancel Anytime, Satisfaction Guaranteed
                        </p>
                    </div>
                    <div className="flex flex-col w-full">
                        <div class="items-center align-items-center justify-between w-full flex flex-row gap-[10px] mt-3">
                            <img src={MarathanLogo} alt="Marathan" className="h-[64px] inline " />
                            <img src={PresenceLogo} alt="Presence" className="h-[64px] inline ml-3" />
                            <img src={RanavatLogo} alt="Ranavat" className=" h-[26px] inline ml-3" />
                            <img src={HadrianLogo} alt="Hadrian" className=" h-[26px] inline ml-3" />
                        </div>
                    </div> */}
                </div>
            </div>
        </Modal >
    );
};

export default SubscriptionModal;
