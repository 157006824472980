import React from 'react';
import { createPortal } from 'react-dom';
import './modal.css';
import useToggleAnimatedDialog from '../../hooks/useToggle';
import {
  X,
} from "@phosphor-icons/react";

const Modal = (props) => {

  const {
    title,
    children,
    body,
    bodyStyle,
    onClose,
    isOpen
  } = props;

  const [showPopup] = useToggleAnimatedDialog(isOpen, 300);

  // return null if portal element does not exist in DOM,
  // or if showPopup is false
  if (!document.getElementById('modal-portal') || !showPopup) return null;

  // use  createPortal() to render element at different location in DOM
  // even though it is written at different location in code.

  const backgroundAnimation = isOpen ? 'fade-in-animation' : 'fade-out-animation';
  const contentAreaAnimation = isOpen ? 'zoom-in-animation' : 'zoom-out-animation';

  return (createPortal(
    <div
      style={{
        animationName: backgroundAnimation
      }}
      className='popup-background'
    >
      <div style={{
        animationName: contentAreaAnimation
      }}
        className='content-area container relative'
      >
        {
          // if title is passed then only display this element
          title &&
          <div className='flex items-center justify-between'>
            {title}
          </div>
        }
        <div className='absolute right-8 top-8'>
          <X
            size={40}
            className="hover:cursor-pointer ml-auto"
            color="black"
            id="cancel-legal-review-button"
            onClick={onClose}
          />
        </div>
        <div className='overflow-scroll h-full py-12' style={{ ...bodyStyle }}>{children || body}</div>
      </div>
    </div>,
    document.getElementById('modal-portal')
  ));
}

export default Modal;