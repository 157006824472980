import { UserProfile } from '@clerk/clerk-react'

import Account from './Account';
import { House, UserCircleGear } from "@phosphor-icons/react";


const DotIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
        </svg>
    )
}
const UserProfilePage = () => {
    return (
        <div class="flex align-items-center mx-auto w-full">
            <div class="w-full mx-auto">
                <UserProfile >
                    <UserProfile.Page label="Onboarding" labelIcon={<UserCircleGear size={18} />} url="update-profile">
                        <Account />
                    </UserProfile.Page>
                    <UserProfile.Link label="Home" labelIcon={<House size={18} />} url="/ask" />
                </UserProfile>
            </div>
        </div >
    )
};

export default UserProfilePage;