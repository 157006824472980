import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signIn, getUserDetails, getLawyerInfo, signInWithGoogle, resetAuthError } from "./AuthenticationSlice";
import SignUpLayout from "../../components/SignUpLayout/SignUpLayout";
import "./Signin.css";
import { loadDataFromLocalStorage } from "../UserAuthentication/AuthenticationSlice";
import { Spinner } from "@phosphor-icons/react";
import google from "../../images/google.svg";
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";
import useValidatePageAccess from "../../hooks/useValidatePageAccess.js";
import { SignIn, SignUp } from "@clerk/clerk-react";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notice, setNotice] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [emailVerificationRequired, setEmailVerificationRequired] = useState(false);
  const [code, setCode] = useState();

  const authError = useSelector((state) => state.auth.signuperror);
  // const userType = useSelector(state => state.auth.userType);
  const user = useSelector((state) => state.auth.user);
  const signupStatus = useSelector((state) => state.auth.status);
  const signinStatus = useSelector((state) => state.auth.signupStatus);

  useEffect(() => {
    window.analytics.page("Signin page viewed");
    dispatch(resetAuthError());
  }, []);

  useEffect(() => {
    dispatch(getUserDetails())
  }, [window.location])

  useEffect(() => {
    // debugger;
    if (authError) {
      setNotice(authError.message);
      setShowLoading(false);
    } else {
      setNotice("");
    }
  }, [authError]);

  useEffect(() => {
    dispatch(getUserDetails())
  }, [window.location])

  window.location.href = "https://www.inhouse.so/auth";

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    console.log("validation check", re.test(String(email).toLowerCase()));

    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const loginWithUsernameAndPassword = async (e) => {
    // debugger;
    e.preventDefault();
    let form = document.getElementById("sign-up-form");
    console.log("show loading", true, "login with username password")
    setShowLoading(true);
    setNotice("");

    if (!validateEmail(email)) {
      setNotice("Please enter a valid email address.");
      console.log("show loading", false, "invalid email")
      setShowLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setNotice("Password must be at least 6 characters long.");
      setShowLoading(false);
      console.log("show loading", false, "invalid password")
      return;
    }

    // window.analytics.identify(email, {
    //   email: email,
    // });
    window.analytics.trackForm(form, "Signed In");
    window.analytics.track("Signed In", { user_email: user?.user_email });
    window.localStorage.setItem("highLightLegalReviewButton", true);
    window.localStorage.setItem("highDocumentUploader", true);
    var url = process.env.REACT_APP_FRONTEND_BASE_ENDPOINT + "/signin";
    if (code) {
      url = process.env.REACT_APP_FRONTEND_BASE_ENDPOINT + "/signin?referral_code=" + code;
    }
    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };
    dispatch(signIn({ email: email, password: password, actionCodeSettings: actionCodeSettings }));
  };

  const handleGoogleSubmit = () => {
    let form = document.getElementById("sign-up-form");
    window.analytics.trackForm(form, "Signed In with Google");
    window.analytics.track("Signed In with google", { user_email: user?.user_email });
    window.localStorage.setItem("highLightLegalReviewButton", true);
    window.localStorage.setItem("highDocumentUploader", true);
    dispatch(signInWithGoogle());
  };

  useEffect(() => {
    if (authError?.code === "auth/verify-email") {
      setEmailVerificationRequired(true);
    }
    if (signupStatus === "loading" || signinStatus === "loading") {
      setShowLoading(true);
    }
    else {
      setShowLoading(false);
    }
  }, [signupStatus, authError, signinStatus]);
  const [activeTab, setActiveTab] = useState('signin');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  return (
    <div className="px-auto mx-auto align-items-center items-center w-[400px] mt-[100px]">
      <div className="text-sm text-center text-gray border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px  text-[20px]">
          <li className="me-2 cursor-pointer" onClick={() => handleTabClick('signin')} >
            <span className={`inline-block p-4 border-b-2 rounded-t-lg ` + (activeTab === 'signin' ? "text-blue  border-blue-600  active dark:text-blue dark:border-blue-500" : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}>Sign In</span>
          </li>
          <li className="me-2 cursor-pointer" onClick={() => handleTabClick('signup')} >
            <span className={`inline-block p-4 border-b-2 rounded-t-lg ` + (activeTab === 'signup' ? "text-blue  border-blue-600  active dark:text-blue dark:border-blue-500" : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}>Sign up</span>
          </li>
        </ul>
        {/* <button onClick={() => handleTabClick('signin')} className={activeTab === 'signin' ? 'active' : ''}>Sign In</button>
      <button onClick={() => handleTabClick('signup')} className={activeTab === 'signup' ? 'active' : ''}>Sign Up</button> */}
      </div>
      <div className="tab-content">
        {activeTab === 'signin' ? <SignIn /> : <SignUp />}
      </div>
    </div>
    // <SignUpLayout>
    //   <h2 className="sign-up-layout-title">Login</h2>
    //   <div className="sign-up-layout-subtitle">
    //     <SignedOut>
    //       <SignInButton />
    //     </SignedOut>
    //     <SignedIn>
    //       <UserButton />
    //     </SignedIn>
    //   </div>
    //   <div className="container">
    //     <div className="row justify-content-center">
    //       {emailVerificationRequired && (
    //         <div className="sign-up-form-container">
    //           <div className="form-component">
    //             <div className="form-label-container row">
    //               <label htmlFor="inputEmail1" className="form-label col-12">
    //                 Email Verification Required
    //               </label>
    //               <div className="form-error" role="alert">
    //                 Please verify your email address by clicking on the link we sent to your inbox
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       )}
    //       {((getUserDetailsStatus === "loading" || (
    //         (signinStatus === "success" || signInWithGoogleStatus === "success") && (getUserDetailsStatus === "idle" || getUserDetailsStatus === "failed"))
    //       ) ||
    //         signinStatus === "loading" || signupStatus === "loading" || (signInWithGoogleStatus === "loading" || signInWithGoogleStatus === "success")) && (
    //           (signInWithGoogleStatus === "loading" || signInWithGoogleStatus === "success") || ((email) && (password))) ? (
    //         <div className="sign-up-form-container">
    //           <div className="w-full h-full flex justify-center items-center">
    //             {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
    //             <div class="rotating-cursor mt-6"></div>
    //           </div>
    //         </div>
    //       ) : (
    //         !emailVerificationRequired && (
    //           <div className="sign-up-form-container">
    //             {/* getUserDetailsStatus = {getUserDetailsStatus}
    //             &nbsp; signinStatus = {signinStatus}
    //             &nbsp; signupStatus = {signupStatus}
    //             &nbsp; signInWithGoogleStatus = {signInWithGoogleStatus}
    //             &nbsp; isAuthenticated = {isAuthenticated}
    //             &nbsp; user = {user?.user_email}
    //             &nbsp; userType = {userType}
    //             &nbsp; accessToken = {accessToken}
    //             &nbsp; refreshToken = {refreshToken} */}
    //             <form className="sign-up-form">
    //               {emailVerified && (
    //                 <div className="form-success" role="alert">
    //                   Congratulations! Your email has been verified. You can now login to access the platform.
    //                 </div>
    //               )}
    //               <div className="flex items-center justify-start gap-6">
    //                 <button
    //                   type="button"
    //                   className="form-submit-google  disabled:opacity-50 w-[248px] h-[58px] "
    //                   onClick={(e) => handleGoogleSubmit()}
    //                   id="signUpButton"
    //                 >
    //                   <img src={google} alt="google" height="26" width="26" /> Login With Google
    //                 </button>
    //                 <p className="text-[#000] text-[16px] font-[500]">Or</p>
    //               </div>
    //               <div className="form-component">
    //                 <label htmlFor="inputEmail1" className="form-label">
    //                   Email
    //                 </label>
    //                 <input
    //                   type="email"
    //                   className="form-input"
    //                   id="inputEmail1"
    //                   aria-describedby="emailHelp"
    //                   placeholder="Email"
    //                   value={email}
    //                   onChange={(e) => setEmail(e.target.value)}
    //                 ></input>
    //               </div>
    //               <div className="form-component">
    //                 <div className="form-label-container">
    //                   <label htmlFor="inputPassword1" className="form-label">
    //                     Password
    //                   </label>
    //                   {notice !== "" && (
    //                     <div className="form-error" role="alert">
    //                       {notice}
    //                     </div>
    //                   )}
    //                 </div>

    //                 <input
    //                   type="password"
    //                   className="form-input"
    //                   id="inputPassword1"
    //                   placeholder="Password"
    //                   value={password}
    //                   onChange={(e) => setPassword(e.target.value)}
    //                 ></input>
    //               </div>

    //               <button
    //                 type="submit"
    //                 className="form-submit h-[50px] disabled:opacity-50"
    //                 disabled={disabled || showLoading || email === "" || password === ""}
    //                 onClick={(e) => loginWithUsernameAndPassword(e)}
    //                 id="loginButton"
    //               >
    //                 {showLoading ? <Spinner size={16} className="animate-spin text-black" /> : "LOGIN"}
    //               </button>
    //             </form>
    //             <div className="form-footer">
    //               <Link to="/signup" id="navigation-onboarding">
    //                 Don’t have an account? Sign up.
    //               </Link>
    //               <Link to="/forgot-password" id="navigation-forgot-password">
    //                 Forgot Password?
    //               </Link>
    //             </div>
    //           </div >
    //         )
    //       )}
    //     </div >
    //   </div >
    // </SignUpLayout >
  );
};

export default Signin;
