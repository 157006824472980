import React, { useState } from "react";
import upload from "../../images/upload.svg";
import { Button, Modal, Upload, message } from "antd";
import { useDropzone } from "react-dropzone";

const ImageUploader = ({ imageUploader, setImageUploader, setFile, file, textExtractionStatus }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const mediaFile = acceptedFiles[0];
      setFile(mediaFile);
    },
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
      "application/pdf": [],
      // "image/jpeg": [],
      // "image/png": [],
    },
  });

  return (
    <Modal
      centered
      open={imageUploader}
      onOk={() => setImageUploader(false)}
      onCancel={() => setImageUploader(false)}
      className="image-upload-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden"
      footer={null}
    >
      <div {...getRootProps()} className="w-[700px] h-full flex flex-col items-center py-[60px] gap-[20px]">
        <input {...getInputProps()} />
        <p className="text-black text-[24px] text-center">You can upload a document and ask questions about them.</p>
        <p className="text-gray-500 text-[16px] text-center">Supported formats: docx, pdf</p>
        <div className="flex font-medium text-[24px] text-center text-[#CACACA] mb-4">or</div>
        <Button
          className={`image-upload border-[#5E59FF] flex items-center px-[27px] py-[21px] w-[351px] rounded-[10px] gap-[18px] border border-solid h-[113px] justify-center`}
        >
          <img className="w-[33px] h-[38px]" alt="upload" src={upload} />
          <div className="font-[700] text-[24px] text-[#5E59FF] text-center" id="upload-document">
            Upload
          </div>
        </Button>
        {file && textExtractionStatus && (
          <div className="mt-4 p-4 border rounded bg-gray-100 w-full text-center">
            <p className="text-red-500">
              You are only allowed to upload one document at a time. This will override the previous document.
            </p>
            <div className="flex items-center justify-center gap-2 mt-2">
              <div className="flex items-center">
                <img className="w-[24px] h-[24px] mr-2" alt="file-icon" src={upload} />
                <span>{file.name}</span>
              </div>
              <Button
                type="link"
                className="text-red-500"
                onClick={() => {
                  setFile(null);
                }}
              >
                X
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ImageUploader;
