import React, { useEffect, useState } from 'react';
import Thread from '../../../components/chat/Thread';
import UpdateChatTitleModal from '../../../components/chat/Modals/UpdateThreadTitleModal';
import DeleteThreadModal from '../../../components/chat/Modals/DeleteThreadModal';
import ShareThreadModal from '../../../components/chat/Modals/ShareThreadModal';
import { useDispatch, useSelector } from 'react-redux';
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../../firebase";


import PREVIOUS from "../../../images/previous.svg";
import {
    getAllThreadsV2, updateThreadTitleLocal, getTitle,
    removeDeletedThreadFromList,
} from '../threadsSlice';
import { updateTitle, getThread, updateViewedChats } from '../chatSlice';
import { useNavigate, useLocation } from 'react-router-dom';


const Threads = (props) => {

    const {
        mode,
        chatId,
        newThreadCreated
    } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const storedChatId = localStorage.getItem("storedChatId");

    const [actionOnThread, setActionOnThread] = useState(""); // actions that can be done on a thread i.e Rename, Delete, Share
    const [updateThreadInfo, setUpdateThreadInfo] = useState({}); // thread which we are updating
    const [updateActionLoading, setUpdateActionLoading] = useState(false);
    const [threadList, setThreadList] = useState([]);
    const [selectedThread, setSelectedThread] = useState(null);
    const [updatedTitle, setUpdatedTitle] = useState(null);
    const [threadDeleted, setThreadDeleted] = useState(null);
    const [unsubscribe, setUnsubscribe] = useState(null);


    const deletedThread = useSelector((state) => state.chat.deletedThread);
    const updatedThreadId = useSelector((state) => state.chat.updatedThreadId);
    const askThreads_status = useSelector((state) => state.threads.askThreads_status);
    const draftThreads_status = useSelector((state) => state.threads.draftThreads_status);
    const user = useSelector((state) => state.auth.user);
    const userType = useSelector((state) => state.auth.userType);
    const viewedChats = useSelector((state) => state.chat.viewedChats);
    const askThreadsList = useSelector((state) => state.threads.askThreadsList);
    const deleteThreadStatus = useSelector((state) => state.chat.deleteThreadStatus);
    const notUpdatedAskThreads = useSelector((state) => state.threads.notUpdatedAskThreads);
    const updateTitleStatus = useSelector((state) => state.chat.updateTitleStatus);
    const getTitleStatus = useSelector((state) => state.chat.getTitleStatus);

    useEffect(() => {
        setThreadList([...askThreadsList]);
    }, [askThreadsList, askThreads_status]); // eslint-disable-line

    useEffect(() => {
        if (location.pathname !== "/ask" && location.pathname !== "/template") {
            if (!newThreadCreated) {
                dispatch(getThread(chatId));
            }
            dispatch(updateViewedChats(chatId));
            // setNewTitle("");
        }
    }, [location.pathname]); // eslint-disable-line


    useEffect(() => {
        if (user?.user_email) {
            if (askThreads_status === "idle" || askThreads_status === "failed") {
                dispatch(getAllThreadsV2({ mode: "ask" }));
            } else if (askThreadsList !== null && askThreads_status === "success") {
                if (storedChatId) {
                    localStorage.removeItem("storedChatId");
                }
                setThreadList([...askThreadsList]);
            }
        }
    }, [user, askThreadsList, askThreads_status]); // eslint-disable-line

    useEffect(() => {
        if (chatId && threadList.length > 0) {
            const temp = threadList.find((thread) => thread.id === chatId);
            setSelectedThread(temp);
        }
    }, [threadList, chatId]);


    const updateThreadTitle = (chatId, newTitle) => {
        dispatch(updateTitle({ thread_id: chatId, title: newTitle }));
        setUpdatedTitle(newTitle);
    };
    useEffect(() => {
        if (updateTitleStatus === "success") {
            dispatch(updateThreadTitleLocal({ threadId: updatedThreadId, title: updatedTitle, mode: mode.toLowerCase() }));
        }
    }, [updateTitleStatus]);

    const onPerformThreadAction = (thread, action) => {
        setUpdateThreadInfo(thread);
        setActionOnThread(action);
    }


    useEffect(() => {
        // if (notUpdatedAskThreads.length > 0) {
        //     notUpdatedAskThreads.map((thread) => {
        //         dispatch(getTitle({ thread: thread, mode: "ask" }));
        //     });
        // }
    }, [notUpdatedAskThreads]); // eslint-disable-line

    useEffect(() => {
        setThreadDeleted(deletedThread);
    }, [deletedThread]); // eslint-disable-line

    useEffect(() => {
        if (deleteThreadStatus === "success") {
            dispatch(removeDeletedThreadFromList({ threadId: deletedThread, mode: mode.toLowerCase() }));
            if (threadDeleted === chatId || deletedThread === chatId) {
                navigate(`/${mode.toLowerCase()}`);
            }
        }
    }, [deleteThreadStatus]); // eslint-disable-line

    useEffect(() => {
        if (user?.user_id && chatId && newThreadCreated && getTitleStatus === "idle") {

            const cid = chatId;
            // const collectionRef = collection(firestore, `frontend-updates-client/${user.user_id}/thread-title-updates`);
            debugger;
            dispatch(getTitle({ thread: chatId, mode: "ask" }));
            // var q = query(collectionRef, where("thread_id", "==", cid));

            // const unsubscribeFunc = onSnapshot(q, (querySnapshot) => {
            //     debugger;
            //     querySnapshot.docChanges().forEach((doc) => {
            //         debugger;
            //         let mode = doc.doc.data()["mode"];
            //         let title = doc.doc.data()["title"];
            //         dispatch(updateThreadTitleLocal({ threadId: chatId, title: title, mode: mode, animate: true }));
            //     });
            // });

            // setUnsubscribe(() => unsubscribeFunc);
        }

        // Cleanup logic moved here, separate from the unsubscribe state setup
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [user, chatId]); // Adding all used variables to ensure effect runs correctly



    return (
        <>
            {/* <h2
                className={`w-full flex justify-start gap-[12px] items-center pl-[20px] md:pl-[19px] border-black flex-shrink-0  text-[14px] font-[500] mb-[8px]`}
            >
                <img height={18} width={18} src={PREVIOUS} />
                Previous
            </h2> */}

            {askThreads_status === "loading" || draftThreads_status === "loading" ? (
                <div className="w-full h-full flex justify-center items-center">
                    {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                    <div class="rotating-cursor"></div>
                </div>
            ) : (
                <>
                    {threadList.map((thread) => (
                        <Thread
                            thread={thread}
                            user={user}
                            userType={userType}
                            viewedChats={viewedChats}
                            source={mode.toLowerCase()}
                            // toggleOpen3={toggleOpen3}
                            // updateThreadtoEdit={updateThreadtoEdit}
                            chatId={chatId}
                            // updateThreadTitle={updateThreadTitle}
                            mode={mode}
                            onPerformThreadAction={onPerformThreadAction}
                        />
                    ))}

                    <UpdateChatTitleModal
                        open={actionOnThread === 'Rename'}
                        setActionOnThread={setActionOnThread}
                        thread={updateThreadInfo}
                        loading={updateActionLoading}
                        setLoading={setUpdateActionLoading}
                        updateThreadTitle={updateThreadTitle}
                    />

                    <DeleteThreadModal
                        open={actionOnThread === 'Delete'}
                        setActionOnThread={setActionOnThread}
                        chatId={chatId}
                        thread={updateThreadInfo}
                        loading={updateActionLoading}
                        setLoading={setUpdateActionLoading}
                    />

                    <ShareThreadModal
                        open={actionOnThread === 'Share'}
                        setActionOnThread={setActionOnThread}
                        thread={updateThreadInfo}
                        loading={updateActionLoading}
                        setLoading={setUpdateActionLoading}
                        mode={mode}
                    />

                </>
            )}</>
    )
}

export default Threads