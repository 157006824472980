import React from 'react';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // console.log(error, errorInfo, 'componentDidCatch')
        Sentry.captureException(error, { extra: errorInfo });
    }

    render() {
        // if (this.state.hasError) {
        //     // You can render any custom fallback UI
        //     return (
        //         <div className="min-h-screen flex items-center justify-center bg-gray-100 p-6">
        //             <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
        //                 <h1 className="text-3xl font-bold text-red-500 mb-4">Oops!</h1>
        //                 <p className="text-gray-700 mb-4">Something went wrong.</p>
        //                 <p className="text-gray-500 mb-8">Please try again later.</p>
        //                 <button
        //                     onClick={() => window.location.reload()}
        //                     className="bg-blue-500 text-white px-4 py-2 rounded-md"
        //                 >
        //                     Reload Page
        //                 </button>
        //             </div>
        //         </div>
        //     );
        // }

        return this.props.children;
    }
}

export default ErrorBoundary;
