import React, { useEffect, useState } from "react";
import SignUpLayout from "../../components/SignUpLayout/SignUpLayout";
import "./Account.css";
import { Button, Modal } from "antd";
import lawyer_profile_svg from "../../images/lawyer-profile.svg";
import { useNavigate } from "react-router-dom";
import LawyerChatLayout from "../../components/chat/LawyerChatLayout";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { getLawyerInfo, loadDataFromLocalStorage } from "../UserAuthentication/AuthenticationSlice";
import { saveUserProfileImage } from "../onboarding/onboardingSlice";

const AccountLawyer = () => {
  const [selectedField, setSelectedField] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [education, setEducation] = useState("");
  const [experience, setExperience] = useState("");
  const [imageProfile, setImageProfile] = useState(null);
  const [numOfFiveStar, setNumOfFiveStar] = useState(50);
  const [specialities, setSpecialities] = useState("Intellectual property law");
  const [calendlyLink, setCalendlyLink] = useState("https://calendly.com/ryanwenger/30min");
  const [modalOpen, setModalOpen] = useState(false);

  const userType = useSelector((state) => state.auth.userType);
  const user = useSelector((state) => state.auth.user);
  const lawyerInfo = useSelector((state) => state.auth.lawyerInfo);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const mediaFile = acceptedFiles[0];
      setImageProfile(mediaFile);
    },
  });

  useEffect(() => {
    debugger;
    if (user && user.first_name) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEducation(user.education);
      setExperience(user.experience);
      setNumOfFiveStar(user.num_of_five_star);
      setSpecialities(user.specialities);
      setCalendlyLink(user.calendly_link);
    }
    else {
      dispatch(getLawyerInfo());
    }
  }, [user]);

  let name = user?.first_name ? user?.first_name?.[0].toUpperCase() : user?.user_email?.[0]?.toUpperCase();
  if (!name) {
    name = "A";
  }
  const imagePath = user?.profile_picture_url ? user?.profile_picture_url : require(`../../images/white/${name}.png`);

  const updateProfileImage = () => {
    const formData = new FormData();
    formData.append("file", imageProfile);
    dispatch(saveUserProfileImage(formData));
  };

  useEffect(() => {
    if (imageProfile) {
      updateProfileImage();
    }
  }, [imageProfile]);

  return (
    <LawyerChatLayout>
      <div className="account-layout overflow-y-scroll w-full">
        <div className="account-container max-w-screen-lg flex flex-col items-start gap-6 w-full">
          <Button
            className="account-layout__back-btn text-[#626262] text-[14px] font-[500] border-[#CACACA] border rounded-[7px] w-[92px] h-[26px] text-center leading-[1]"
            type="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            BACK
          </Button>
          <div className="account-layout__title  text-[#000] text-[48px] font-[800]">Account</div>
          <div className="account-layout__content w-full rounded-[30px] border bg-white flex flex-col items-start h-full mb-[200px] pt-0 pb-[58px] overflow-x-hidden">
            <div className="account-layout__content__item__container">
              <div className="account-layout__content__item">
                <div className="account-layout__content__item__info flex gap-4 items-center">
                  <div className="account-layout__content__item__label">Profile Photo</div>
                  <img
                    className="rounded-full shrink-0"
                    width={42}
                    height={42}
                    src={imageProfile ? URL.createObjectURL(imageProfile) : imagePath}
                  />
                </div>
                {/* <Button {...getRootProps()} className="account-layout__content__item__edit-btn">
                  <input {...getInputProps()} />
                  UPDATE
                </Button> */}
              </div>
            </div>

            <div className="account-layout__content__item__container">
              <div className="account-layout__content__item">
                <div className="account-layout__content__item__info flex flex-col gap-[14px] items-start">
                  <div className="account-layout__content__item__label">First Name</div>
                  <div className="account-layout__content__item__value">
                    <span>
                      Your first name is {""}
                      <span className="account-layout__content__item__value__text">{firstName} </span>
                    </span>
                  </div>
                </div>
                {/* <Button
                  className="account-layout__content__item__edit-btn"
                  id="firstName"
                  onClick={() => {
                    // setSelectedField("firstName");
                    setModalOpen(true);
                  }}
                >
                  UPDATE
                </Button> */}
              </div>
              {selectedField === "firstName" && (
                <div className="account-layout__content__edit-container">
                  <input
                    id="first-name"
                    type="text"
                    class="account-layout__content__edit-container__input"
                    value={firstName}
                    placeholder="Update First Name"
                    aria-label="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {/* <Button
                    className="account-layout__content__edit-save"
                    onClick={() => {
                      setSelectedField("");
                    }}
                  >
                    Save
                  </Button> */}
                </div>
              )}
            </div>
            <div className="account-layout__content__item__container">
              <div className="account-layout__content__item">
                <div className="account-layout__content__item__info flex flex-col gap-[14px] items-start">
                  <div className="account-layout__content__item__label">Last Name</div>
                  <div className="account-layout__content__item__value">
                    <span>
                      Your last name is {""}
                      <span className="account-layout__content__item__value__text">{lastName}</span>
                    </span>
                  </div>
                </div>
                {/* <Button
                  className="account-layout__content__item__edit-btn"
                  id="lastName"
                  onClick={() => {
                    // setSelectedField("lastName");
                    setModalOpen(true);
                  }}
                >
                  UPDATE
                </Button> */}
              </div>
              {selectedField === "lastName" && (
                <div className="account-layout__content__edit-container">
                  <input
                    id="last-name"
                    type="text"
                    class="account-layout__content__edit-container__input"
                    value={lastName}
                    placeholder="Update Last Name"
                    aria-label="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {/* <Button
                    className="account-layout__content__edit-save"
                    onClick={() => {
                      setSelectedField("");
                    }}
                  >
                    Save
                  </Button> */}
                </div>
              )}
            </div>
            <div className="account-layout__content__item__container">
              <div className="account-layout__content__item">
                <div className="account-layout__content__item__info flex flex-col gap-[14px] items-start">
                  <div className="account-layout__content__item__label">Education</div>
                  <div className="account-layout__content__item__value">
                    <span>
                      Your education is {""}
                      <span className="account-layout__content__item__value__text">{education}</span>
                    </span>
                  </div>
                </div>
                {/* <Button
                  className="account-layout__content__item__edit-btn"
                  id="education"
                  onClick={() => {
                    // setSelectedField("education");
                    setModalOpen(true);
                  }}
                >
                  UPDATE
                </Button> */}
              </div>
              {selectedField === "education" && (
                <div className="account-layout__content__edit-container">
                  <input
                    id="education"
                    type="text"
                    class="account-layout__content__edit-container__input"
                    value={education}
                    placeholder="Update education"
                    aria-label="Education"
                    onChange={(e) => setEducation(e.target.value)}
                  />
                  {/* <Button
                    className="account-layout__content__edit-save"
                    onClick={() => {
                      setSelectedField("");
                    }}
                  >
                    Save
                  </Button> */}
                </div>
              )}
            </div>

            <div className="account-layout__content__item__container">
              <div className="account-layout__content__item">
                <div className="account-layout__content__item__info flex flex-col gap-[14px] items-start">
                  <div className="account-layout__content__item__label">Experience</div>
                  <div className="account-layout__content__item__value">
                    <span>
                      Your experience is {""}
                      <span className="account-layout__content__item__value__text">{experience} Years</span>
                    </span>
                  </div>
                </div>
                {/* <Button
                  className="account-layout__content__item__edit-btn"
                  id="experience"
                  onClick={() => {
                    // setSelectedField("experience");
                    setModalOpen(true);
                  }}
                >
                  UPDATE
                </Button> */}
              </div>
              {selectedField === "experience" && (
                <div className="account-layout__content__edit-container">
                  <input
                    type="number"
                    className="account-layout__content__edit-container__input"
                    id="experience"
                    aria-describedby="Experience"
                    placeholder="Update Experience"
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                  />

                  {/* <Button
                    className="account-layout__content__edit-save"
                    onClick={() => {
                      setSelectedField("");
                    }}
                  >
                    Save
                  </Button> */}
                </div>
              )}
            </div>

            <div className="account-layout__content__item__container">
              <div className="account-layout__content__item">
                <div className="account-layout__content__item__info flex flex-col gap-[14px] items-start">
                  <div className="account-layout__content__item__label">Number of 5 Star Reviews</div>
                  <div className="account-layout__content__item__value">
                    <span>
                      Your number of 5-Star reviws are{" "}
                      <span className="account-layout__content__item__value__text">{numOfFiveStar}</span>
                    </span>
                  </div>
                </div>
                {/* <Button
                  className="account-layout__content__item__edit-btn"
                  id="numOfFiveStar"
                  onClick={() => {
                    // setSelectedField("numOfFiveStar");
                    setModalOpen(true);
                  }}
                >
                  UPDATE
                </Button> */}
              </div>
              {selectedField === "numOfFiveStar" && (
                <div className="account-layout__content__edit-container">
                  <input
                    id="numOfFiveStar"
                    type="number"
                    class="account-layout__content__edit-container__input"
                    value={numOfFiveStar}
                    placeholder="Update number of 5-Star reviews"
                    aria-label="5-Star reviews"
                    onChange={(e) => setNumOfFiveStar(e.target.value)}
                  />
                  {/* <Button
                    className="account-layout__content__edit-save"
                    onClick={() => {
                      setSelectedField("");
                    }}
                  >
                    Save
                  </Button> */}
                </div>
              )}
            </div>

            <div className="account-layout__content__item__container">
              <div className="account-layout__content__item">
                <div className="account-layout__content__item__info flex flex-col gap-[14px] items-start">
                  <div className="account-layout__content__item__label">Specialities</div>
                  <div className="account-layout__content__item__value">
                    <span>
                      Your specialities are {""}
                      <span className="account-layout__content__item__value__text">{specialities}</span>
                    </span>
                  </div>
                </div>
                {/* <Button
                  className="account-layout__content__item__edit-btn"
                  id="specialities"
                  onClick={() => {
                    // setSelectedField("specialities");
                    setModalOpen(true);
                  }}
                >
                  UPDATE
                </Button> */}
              </div>
              {selectedField === "specialities" && (
                <div className="account-layout__content__edit-container">
                  <input
                    id="specialities"
                    type="text"
                    class="account-layout__content__edit-container__input"
                    value={specialities}
                    placeholder="Update specialities"
                    aria-label="specialities"
                    onChange={(e) => setSpecialities(e.target.value)}
                  />
                  {/* <Button
                    className="account-layout__content__edit-save"
                    onClick={() => {
                      setSelectedField("");
                    }}
                  >
                    Save
                  </Button> */}
                </div>
              )}
            </div>
            <div className="account-layout__content__item__container">
              <div className="account-layout__content__item">
                <div className="account-layout__content__item__info flex flex-col gap-[14px] items-start">
                  <div className="account-layout__content__item__label">Calendly Link</div>
                  <div className="account-layout__content__item__value">
                    <span>
                      Your Calendly Link is {""}
                      <span className="account-layout__content__item__value__text">{calendlyLink}</span>
                    </span>
                  </div>
                </div>
                {/* <Button
                  className="account-layout__content__item__edit-btn"
                  id="calendlyLink"
                  onClick={() => {
                    // setSelectedField("calendlyLink");
                    setModalOpen(true);
                  }}
                >
                  UPDATE
                </Button> */}
              </div>
              {selectedField === "calendlyLink" && (
                <div className="account-layout__content__edit-container">
                  <input
                    id="calendlyLink"
                    type="text"
                    class="account-layout__content__edit-container__input"
                    value={calendlyLink}
                    placeholder="Update calendly link"
                    aria-label="calendlyLink"
                    onChange={(e) => setCalendlyLink(e.target.value)}
                  />
                  {/* <Button
                    className="account-layout__content__edit-save"
                    onClick={() => {
                      setSelectedField("");
                    }}
                  >
                    Save
                  </Button> */}
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          centered
          open={modalOpen}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
          footer={null}
        >
          <div className="w-[600px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
            <span className="text-black text-[30px] font-[800] leading-[1]">Accept Job</span>
            <span className="text-black text-[16px] font-[500] leading-[1]">Feature not available</span>

            <div className="flex items-center justify-start w-full gap-4 h-full ">
              <button
                className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-3 px-[50px] rounded-[10px]"
                onClick={() => {
                  setModalOpen(false);
                }}
                id="decline-job-button"
              >
                Ok
              </button>
              {/* <button
                className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                onClick={() => setModalOpen(false)}
                id="cancel-decline-job-button"
              >
                Cancel
              </button> */}
            </div>
          </div>
        </Modal>
      </div>
    </LawyerChatLayout>
  );
};

export default AccountLawyer;
