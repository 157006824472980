import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import chatSliceReducer from "../../pages/chatpage/chatSlice";
import onboardingSlice from "../../pages/onboarding/onboardingSlice";
import AuthenticationSlice from "../../pages/UserAuthentication/AuthenticationSlice";
import ThreadsSlice from "../../pages/chatpage/threadsSlice";
import MyReferral from "../../pages/refer/MyReferral";
import MyReferralSlice from "../../pages/refer/MyReferralSlice";

const allReducers = combineReducers({
  chat: chatSliceReducer,
  onboarding: onboardingSlice,
  auth: AuthenticationSlice,
  threads: ThreadsSlice,
  myReferral: MyReferralSlice,
});

const applicationStore = createStore(allReducers, applyMiddleware(thunkMiddleware));
export default applicationStore;
