import { useEffect, useRef, useState } from 'react'

const useToggleAnimatedDialog = (isOpen, animationDuration) => {

    const [showDialog, setShowDialog] = useState(false);
    const mountedRef = useRef(false);

    // Effect 1
    useEffect(() => {
        // if isOpen is changed to false we will make the Modal JSX to hide after animationDuration ms
        // so that the close animation can be played
        if (isOpen === false) {
            // if it is first mount, we don't want the setShowDialog to be set to false using setTimeout
            // because in first render isOpen will be false by default & if we trigger bottom sheet open & change isOpen -> true
            // before animationDuration ms then setTimeout will run on expiry of animationDuration ms & will close the modal automatically. 
            // & since we will be clicking the open button from parent which will update isOpen -> true so this useEffect will not run again
            // & showDialog will be set to false for ever.
            if (!mountedRef.current) return;
            setTimeout(() => {
                setShowDialog(false);
                // this delay should be equal to animation duration
            }, animationDuration);
        } else {
            // if isOpen is changed to true we will make the Dialog JSX to appear instantly
            setShowDialog(true);
        }

    }, [isOpen])

    // Effect 2
    useEffect(() => {
        // Effect 2 should always be placed after Effect 1
        mountedRef.current = true;
    }, [])

    return [showDialog, setShowDialog]
}

export default useToggleAnimatedDialog


// NOTE:This hook can be used to create components which show animation before closing & opening, Similar to Modal