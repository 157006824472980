import React, { useEffect, useState } from "react";
import upload from "../../images/upload.svg";
import template_upload from "../../images/template-upload.svg";
import { Button, Modal, Upload, message } from "antd";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { uploadExternalTemplate } from "../../pages/chatpage/chatSlice";
import { Spinner } from "@phosphor-icons/react";


const TemplateUploader = ({ templateUploader, setTemplateUploader, setFileTemplate }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const externalTemplateSaveStatus = useSelector((state) => state.chat.externalTemplateSaveStatus);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const mediaFile = acceptedFiles[0];
      setFile(mediaFile);
    },
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
      "application/pdf": [],
      "image/jpeg": [],
      "image/png": [],
    },
  });
  useEffect(() => {
    if (externalTemplateSaveStatus === "success") {
      message.success("Template uploaded successfully");
      setFile(null);
      setTemplateUploader(false);
    } else if (externalTemplateSaveStatus === "failed") {
      message.error("Failed to upload template");
    }
  }, [externalTemplateSaveStatus]);


  const uploadTemplate = () => {
    const formData = new FormData();
    formData.append("file", file);
    // setFileTemplate(file);
    dispatch(uploadExternalTemplate(formData));
    window.analytics.track("Template Submitted for import", {
      type: "External",
      user_email: user?.user_email
    });
  }
  return (
    <Modal
      centered
      open={templateUploader}
      onOk={() => setTemplateUploader(false)}
      onCancel={() => setTemplateUploader(false)}
      className="image-upload-pop-up h-full rounded-[10px] bg-[#fff] overflow-hidden"
      footer={null}
    >
      <div className="w-[700px] h-full flex flex-col items-center p-[60px] gap-[28px] cursor-pointer">
        <p className="text-black text-[30px] font-[700] text-center">Create a Template </p>
        <p className="text-gray-500 text-[16px] text-center font-[400]">
          Upload a contract and InHouse will convert it into a template within 48 hours
        </p>
        {file ? (
          <div className="mt-4 p-4 border rounded bg-gray-100 w-full text-center">
            {/* <p className="text-red-500">
              You are only allowed to upload one document at a time. This will override the previous document.
            </p> */}
            <div className="flex items-center justify-center gap-2 mt-2">
              <div className="flex items-center">
                <img className="w-[24px] h-[24px] mr-2" alt="file-icon" src={upload} />
                <span>{file.name}</span>
              </div>
              <Button
                type="link"
                className="text-red-500"
                onClick={() => {
                  setFile(null);
                }}
              >
                X
              </Button>
            </div>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className="drag-and-drop flex flex-col items-center w-full rounded-[8px] border-[#D9D9D9] border p-[16px]"
          >
            <input {...getInputProps()} />

            <img className="w-[48px] h-[48px]" alt="upload" src={template_upload} />
            <div className="flex flex-col items-center">
              <p className="text-black text-[16px] font-[500] text-center">Click or drag file to this area to upload</p>
              <p className="text-[#0D0D0D] text-[14px] font-[400] text-center">Documents supported: .doc, .pdf</p>
            </div>
          </div>
        )}

        <Button
          className="account-layout__content__edit-save h-[54px]"
          onClick={() => {
            if (file) {
              uploadTemplate();
            } else {
              message.error("Please upload a file to proceed");
            }
          }}
          disabled={externalTemplateSaveStatus === "loading" ? true : false}
        >
          Submit {externalTemplateSaveStatus === "loading" ? <Spinner size={16} className="animate-spin text-black" /> : <></>}
        </Button>
      </div>
    </Modal >
  );
};

export default TemplateUploader;
