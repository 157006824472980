import React from "react";
import { Paperclip, X } from "@phosphor-icons/react";

const FilePreview = ({ file, onDelete }) => {
    if (file === null) return;
    return (
        <div className="file-preview flex items-center justify-between p-2 rounded mb-2 group bg-slate-200 relative w-fit">
            <div className="flex items-center">
                <Paperclip size={24} className="mr-2" />
                <span className="file-name mr-2">{file?.name}</span>
                {/* <span className="file-type text-gray-600">({file.type})</span> */}
            </div>
            <X
                size={20}
                className="cursor-pointer hidden group-hover:flex text-gray-600 group-hover:text-red-600 absolute -top-2 -right-2 bg-white rounded-full border border-black border-solid"
                onClick={onDelete}
            />
        </div>
    );
};

export default FilePreview;