import React, { useEffect } from "react";
import { getCheckoutUrl } from "../../pages/UserAuthentication/AuthenticationSlice";

import { PopupButton } from "react-calendly";
import { useSelector, useDispatch } from "react-redux";
import ListIconHandShake from "../../images/list-icon-handshake.png";
import ListIconCheckCertificate from "../../images/list-icon-check-certificate.png";
import ListIconLaw from "../../images/list-icon-law.png";
import ListIconAi from "../../images/list-icon-ai.png";
import ListItemFile from "../../images/list-item-file.png";
import ListItemQuestion from "../../images/list-item-question.png";

const SelfHelpFullServiceModalContent = ({ setShowSubscriptionModal, checkoutUrl }) => {
    // Your component logic goes here
    const dispatch = useDispatch();
    const unlimited = [
        { "icon": ListIconAi, "text": "Draft full legal documents in under 10 mins" },
        { "icon": ListItemFile, "text": "Review and receive advice on any legal document" },
        { "icon": ListItemQuestion, "text": "Get instant answers to your legal question 24/7" },
    ];

    const supervised = [
        { "icon": ListIconHandShake, "text": "Get matched with a lawyer experienced in your industry " },
        { "icon": ListIconCheckCertificate, "text": "Proactively manage risks with regular legal health checks" },
        { "icon": ListIconLaw, "text": "Enjoy fast communications via chat, email, or phone" },
    ]
    const subscriptionType = useSelector((state) => state.auth.subscriptionType);
    const user = useSelector((state) => state.auth.user);

    const fetchCheckoutUrl = () => {
        dispatch(getCheckoutUrl());
    }
    useEffect(() => {
        if (checkoutUrl) {
            window.location.href = checkoutUrl
        }
    }, [checkoutUrl]);

    return (
        <div>
            <div class="flex flex-row gap-[30px] w-full">
                <h2 class="font-[FoundersGroteskBold] font-[700] text-[30px] w-full leading-[28px] ">
                    Your {subscriptionType === "trial_expired" ? "trial has ended" : "subscription has expired"} - pick a plan to continue
                </h2>
            </div>
            <div class=" flex flex-row gap-[10px]">
                <div class="w-[433px] mt-[30px] px-[41px] py-[36px] relative rounded gap-[16px] bg-[#FAFAFA] text-[#434343] ">
                    <h3 class="font-700 font-[FoundersGroteskBold] mx-0 text-[30px] text-[#0D0D0D] leading-[28px] text-center">Self-Help AI </h3>
                    <p className="text-[22px] leading-[26.4px] font-[FoundersGrotesk-medium] text-center w-[305px] mx-auto mt-[20px]">Unlimited use of our fast and reliable legal AI platform </p>
                    <h3 className="text-center text-[30px] leading-[28px] mt-[20px]">$49/month</h3>
                    {/* <button  */}
                    <button className="font-[FoundersGrotesk-medium] py-0  h-16 flex items-center text-center text-[18px] md:text-[22px] leading-[0] px-12 md:px-20 bg-[#5E59FF] rounded-md mx-auto my-[34px] text-white"
                        onClick={() => { window.analytics.track("Checkout Button Clicked", { "subscriptionType": "AI-Only-Plan-USD-Monthly", user_email: user?.user_email }); fetchCheckoutUrl() }}

                    >
                        Get Started
                    </button>
                    <ul className="text-[#626262] founders-regular text-[16px] md:text-[18px] mt-6 leading-[18px]">
                        {
                            unlimited.map((item, index) => (
                                <li className="mt-[15px] flex flex-row gap-4 items-center"><img className="w-[47px] h-[47px]" src={item.icon} /> {item.text}</li>
                            ))
                        }
                    </ul>
                </div>
                <div class="w-[433px] mt-[30px] px-[41px] py-[36px] rounded gap-[16px] bg-[#FAFAFA] text-[#434343] leading-[36px] text-[30px]">
                    <h3 class="font-700 font-[FoundersGroteskBold] text-[30px] leading-[28px]  text-[#0D0D0D] text-center">Full Service</h3>
                    <p className="text-[22px] leading-[26.4px] font-[FoundersGrotesk-medium] text-center w-[305px] mx-auto mt-[20px]">Add a dedicated lawyer for enhanced piece of mind</p>
                    <h3 className="text-center text-[30px] leading-[28px] mt-[20px]"><span className="text-[19px] leading-[28px]">Starting at </span> $99/month</h3>
                    <button className="font-[FoundersGrotesk-medium] pt-[6px] pb-0  h-16 flex items-center text-[18px] md:text-[22px] leading-[0] px-12 md:px-20 bg-[#5E59FF] rounded-md mx-auto my-[30px] text-white"
                        onClick={() => {
                            window.analytics.track("get custom quote button Clicked", { user_email: user?.user_email });
                        }}
                    >
                        <PopupButton
                            url="https://calendly.com/ryanwenger"
                            /*
                            * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                            * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                            */
                            rootElement={document.getElementsByTagName("body")[0]}
                            className="w-full bg-danger"
                            pageSettings={{
                                backgroundColor: 'white',
                                hideEventTypeDetails: true,
                                primaryColor: '#5E59FF',
                                textColor: '#434343'
                            }}
                            text="Get Custom Quote"
                        />
                    </button>
                    <ul className="text-[#626262] founders-regular text-[16px] md:text-[18px] leading-[18px] mt-6">
                        {
                            supervised.map((item, index) => (
                                <li className="mt-[15px] flex flex-row gap-4 items-center"><img className="w-[47px] h-[47px]" src={item.icon} /> {item.text}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div >
    );
};

export default SelfHelpFullServiceModalContent;