import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { auth } from "../../firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { signIn, getUserDetails, requestPilotAccessAPI } from "./AuthenticationSlice";
import SignUpLayout from "../../components/SignUpLayout/SignUpLayout";
import "./Signin.css";

const ApplicationUnderReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.auth.accessToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const authError = useSelector((state) => state.auth.error);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // const userType = useSelector(state => state.auth.userType);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (isAuthenticated) {
      // localStorage.setItem("accessToken", accessToken);
      // localStorage.setItem("refreshToken", refreshToken);
      dispatch(getUserDetails());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      var userType = localStorage.getItem("userType");
      if (userType === "lawyer") {
        navigate("/lawyer");
      } else if (userType === "client") {
        // if (user.first_name) {
        navigate("/");
        // } else {
        //   navigate("/update-profile#/update-profile");
        // }
      }
    }
  }, [user]);

  return (
    <SignUpLayout>
      <h2 className="sign-up-layout-title">Create Your Account</h2>

      <div className="container">
        <div className="row justify-content-center">
          <div className="sign-up-form-container">
            <form className="sign-up-form">
              <div className="form-component">
                <span className="form-title">Your application is still Under Review</span>
                <span className="form-description">
                  Unfortunately, you do not have access to the platform yet. Our team is still reviewing your
                  application.
                </span>
              </div>

              <a
                href="mailto:support@inhouse.so"
                type="button"
                className="form-submit text-decoration-none"
                id="contact-support"
              >
                CONTACT SUPPORT
              </a>
            </form>
            <div className="form-footer">
              <Link to="/signup" id="navigation-signup">
                Think this was an error? Try logging in with a different email.{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default ApplicationUnderReview;
