import React from "react";
import SignUpLayout from "../../components/SignUpLayout/SignUpLayout";
import "./Schedule.css";
import { InlineWidget } from "react-calendly";

const Schedule = () => {
  return (
    <SignUpLayout showGradient={false}>
      <div className="schedule-layout">
        <div className="schedule-info">
          <div className="schedule-info-conatiner">
            <div className="schedule-title">Schedule Onboarding Call</div>
            <div className="schedule-description">As a Pilot User, onboarding is necessary to start using InHouse</div>
          </div>
        </div>

        <div className="calendly-container">
          <InlineWidget url="https://calendly.com/ryanwenger/30min" styles={{ height: "100%" }} />
        </div>
      </div>
    </SignUpLayout>
  );
};

export default Schedule;
