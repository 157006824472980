import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Template from "../../components/chat/Template";
import TemplateUploader from "../../components/chat/TemplateUploader.js";
import ChatLayout from "../../components/chat/ChatLayout.js";
import SideNav from "../../pages/chatpage/SideNav";

import {
    resetChatPage,
    listTemplates,
    clearEditTemplateLocal,
    updateTemplateTitleLocal,
    deleteTemplateLocal,
} from "../chatpage/chatSlice.js";
import {
    Paperclip,
} from "@phosphor-icons/react";

import useValidatePageAccess from "../../hooks/useValidatePageAccess.js";


const Templates = () => {

    const dispatch = useDispatch();

    const [templateListTab, setTemplateListTab] = useState(1);
    const [templateUploader, setTemplateUploader] = useState(null);
    const [selectedTemplate, setselectedTemplate] = useState(null);
    const [templatesList, setTemplatesList] = useState(null);
    const [newTitle, setNewTitle] = useState("");
    const [fileTemplate, setFileTemplate] = useState(null);

    const templateListStatus = useSelector((state) => state.chat.templateListStatus);
    const templateList = useSelector((state) => state.chat.templateList);
    const templateRenameStatus = useSelector((state) => state.chat.templateRenameStatus);
    const templateDeleteStatus = useSelector((state) => state.chat.templateDeleteStatus);
    const subscriptionType = useSelector((state) => state.auth.subscriptionType);
    const user = useSelector((state) => state.auth.user);
    const [image, setImage] = useState("");

    useValidatePageAccess();


    useEffect(() => {
        dispatch(clearEditTemplateLocal());
        dispatch(resetChatPage());
        if (templateListStatus === "success") {
            if (templateList.length > 0) {
                setTemplatesList(templateList.slice(0));
            }
            window.analytics.page('Templates Page', {
                title: 'Templates list page',
                url: window.location.href,
                path: window.location.pathname || window.location.path,
            });
        } else if (templateListStatus === "idle") {
            dispatch(listTemplates());
        }
    }, [templateListStatus, templateList, window.location.pathname]);

    useEffect(() => {
        if (templateRenameStatus === "success") {
            // debugger;

            // let temp = templatesList.slice(0);
            // let index = temp.findIndex((template) => template.id === selectedTemplate.id);
            // if (index !== -1) {
            //   temp[index] = {
            //     ...temp[index],
            //     name: newTitle,
            //   };
            // }

            dispatch(updateTemplateTitleLocal({ template_id: selectedTemplate.id, name: newTitle }));
        }
    }, [templateRenameStatus]);

    useEffect(() => {
        if (templateDeleteStatus === "success") {
            dispatch(deleteTemplateLocal({ template_id: selectedTemplate.id }));
        }
    }, [templateDeleteStatus]);


    const displayName = user?.organization_name ? user?.organization_name : (user?.first_name ? user?.first_name : user?.user_email?.split("@")[0]);

    // set image based on the user profile image
    useEffect(() => {
        if (user?.profileImage) {
            setImage(user.profileImage);
        } else {
            const firstNameInitial = user?.first_name ? user.first_name[0].toUpperCase() : "";
            const lastNameInitial = user?.last_name ? user.last_name[0].toUpperCase() : "";
            const name = user?.first_name ? firstNameInitial + lastNameInitial : user?.user_email?.[0]?.toUpperCase();
            // const path = `../../images/white/${name}.png`;

            setImage(name);
        }
    }, [user]);

    return (
        <ChatLayout>
            <section className="chat-body h-full w-full flex ">
                <div className="relative flex fex-row  w-full h-full">
                    <SideNav mode="ask" chatId={null}
                        newThreadCreated={false}
                        displayName={displayName}
                        image={image}
                        subscriptionType={subscriptionType}
                    />
                    <div className="flex h-[100%] w-full flex-col px-[50px] xl:px-[150px] justify-start items-center overflow-y-scroll pt-[20px]">


                        <div className="w-full flex flex-col justify-center items-center gap-[16px]">
                            <p className="text-black text-[48px] font-[700]">Templates</p>
                            <p className="text-[#626262] text-[22px] font-[500]">
                                Use InhouseAI to quickly create new versions of your saved contracts.{" "}
                            </p>
                        </div>
                        <div className="flex w-full justify-between items-center mt-[50px]">
                            <div className="flex items-center gap-[24px]">
                                <button
                                    onClick={() => {
                                        setTemplateListTab(1);
                                    }}
                                    className={`text-[16px] font-[500] ${templateListTab === 1 ? "text-black" : "text-[#CACACA]"}`}
                                    type="text"
                                >
                                    Templates
                                </button>
                                <button
                                    onClick={() => {
                                        setTemplateListTab(2);
                                    }}
                                    className={`text-[16px] font-[500] ${templateListTab === 2 ? "text-black" : "text-[#CACACA]"}`}
                                    type="text"
                                >
                                    Trash
                                </button>
                            </div>
                            <button
                                className="h-[35px] rounded-[8px] p-[12px]  text-white bg-[#5E59FF] flex items-center gap-[10px] border-[1px]"
                                onClick={() => {
                                    window.analytics.track("'Create A Template' Clicked", {
                                        location: "Template",
                                        user_email: user?.user_email
                                    });
                                    setTemplateUploader(true);
                                }}
                            >
                                <Paperclip size={12} className="" />
                                <span className=" font-[500]">Create a template</span>
                            </button>
                        </div>
                        {templateListStatus === "loading" ? (
                            <div className="w-full h-full flex justify-center items-center">
                                <div class="rotating-cursor"></div>
                            </div>
                        ) : templateListStatus === "success" && templatesList && templatesList.length > 0 ? (
                            <div className="flex h-full w-full flex-col justify-start items-center mt-[24px] gap-[18px] mb-[50px]">
                                {(templateListTab === 1
                                    ? templatesList.filter((template) => !template.deleted)
                                    : templatesList.filter((template) => template.deleted)
                                ).map((template) => (
                                    <Template
                                        key={template.id}
                                        isInternalTemplate={template.isInternalTemplate}
                                        info={template}
                                        setTemplatesList={setTemplatesList}
                                        templatesList={templatesList}
                                        setselectedTemplate={setselectedTemplate}
                                        selectedTemplate={selectedTemplate}
                                        newTitle={newTitle}
                                        setNewTitle={setNewTitle}
                                    />
                                ))}
                            </div>
                        ) : (
                            templateListStatus === "success" &&
                            templatesList &&
                            templatesList.length === 0 && <div className="w-full h-full">No Templates to display</div>
                        )}
                    </div>
                    <TemplateUploader
                        setTemplateUploader={setTemplateUploader}
                        templateUploader={templateUploader}
                        setTemplate={setFileTemplate}
                    />
                </div>
            </section>
        </ChatLayout>
    )
}

export default Templates;