import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useHotjar = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.hj) {
      if ((Intl.DateTimeFormat().resolvedOptions().timeZone) && (Intl.DateTimeFormat().resolvedOptions().timeZone.includes('America'))) {
        console.log("hotjar is enabled here", Intl.DateTimeFormat().resolvedOptions().timeZone);
        window.hj('stateChange', location.pathname + location.search);
      }
    }
  }, [location]);
};

export default useHotjar;
