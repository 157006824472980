import React, { useEffect, useState } from 'react';
import { Modal } from "antd";
import {
    Spinner,
} from "@phosphor-icons/react";
import { useDispatch, useSelector } from 'react-redux';
import {
    refreshSubscriptionStatus, getPortalSession,
    setShowSubscriptionModal,
    setRetainerModalOpen,
    setShowSubscriptionStatusRefreshModal
} from "../../../UserAuthentication/AuthenticationSlice";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import applicationStore from "../../../../../src/persistence/stores/RootStore.js"


const SubscriptionStatusRefreshModal = (props) => {
    // console.log(props)
    const {
        open,
        user,
    } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const sub_id = queryParams.get('sub_id');
    const [paymentToastShown, setPaymentToastShown] = useState(false);
    const [intervalId, setIntervalId] = useState([])
    const [apiCallAt, setApiCallAt] = useState(new Date());

    const subscriptionStatusFetch = useSelector((state) => state.auth.subscriptionStatusFetch);
    const subscriptionType = useSelector((state) => state.auth.subscriptionType);



    const keepStatusUpdated = () => {
        const subscriptionStatusFetch = applicationStore.getState().auth.subscriptionStatusFetch;
        const subscriptionType = applicationStore.getState().auth.subscriptionType;

        var firstApiCall = false;
        if (!apiCallAt) {
            setApiCallAt(new Date())
            firstApiCall = true;
        }
        var currentDate = new Date();
        var diff = currentDate - apiCallAt;
        if (diff > 2000 || firstApiCall) {
            if (["free", null, "", "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired"].indexOf(subscriptionType) !== -1) {
                dispatch(refreshSubscriptionStatus())
                setApiCallAt(new Date())
            }
            else {
                if (sub_id && !paymentToastShown) {

                    setPaymentToastShown(true);
                    dispatch(setShowSubscriptionModal(false))
                    dispatch(setRetainerModalOpen(false));
                    if (window.location.search.indexOf("sub_id") !== -1) {
                        toast.success("Your card has been successfully added");
                        dispatch(setShowSubscriptionStatusRefreshModal(false));
                        if (intervalId.length > 0 && !apiCallAt) {
                            intervalId.forEach(element => {
                                clearInterval(element);
                            });
                            setIntervalId([]);
                        }
                        navigate("/ask")
                    }
                    else {
                        intervalId.forEach(element => {
                            clearInterval(element);
                        });
                        setIntervalId([]);
                    }
                }
            }
        }
        else {
        }
    }

    useEffect(() => {
        if (sub_id) {
            if (["free", null, "", "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired"].indexOf(subscriptionType) !== -1) {
                if (intervalId.length === 0) {
                    var interval = (setInterval(() => {
                        keepStatusUpdated();
                    }, 3000));
                    var intervals = intervalId;
                    intervals.push(interval)
                    setIntervalId(intervals)
                }
            }
        }
    }, [user?.user_email]); // eslint-disable-line

    useEffect(() => {
        if (intervalId.length > 0 && !apiCallAt) {
            intervalId.forEach(element => {
                clearInterval(element);
            });
            setIntervalId([]);
        }
    }, [user?.user_email]); // eslint-disable-line

    useEffect(() => {
        if (sub_id) {
            dispatch(setShowSubscriptionStatusRefreshModal(true))
            setTimeout(() => {
                keepStatusUpdated()
            }, 1000);
        }
        // eslint-disable-next-line
    }, [sub_id, user]);

    // useEffect(() => {
    //     if (["free", null, "", "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired"].indexOf(subscriptionType) === -1) {
    //         dispatch(setShowSubscriptionModal(false));
    //         setRetainerModalOpen(false);
    //         if (subscriptionStatusFetch === "success") {
    //             toast.success("Your card has been successfully added. You can now continue with your conversation.");
    //             dispatch(setShowSubscriptionStatusRefreshModal(false))
    //             navigate("/ask")
    //         }
    //         // getPortalSessionUrl();
    //     }
    // }, [subscriptionType, subscriptionStatusFetch]);

    return (
        <Modal
            open={open}
            // onClose={() => setShowSubscriptionStatusRefreshModal(false)}
            className="p-[20px] rounded-[30px] overflow-hidden"
            cancelButtonProps={{
                style: { display: 'none' }
            }}
        >
            <div className="flex flex-col items-start w-full p-5">

                <div class="pr-8 mt-3 flex flex-col justify-between items-center">
                    <p class="text-[18px]">
                        We are updating your subscription status. If the update is taking longer than expected, please click the button below to refresh.

                    </p>
                    <div class="flex flex-row mt-5"><Spinner size={18} className="animate-spin mr-3" /> Refreshing Payment Status </div>

                    <button type="button"
                        className="rounded w-fit mt-5 font-[FoundersGrotesk-medium] bg-[#5E59FF] my-3 pt-[16px] pb-[12px] px-[26px] text-[#fff] text-[18px] "
                        target="blank"
                        disabled={subscriptionStatusFetch === "loading"}
                        onClick={() => {
                            dispatch(refreshSubscriptionStatus())
                        }}
                    >
                        Refresh Payment Status

                    </button>
                </div>
            </div>
        </Modal >
    )
}

export default SubscriptionStatusRefreshModal