import React, { useEffect, useState } from "react";
import "./ChatHeader.css"; // Import the associated CSS file
import logoImage from "../../images/lexxa-logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Checkbox, Rate, Select } from "antd";
import { resetLegalReviewStatus } from "../../pages/chatpage/threadsSlice";
import { acceptLegalReview, rejectLegalReview } from "../../pages/chatpage/chatSlice";
import { getRetainer, submitLegalReview } from "../../pages/chatpage/chatSlice";
import { use } from "marked";
import { Spinner } from "@phosphor-icons/react";
// import { createStyles, useTheme } from "antd-style";

const items = [
  {
    label: "California Bar",
    key: "0",
  },
  {
    label: "UCLA",
    key: "1",
  },
  {
    label: "10 Years of experience",
    key: "3",
  },
  {
    label: "350 5-Star Reviews",
    key: "4",
  },
];

const engagementContract = `Date Created: 12/12/2024

Lorem ipsum dolor sit amet consectetur. Habitant at nulla turpis mauris et nec. Amet dictum neque nunc quis tincidunt adipiscing integer. Purus pellentesque nunc auctor curabitur. Pellentesque tellus fermentum leo eu accumsan enim amet est sit. Eu velit in odio pellentesque est ullamcorper. Quam id rhoncus orci viverra eget lobortis. Ut et mattis sollicitudin lobortis faucibus amet quam adipiscing ut. Neque sagittis lectus sagittis lacus scelerisque. Ac platea nisl egestas eget eget dolor augue faucibus. Vitae aliquam facilisis risus urna ipsum dictum non. Viverra nisl lectus sit nulla ornare libero. Varius massa proin ultricies et enim adipiscing proin tincidunt. Quis hac ligula sit urna sollicitudin. Augue urna sed nunc cursus neque dictumst. 

Viverra lorem aliquam et maecenas velit non blandit. Adipiscing risus netus enim donec at. Consectetur ac feugiat vel netus sollicitudin pharetra aliquam pretium. At malesuada nullam elit nibh. Ut enim eu auctor lobortis duis. Rhoncus velit sed eget tortor. Amet non gravida risus neque mattis a blandit cursus. Imperdiet sit tincidunt tellus mi bibendum nullam magna. Sit orci cras nulla massa mattis tortor ultricies morbi. Suspendisse volutpat nibh magnis non ut vel integer sit. In pharetra purus magna cursus. Metus nulla arcu egestas iaculis dolor condimentum eget vitae facilisi. Libero pharetra quis urna eleifend amet at. Scelerisque tellus proin morbi egestas nisl. `;

const LawyerChatHeader = () => {
  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  // const [acceptedAgreement, setAcceptedAgreement] = useState(false);
  // const [jobCompleted, setJobCompleted] = useState(false);
  const [engagementmodalOpen, setEngagementModalOpen] = useState(false);
  const [completemodalOpen, setCompleteModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [reasonForCompleting, setReasonForCompleting] = useState("");
  const [reviewStatus, setReviewStatus] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageHasAccepted, setMessageHasAccepted] = useState(false);
  const [embedLink, setEmbedLink] = useState("");
  const [retainerEmbedmodalOpen, setRetainerEmbedModalOpen] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);

  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const threadLegalReviewStatus = useSelector((state) => state.chat.status);
  const legalReviewRequested = useSelector((state) => state.chat.legalReviewRequested);
  const legalReviewSubmitted = useSelector((state) => state.chat.legalReviewSubmitted);
  const legalReviewAccepted = useSelector((state) => state.chat.legalReviewAccepted);
  const acceptLegalReviewStatus = useSelector((state) => state.threads.acceptLegalReviewStatus);
  const messages = useSelector((state) => state.chat.messages);
  const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
  const submitLegalReviewStatus = useSelector((state) => state.chat.submitLegalReviewStatus);
  const prospective_client_ids = useSelector((state) => state.auth.prospective_client_ids);
  const clients = useSelector((state) => state.auth.clients);

  const getRetainerStatus = useSelector((state) => state.chat.getRetainerStatus);
  const getRetainerError = useSelector((state) => state.chat.getRetainerError);
  const retainerLink = useSelector((state) => state.chat.retainerLink);

  let { chatId } = useParams();
  const dispatch = useDispatch();
  let selectedClient = JSON.parse(localStorage.getItem("selectedClientDetails"));

  // reset legal review status on chat page if the lawyer moves from one chat to another
  useEffect(() => {
    dispatch(resetLegalReviewStatus());
  }, [chatId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setClientDetails(selectedClient);
  }, [selectedClient]);

  useEffect(() => {
    if (legalReviewAccepted) {
      setReviewStatus("in-progress");
    }
  }, [legalReviewAccepted]);

  useEffect(() => {
    if (legalReviewSubmitted) {
      setReviewStatus("completed");
    }
  }, [legalReviewSubmitted]);

  useEffect(() => {
    if (legalReviewSubmitted) {
      setReviewStatus("completed");
    } else if (legalReviewRequested && legalReviewAccepted) {
      setReviewStatus("pending");
    } else {
      setReviewStatus(null);
    }
  }, [legalReviewRequested, legalReviewAccepted, legalReviewSubmitted]);

  const hasAgreed = user?.has_agreed || false;
  const lawyer = {
    name: "Alexa Johnson",
    experience: 10,
    bar: "California Bar",
    institution: "UCLA",
    ratings: 350,
  };

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    if (threadLegalReviewStatus) {
      // setReviewStatus(threadLegalReviewStatus);
    }
  }, [threadLegalReviewStatus]);

  const getThreadLegalReviewStatus = (status) => {
    switch (status) {
      case "pending":
        return "Pending";
      case "in-progress":
        return "In Progress";
      case "completed":
        return "Completed";
      case "declined":
        return "Declined";
    }
  };

  const handleFeedbackChange = (event) => {
    setFeedbackMessage(event.target.value);
  };

  const handleChange = (value) => {
    setReasonForCompleting(value);
  };

  const acceptReview = () => {
    dispatch(acceptLegalReview({ chatId: chatId }));
  };

  const rejectReview = () => {
    setReviewStatus("declined");
    dispatch(rejectLegalReview({ chatId: chatId }));
  };

  const submitReview = () => {
    dispatch(submitLegalReview({ thread_id: chatId }));
  };

  useEffect(() => {
    const handleMessage = (params) => {
      if (params.origin !== "https://app.boldsign.com") {
        return;
      }

      switch (params.data.action) {
        case "onDocumentSigned":
          // setengagementSigned(true);
          // setEngagementModalOpen(false);
          acceptReview();
          setEmbedLink("");
          // console.log("document signed");
          setRetainerEmbedModalOpen(false);
          break;
        case "onDocumentSigningFailed":
          // handle document signing failure
          setEmbedLink("");
          setEngagementModalOpen(false);
          rejectReview();
          console.log("document signing failed");
          break;
        case "onDocumentReassigned":
          // handle document reassigning success
          break;
        case "onDocumentReassigningFailed":
          // handle document reassigning failure
          break;
        case "onDocumentDeclined":
          // handle document declining success
          setEmbedLink("");
          setEngagementModalOpen(false);
          break;
        case "onDocumentDecliningFailed":
          // handle document declining failure
          setEmbedLink("");
          setEngagementModalOpen(false);
          break;
        default:
          break;
      }
    };

    if (retainerLink && getRetainerStatus === "success") {
      setEmbedLink(retainerLink);
      setRetainerEmbedModalOpen(true);
      window.addEventListener("message", handleMessage);
    } else if (getRetainerStatus === "failed") {
      setEngagementModalOpen(false);
    }

    return () => {
      if (retainerLink) {
        window.removeEventListener("message", handleMessage);
      }
    };
  }, [retainerLink, getRetainerStatus]); // eslint-disable-line

  const handlefetchRetainer = () => {
    let selectedClient = localStorage.getItem("selectedClientId");
    if (selectedClient && clientDetails && clientDetails?.is_prospective) {
      dispatch(getRetainer({ clientId: selectedClient }));
    } else {
      setAcceptModalOpen(true);
    }
  };

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1]?.text === "Legal Matter Declined") {
      setReviewStatus("declined");
    }
  }, [messages]);

  return (
    <header className="chat-app-header">
      <img
        src={logoImage}
        alt="Logo"
        className="logo-image"
        onClick={() => {
          navigate("/");
        }}
        id="navigate-to-home"
      />
      <div className={`flex items-center gap-6 ${getThreadStatus === "loading" ? "d-none" : ""}`}>
        {chatId && reviewStatus ? (
          <div className="text-[#5E59FF] text-[16px] font-[500] text-start gap-6 flex items-center justify-center">
            Status: {getThreadLegalReviewStatus(reviewStatus)}
            {reviewStatus !== "declined" && (
              <Button
                className="bg-[#5E59FF] py-3 px-6 h-[48px] w-[180px] flex items-center justify-center text-center text-[16px] font-[500] text-white rounded-[10px] disabled:opacity-50 disabled:bg-[#5E59FF]"
                onClick={() => {
                  window.analytics.track("Legal review completed", { user_email: user?.user_email });
                  setCompleteModalOpen(true);
                }}
                disabled={legalReviewSubmitted || reviewStatus === "completed" || reviewStatus === "declined"}
                id="complete-job-button"
              >
                Complete Job
              </Button>
            )}
          </div>
        ) : (
          chatId &&
          reviewStatus === null && (
            <div className="flex items-center gap-3">
              {/* <Button
                className="decline-button py-3 px-6 w-[180px] h-[48px] flex items-center justify-center text-center  text-[16px] font-[500] text-[#5E59FF] rounded-[10px] border border-[#5E59FF]"
                onClick={() => {
                  window.analytics.track("Decline Legal review");
                  setDeclineModalOpen(true);
                }}
                id="decline-job-button"
              >
                Decline Job
              </Button>{" "}
              <Button
                className="bg-[#5E59FF] py-3 px-6 w-[180px] h-[48px] flex items-center justify-center text-center  text-[16px] font-[500] text-white rounded-[10px]"
                onClick={() => {
                  window.analytics.track("Accept legal review");
                  handlefetchRetainer();
                }}
                id="accept-job-button"
                disabled={getRetainerStatus === "loading"}
              >
                {getRetainerStatus === "loading" ? (
                  <Spinner size={16} className="animate-spin text-black" />
                ) : (
                  "Accept Job"
                )}
              </Button> */}
            </div>
          )
        )}
      </div>

      <Modal
        centered
        open={engagementmodalOpen}
        onOk={() => setEngagementModalOpen(false)}
        onCancel={() => setEngagementModalOpen(false)}
        className="engagement-pop-up h-fit rounded-[30px] bg-[#fff] overflow-hidden"
        footer={null}
      >
        <div className="w-[900px] h-full flex flex-col items-start px-[30px] py-[50px] gap-[30px]">
          <span className="text-black text-[30px] font-[700] leading-[1]">Accept Job</span>
          <span className="text-black text-[24px] font-[600] leading-[1]">Sign Engagement Letter</span>
          <textarea
            id="engage contract"
            name="engage contract"
            value={engagementContract}
            // onChange={handleFeedbackChange}
            // rows={4}
            style={{ resize: "none" }}
            className="px-6 py-4 bg-[#EAEAEA] w-full rounded-[10px] text-[12px] font-[500] text-black h-[260px] placeholder:italic placeholder:text-black overflow-auto scrollbar-thin scrollbar-thumb-gray-500"
          // placeholder="Feel free to describe your desired scope of work, business goals, or extra context"
          ></textarea>
          <Checkbox checked={checked} onChange={onChange} className="flex gap-1 text-black text-[16px] font-[400]">
            I have read and agree with the terms of this engagement letter
          </Checkbox>
          <div className="flex flex-row justify-between items-end  w-full">
            <div className="flex flex-col items-start gap-2">
              {/* <span className="text-black text-[16px] font-[700]">Draw Signature</span>
              <textarea
                id="signature"
                name="signature"
                // value={engagementContract}
                // onChange={handleFeedbackChange}
                // rows={4}
                style={{ resize: "none" }}
                className="px-6 py-4 bg-[#EAEAEA] w-[370px] rounded-[13px] text-[12px] font-[500] text-black h-[84px] placeholder:italic placeholder:text-black overflow-auto scrollbar-thin scrollbar-thumb-gray-500"
                // placeholder="Feel free to describe your desired scope of work, business goals, or extra context"
              ></textarea> */}
            </div>
            <div className="flex items-center justify-end w-full gap-4 h-full ">
              {/* <button
                className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                onClick={() => setEngagementModalOpen(false)}
              >
                Cancel
              </button> */}
              <button
                className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-3 px-[50px] rounded-[10px]"
                onClick={() => {
                  // setAcceptedAgreement(true);
                  // setEngagementModalOpen(false);
                  // acceptReview();
                  handlefetchRetainer();
                }}
                id="accept-engagement-button"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={declineModalOpen}
        onOk={() => setDeclineModalOpen(false)}
        onCancel={() => setDeclineModalOpen(false)}
        className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
        footer={null}
      >
        <div className="w-[600px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
          <span className="text-black text-[30px] font-[800] leading-[1]">Decline Job</span>
          <span className="text-black text-[16px] font-[500] leading-[1]">
            Please be sure to communicate your reason in the chat for declining this project.
          </span>

          <div className="flex items-center justify-start w-full gap-4 h-full ">
            <button
              className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-3 px-[50px] rounded-[10px]"
              onClick={() => {
                setDeclineModalOpen(false);
                rejectReview();
                window.analytics.track("Legal review declined", { user_email: user?.user_email });
              }}
              id="decline-job-button"
            >
              Decline
            </button>
            <button
              className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
              onClick={() => setDeclineModalOpen(false)}
              id="cancel-decline-job-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={acceptModalOpen}
        onOk={() => setAcceptModalOpen(false)}
        onCancel={() => setAcceptModalOpen(false)}
        className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
        footer={null}
      >
        <div className="w-[600px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
          <span className="text-black text-[30px] font-[800] leading-[1]">Accept Job</span>
          <span className="text-black text-[16px] font-[500] leading-[1]">
            Are you sure you want to accept this job?
          </span>

          <div className="flex items-center justify-start w-full gap-4 h-full ">
            <button
              className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-3 px-[50px] rounded-[10px]"
              onClick={() => {
                setAcceptModalOpen(false);
                acceptReview();
              }}
              id="decline-job-button"
            >
              Accept
            </button>
            <button
              className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
              onClick={() => setAcceptModalOpen(false)}
              id="cancel-decline-job-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={completemodalOpen}
        onOk={() => setCompleteModalOpen(false)}
        onCancel={() => setCompleteModalOpen(false)}
        className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
        footer={null}
      >
        <div className="w-[526px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
          <span className="text-black text-[30px] font-[800] leading-[1]">Complete Job</span>

          <div className="flex flex-col items-start gap-3 w-full">
            <span className="text-black text-[16px] font-[500] leading-[1]">
              Please be sure to communicate your reason in the chat for completing this project.
            </span>
          </div>
          <div className="flex items-center justify-start w-full gap-4 h-full ">
            <button
              className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-3 px-[50px] rounded-[10px]"
              onClick={() => {
                submitReview();
                window.analytics.track("Legal review completed", { user_email: user?.user_email });
                setCompleteModalOpen(false);
              }}
              id="submit-request-review-button"
            >
              SUBMIT
            </button>
            <button
              className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
              onClick={() => setCompleteModalOpen(false)}
              id="cancel-decline-job-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      {embedLink && getRetainerStatus === "success" && (
        <Modal
          centered
          open={retainerEmbedmodalOpen}
          onOk={() => setRetainerEmbedModalOpen(false)}
          // onCancel={() => setRetainerEmbedModalOpen(false)}
          className="retainer-pop-up h-[90vh] w-[90vw] rounded-[30px] bg-[#fff] overflow-hidden"
          footer={null}
        >
          <div className="h-[90vh] w-[90vw]">
            <iframe src={embedLink} title="retainer" width="100%" height="100%"></iframe>
          </div>
        </Modal>
      )}
    </header>
  );
};

export default LawyerChatHeader;
