import React from "react";
import PropTypes from "prop-types";
import "./ChatLayout.css";
import LawyerChatHeader from "./LawyerChatHeader";

const LawyerChatLayout = ({ children }) => {
  return (
    <div className="chat-layout">
      <head>
        <title>Chat with Lexxa—Advanced Legal AI | InHouse</title>
        <meta
          name="description"
          content="How can Lexxa help you today? Ask a legal question, draft a legal document, review a legal contract. If you think it, Lexxa can do it."
        />
      </head>
      <LawyerChatHeader />
      <div className="chat-layout-body">{children}</div>
    </div>
  );
};

LawyerChatLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LawyerChatLayout;
