import React, { useEffect, useState } from "react";
import copy from "../../images/copy.svg";
import { Link } from "react-router-dom";
import { Paperclip, Spinner, ThumbsDown, ThumbsUp, X } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "./ActionButton";
import { Modal } from "antd";
import {
  requestLegalReview, saveAsTemplate, acceptEditSuggestions,
  // updateSaveAsTemplateChats,
} from "../../pages/chatpage/chatSlice";
import { legalReviewFeaturesNew } from "./ChatHeader";
import info from "../../images/info.svg";
import Vector_stroke from "../../images/Vector_stroke.png";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { setRetainerModalOpen, setShowSubscriptionModal } from "../../pages/UserAuthentication/AuthenticationSlice";


const Chat = ({
  key,
  isAIReply,
  chatInfo,
  imageUrl,
  setLike,
  setDisLike,
  clickCopy,
  notificationMessage = null,
  isLastAiReply,
  isLastReply,
  viewerMode = "client",
  chatId,
  handleSend,
  selectedThread,
  legalReviewRequested,
  subscriptionType,
  userAction,
  setUserAction,
  // showHyperLinkButton,
  // showTemplateButton,
  // showLegalReviewRequestButton
}) => {
  const currentDate = new Date(chatInfo?.created_at);
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
  };
  const [animate, setAnimate] = useState(false);
  const formattedDate = currentDate.toLocaleString("en-US", options);
  const lastMessageId = useSelector((state) => state.chat.lastMessageId);
  const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
  // const [retainermodalOpen, setRetainerModalOpen] = useState(false);
  const [confirmationTextModal, setConfirmationTextModal] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationTextSend, setConfirmationTextSend] = useState("");
  const [lawyer, setLawyer] = useState({});
  const [reviewRequest, setReviewRequest] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [hasAcceptEditSuggestions, setHasAcceptEditSuggestions] = useState(false);
  const [buttonsToShow, setButtonsToShow] = useState([]);
  const [showLegalReviewRequestButton, setShowLegalReviewRequestButton] = useState(true);
  const [showTemplateButton, setShowTemplateButton] = useState(true);
  const [showHyperLinkButton, setShowHyperLinkButton] = useState(true);

  const dispatch = useDispatch();

  const isDraft = window.location.pathname.includes("draft");

  const lawyerDetails = useSelector((state) => state.auth.lawyerInfo);
  const user = useSelector((state) => state.auth.user);
  const templateSaveStatus = useSelector((state) => state.chat.templateSaveStatus);
  const templateSaveError = useSelector((state) => state.chat.templateSaveError);

  const getRetainerStatus = useSelector((state) => state.chat.getRetainerStatus);
  const requestLegalReviewStatus = useSelector((state) => state.chat.requestLegalReviewStatus);
  const changesAccepted = useSelector((state) => state.chat.changesAccepted);
  const lastConversationalMessage = useSelector((state) => state.chat.lastConversationalMessage);
  const saveAsTemplateChats = useSelector((state) => state.chat.saveAsTemplateChats);
  const legalReviewRequestedChats = useSelector((state) => state.chat.legalReviewRequestedChats);
  const viewChatLinks = useSelector((state) => state.chat.viewChatLinks);


  let name = lawyerDetails?.first_name
    ? lawyerDetails?.first_name?.[0].toUpperCase()
    : lawyerDetails?.user_email?.[0]?.toUpperCase() || "A";
  const imagePath = lawyerDetails?.profile_picture_url
    ? lawyerDetails?.profile_picture_url
    : require(`../../images/white/${name}.png`);

  useEffect(() => {
    setShowHyperLinkButton(viewChatLinks[viewChatLinks.length - 1] === chatInfo.id)
    updatebuttonsToShow();
  }, [viewChatLinks]);

  useEffect(() => {
    setShowTemplateButton(saveAsTemplateChats[saveAsTemplateChats.length - 1] === chatInfo.id)
    updatebuttonsToShow();
  }, [saveAsTemplateChats]);

  useEffect(() => {
    var show = legalReviewRequestedChats[legalReviewRequestedChats.length - 1] === chatInfo.id;
    setShowLegalReviewRequestButton(show)
    updatebuttonsToShow();
  }, [legalReviewRequestedChats]);


  useEffect(() => {
    const newAiMessage = localStorage.getItem("newAiMessage");
    if (newAiMessage && newAiMessage === chatInfo.id && isAIReply) {
      setAnimate(true);
      localStorage.removeItem("newAiMessage");
    }
  }, [getThreadStatus]);



  useEffect(() => {
    if (lawyerDetails) {
      setLawyer({
        name: lawyerDetails.first_name + " " + lawyerDetails.last_name,
        experience: lawyerDetails.num_years_experience,
        bar: "California Bar",
        institution: lawyerDetails.education,
        ratings: lawyerDetails.num_5star_reviews,
        calendly_link: lawyerDetails.calendly_link,
      });
    } else {
      setLawyer({
        name: "Alexa Johnson",
        experience: 10,
        bar: "California Bar",
        institution: "UCLA",
        ratings: 350,
      });
    }
  }, [lawyerDetails]);



  const legalReviewAction = () => {
    // if user is based in california, 
    // if subscribed, set retainer modal
    // else show subscription modal

    // if user is based oôutside california,
    // set retainer modal true
    debugger;
    if (user?.state === "California") {
      if (["free", null, "", "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired", "trial"].indexOf(subscriptionType) !== -1) {
        setUserAction("legal-review-request");
        dispatch(setShowSubscriptionModal(true))
        window.analytics.track("subscription popup triggered", { "action": "legal review request", "chatId": chatId, "user_state": user?.state, user_email: user?.user_email });
      }
      else {
        dispatch(setRetainerModalOpen(true))
        window.analytics.track("retainer popup triggered", { "action": "legal review request", "chatId": chatId, "user_state": user?.state, user_email: user?.user_email });

      }
    }
    else {
      dispatch(setRetainerModalOpen(true))
      window.analytics.track("retainer popup triggered", { "action": "legal review request", "chatId": chatId, "user_state": user?.state, user_email: user?.user_email });
    }
  }

  const createLegalReviewRequest = () => {
    dispatch(requestLegalReview({ threadId: chatId, reviewRequest: reviewRequest, promoCode: promoCode }));
  };



  const formatText = (text) => {
    text = text.replace(/`/g, "&#96;");
    text = text.replace(/\\n/g, ' \n ');
    text = text.replace(/\n#/g, ' \n \n #');
    text = text.replace(/\n/g, ' \n\n ');
    text = text.replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    text = text.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    return text;
  }

  const hasAcceptEditSuggestionsButton = () => {
    if (chatInfo?.quick_action_buttons?.length > 0) {
      return chatInfo?.quick_action_buttons.some((btn) => btn.type === "accept_edit_suggestions");
    }
    return false;
  }
  const showQuickActionButton = (btn) => {
    if (btn.type === "accept_edit_suggestions" || btn.type === "quick_response") {
      if (isLastReply) {
        return true;
      }
      return false;
    }
    if (btn.type === "legal_review_request") {
      if (showLegalReviewRequestButton) {
        if (legalReviewRequested) {
          return false;
        }
        // if this is the last conversational message, then show the legal review button
        if (showLegalReviewRequestButton) {
          return true;
        }
        return false
      }
      else {
        return false;
      }
    }
    if (btn.type === "hyperlink" && showHyperLinkButton) {
      return true
    }
    if (btn.type === "save_as_template") {
      if (selectedThread?.template) {
        return false
      }
      if (showTemplateButton) {
        if (templateSaveStatus !== "success") {
          return true;
        }
      }
    }


    if (isLastReply || lastConversationalMessage === chatInfo.id) {
      if (changesAccepted) {
        return true;
      }
      if (hasAcceptEditSuggestionsButton()) {
        return false;
      }
      return true;
    }
  }

  const updatebuttonsToShow = () => {
    // debugger;
    if (chatInfo?.quick_action_buttons?.length > 0) {
      var buttons = [];
      chatInfo?.quick_action_buttons.map((btn) => {
        if (showQuickActionButton(btn)) {
          buttons.push(btn)
        }
      })
      setButtonsToShow(buttons)
      return [];
    }
  }

  useEffect(() => {
    updatebuttonsToShow()
  }, []);

  // if (chatInfo?.id === "82d1fb81-f8f8-44f7-999c-511b73a7a7ef") {
  //   debugger
  // }

  //   const typeText = () => {
  //     <Markdown remarkPlugins={[remarkGfm]}>{formattedText(chatInfo?.text)}</Markdown>
  //     text = text.props.children
  //     let index = 0;
  //     if (index < text.length) {
  //        += text.charAt(index);
  // index++;
  // setTimeout(typeText, 100); // Adjust speed here
  //     }
  // return typeText
  //   }

  if (!chatInfo?.text) return null;

  return (
    <>
      {chatInfo.message_type === "conversation" ? (
        <div className="group flex">
          <div className={`flex items-start transition duration-300 flex-grow w-full`} key={key}>
            <div className="mr-3 flex-shrink-0">
              <div className=" h-[45px] w-[45px] flex-shrink-0 rounded-full overflow-hidden md:flex hidden">
                {imageUrl && imageUrl?.length > 2 ? (
                  <img
                    src={imageUrl}
                    alt="User"
                    className="flex-shrink-0"
                    style={{ objectFit: "cover", width: "100%", height: "100%" }}
                  />
                ) : (
                  <div className="w-full h-full bg-[#F5F5F5] flex justify-center items-center text-[16px] font-[400]">
                    {imageUrl}
                  </div>
                )}
              </div>
              <div className=" h-[30px] w-[30px] flex-shrink-0 rounded-full overflow-hidden md:hidden">
                {imageUrl && imageUrl?.length > 2 ? (
                  <img
                    src={imageUrl}
                    alt="User"
                    className="flex-shrink-0"
                    style={{ objectFit: "cover", width: "100%", height: "100%" }}
                  />
                ) : (
                  <div className="w-full h-full bg-[#F5F5F5] flex justify-center items-center text-[16px] font-[400]">
                    {imageUrl}
                  </div>
                )}
              </div>
            </div>
            <div className="flex-grow ">
              {chatInfo?.attachment_names?.length > 0 && (
                <div className="flex justify-end w-full items-center">
                  <div className="file-preview flex items-center justify-between p-2 rounded mb-2 group bg-slate-200 relative w-fit">
                    <div className="flex items-center">
                      <Paperclip size={24} className="mr-2" />
                      <span className="file-name mr-2">{chatInfo?.attachment_names[0]}</span>
                      {/* <span className="file-type text-gray-600">({file.type})</span> */}
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`flex items-center px-4 py-6 text-black rounded-b-[5px] rounded-tr-[5px] ${isAIReply ? "bg-[#DFDFDF]" : "bg-[#F5F5F5]"
                  }`}
              >
                {chatInfo?.text ? (
                  animate ? (
                    <>
                      <div className="chat">
                        <Markdown remarkPlugins={[remarkGfm]}>{`${formatText(chatInfo?.text)}`}</Markdown>
                      </div>

                      {/* <TypeAnimation
                        sequence={[getMarkdownText(chatInfo?.text)]}
                        speed={100}
                        cursor={false}
                        style={{ whiteSpace: 'pre-line' }}
                        repeat={0}
                        omitDeletionAnimation={true}
                      /> */}

                    </>
                  ) : (
                    // <div dangerouslySetInnerHTML={{ __html: convertToHtml(chatInfo?.text) }} className="text-black"></div>
                    <div className="chat">
                      <Markdown remarkPlugins={[remarkGfm]}>{`${formatText(chatInfo?.text)}`}</Markdown>
                    </div>
                  )
                ) : (
                  "Hi ! What legal work do you need today?"
                )}
              </div>

              <div className={`flex justify-end w-full md:flex-row flex-col h-[15px] gap-[10px]  md:gap-[20px]`}>
                {!window.location.pathname.includes("shared") && (
                  <>
                    <div
                      className={`text-gray-500 ${isLastAiReply && isAIReply ? "flex" : "hidden"
                        } group-hover:flex transition-opacity duration-300 ease-in-out shrink-0 text-[12px]`}
                    >
                      {formattedDate}
                    </div>
                    {/* {isLastAiReply && isAIReply && (
                      <div className="text-[12px] text-start flex justify-center ">
                        We recommend getting AI responses reviewed by a lawyer by pressing the "Request Legal Review"
                        button
                      </div>
                    )} */}
                  </>
                )}
              </div>
              {viewerMode === "client" && (
                <>
                  {/* {chatInfo?.quick_action_buttons?.length > 0 && isLastReply && isAIReply && ( */}
                  {chatInfo?.quick_action_buttons?.length > 0 && isAIReply && (
                    <div className="flex items-center gap-[12px] w-full flex-wrap">
                      {buttonsToShow.map((btn, index) => {
                        if (showQuickActionButton(btn)) {
                          return (
                            <ActionButton
                              info={btn}
                              index={index}
                              selectedThread={selectedThread}
                              onClick={() => {
                                if (btn.type === "legal_review_request") {
                                  legalReviewAction();
                                  window.analytics.track("'Talk to lawyer' clicked", {
                                    buttonType: btn.type,
                                    confirmation_popup_text: btn.confirmation_popup_text,
                                    text: btn.confirmation_popup_text,
                                    hyperlink_url: btn.hyperlink_url,
                                    threadId: chatId,
                                    user_email: user?.user_email
                                  });

                                } else if (btn.type === "quick_response") {
                                  window.analytics.track("Quick Response button clicked", {
                                    buttonType: btn.type,
                                    confirmation_popup_text: btn.confirmation_popup_text,
                                    text: btn.confirmation_popup_text,
                                    hyperlink_url: btn.hyperlink_url,
                                    threadId: chatId,
                                    user_email: user?.user_email
                                  });
                                  if (btn.confirmation_popup_text) {
                                    setConfirmationText(btn.confirmation_popup_text);
                                    setConfirmationTextSend(btn.text);
                                    setConfirmationTextModal(true);
                                  } else {
                                    handleSend({ messageInput: btn.text });
                                  }
                                } else if (btn.type === "save_as_template") {
                                  window.analytics.track("Save as Template Clicked", {
                                    buttonType: btn.type,
                                    confirmation_popup_text: btn.confirmation_popup_text,
                                    text: btn.confirmation_popup_text,
                                    hyperlink_url: btn.hyperlink_url,
                                    threadId: chatId,
                                    user_email: user?.user_email
                                  });
                                  dispatch(saveAsTemplate({ thread_id: chatId }));
                                }
                                else if (btn.type === "accept_edit_suggestions") {
                                  window.analytics.track("Accept Edit suggestions", {
                                    buttonType: btn.type,
                                    confirmation_popup_text: btn.confirmation_popup_text,
                                    text: btn.confirmation_popup_text,
                                    hyperlink_url: btn.hyperlink_url,
                                    threadId: chatId,
                                    user_email: user?.user_email
                                  });
                                  dispatch(acceptEditSuggestions({ thread_id: chatId }))
                                }
                                else {
                                  window.analytics.track("View clicked", {
                                    buttonType: btn.type,
                                    confirmation_popup_text: btn.confirmation_popup_text,
                                    text: btn.confirmation_popup_text,
                                    hyperlink_url: btn.hyperlink_url,
                                    threadId: chatId,
                                    user_email: user?.user_email
                                  });
                                  window.open(btn.hyperlink_url, "_blank");
                                }
                              }}
                            />
                          );
                        }
                      })}
                      {/* {templateSaveStatus === "success" && (
                        <div className="text-[16px] text-black font-[400] mt-[20px] pl-[63px]">
                          Your document has been saved as a template. You can{" "}
                          <Link className="underline" to="/template">
                            view it in your Templates.
                          </Link>
                        </div>
                      )} */}
                      {templateSaveError && (
                        <div className="text-[12px] text-start flex justify-center ">
                          Error saving template
                        </div>
                      )}

                    </div>
                  )}</>
              )}
            </div>
          </div>
          <div className="h-full w-[40px] min-w-[40px] max-w-[40px] flex justify-center">
            {isAIReply ? (
              <div
                className={`items-center gap-2 group-hover:flex ${isLastAiReply && isAIReply ? "flex" : "hidden"
                  } flex-col transition-opacity duration-300 ease-in-out`}
              >
                {viewerMode === "client" ? (
                  <>
                    {!window.location.pathname.includes("shared") &&
                      <>
                        {!chatInfo?.feedback_type && !window.location.pathname.includes("shared") ? (
                          <>
                            <ThumbsUp
                              className="hover:cursor-pointer"
                              size={20}
                              color="#CACACA"
                              onClick={setLike}
                              id="chat-like-button"
                            />
                            <ThumbsDown
                              className="hover:cursor-pointer"
                              size={20}
                              color="#CACACA"
                              onClick={setDisLike}
                              id="chat-dislike-button"
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            {chatInfo?.feedback_type === "like" ? (
                              <ThumbsUp size={20} className="hover:cursor-default" />
                            ) : (
                              <ThumbsDown size={20} className="hover:cursor-default" />
                            )}
                          </>
                        )}
                      </>
                    }
                    <button onClick={clickCopy} id="chat-copy-button">
                      <img src={copy} alt="Dislike" className="w-[20px] h-[20px]" />
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              !isAIReply &&
              (
                <div
                  div
                  className="items-center gap-2 hidden group-hover:flex transition-opacity duration-300 ease-in-out"
                >
                  <button onClick={clickCopy} id="chat-copy-button">
                    <img src={copy} alt="Dislike" className="w-[20px] h-[20px]" />
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      ) : (
        chatInfo.message_type === "information" && (
          <div className="flex flex-col text-[#5E59FF] text-[16px] leading-[19.2px] font-[400] mb-[20px] md:ml-[57px] ml-[42px] text-wrap pt-3">
            <div class="flex flex-rows"><img src={Vector_stroke} className="h-[14px] w-[14px] mr-2" />{chatInfo?.text}</div>

          </div>
        )
      )}
      {/* <Modal
        centered
        open={retainermodalOpen}
        onOk={() => setRetainerModalOpen(false)}
        onCancel={() => setRetainerModalOpen(false)}
        className="legal-review-pop-up h-fit rounded-[30px] bg-[#fff] overflow-hidden"
        footer={null}
      >
        <div className="w-full h-full flex ">
          <div className="md:flex flex-col hidden w-[300px] min-h-full bg-[#6E40FF] pt-[60px] pl-[30px] pr-5">
            <span className="text-[#fff] text-[22px] font-[700] leading-normal">
              Using Lexxa for the initial work saves ~90%{" "}
            </span>
            <div className="flex flex-col items-start gap-10 mt-10">
              {legalReviewFeatures.map((feature, index) => (
                <div className="flex items-start gap-3 text-white">
                  <div className=" flex items-start justify-center">
                    <span className=" text-[18px] font-[700]">{index + 1}.</span>
                  </div>
                  <div className="flex flex-col items-start justify-center text-start">
                    <span className=" text-[18px] font-[700] leading-[1.2]">{feature.title}</span>
                    <span className=" text-[12px] font-[400] leading-[1.2]">{feature.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="md:w-[750px] min-h-full md:pt-11 md:pb-[25px] md:pr-[40px] md:pl-[50px] flex flex-col items-start w-full p-[40px]">
            <span className="text-[20px] md:text-[30px] font-[700] text-black ">
              Request Legal Review - {isDraft ? "Draft" : "Ask"}{" "}
            </span>
            <span className="text-[16px] font-[500] text-black">
              This chat {isDraft && "and document"} will be shared with your {!engagementSigned && "prospective"}{" "}
              attorney:
            </span>
            <div className="w-full mt-6 gap-[30px] md:flex md:flex-row flex-col mb-10">
              <div className="flex h-[200px] w-full md:h-[100px] md:w-[100px] flex-shrink-0 overflow-hidden md:mb-0 mb-[30px] ">
                <img
                  src={imagePath}
                  className="flex-shrink-0"
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
              </div>
              <div className="flex flex-col items-start">
                <div className="flex-col md:flex-row gap-2 leading-[1.2]">
                  <span className="text-[18px] font-[700] text-black ">{lawyer.name}</span>{" "}
                  <a href="" className="italic text-[16px] font-[400] text-[#5E59FF]">
                    Schedule A Video Call
                  </a>
                </div>
                <div className="flex flex-col items-start text-[16px] font-[400] text-black leading-[1.2]">
                  <span>Education: {lawyer.institution}</span>
                  <span>Years Experience: {lawyer.experience}</span>
                  <span>5 Star Reviews: {lawyer.ratings}</span>
                  <span>Specialty: Contracts, Formation, Trademarks, General Business</span>
                </div>
              </div>
            </div>
            <textarea
              id="review request"
              name="review request"
              value={reviewRequest}
              onChange={handleReviewRequestChange}
              style={{ resize: "none" }}
              className="md:px-6 md:py-4 p-[8px] bg-[#EAEAEA] w-full rounded-[10px] text-[16px] font-[400] text-black h-[100px] placeholder:italic placeholder:text-black placeholder:leading-[1.2]"
              placeholder="Feel free to describe your desired scope of work, business goals, or extra context"
            ></textarea>
            <div className="flex items-start flex-col w-full mt-10 gap-4">
              <div className="flex items-center gap-[16px] flex-col md:flex-row">
                <span className="text-[16px] font-[700] text-black leading-[1]">
                  Payment Terms: {"Hourly"}
                </span>
                <div className="flex justify-center items-center gap-[8px]">
                  <textarea
                    id="promo code"
                    name="promo code"
                    value={promoCode}
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                    }}
                    style={{ resize: "none" }}
                    className="md:px-[12px] md:py-[8px] p-[8px] bg-[#EAEAEA] w-[176px] rounded-[6px] text-[12px] font-[400] text-black h-[32px] placeholder:text-black placeholder:leading-[1.2] "
                    placeholder="Enter Promo Code"
                  ></textarea>
                  {promoCode && promoCode === "FIRST100" && (
                    <div className="flex justify-center items-center gap-[8px]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path
                          d="M11 2.0625C9.23233 2.0625 7.50436 2.58668 6.0346 3.56874C4.56483 4.55081 3.41929 5.94665 2.74283 7.57977C2.06637 9.21288 1.88938 11.0099 2.23424 12.7436C2.57909 14.4773 3.43031 16.0698 4.68024 17.3198C5.93017 18.5697 7.52268 19.4209 9.25638 19.7658C10.9901 20.1106 12.7871 19.9336 14.4202 19.2572C16.0534 18.5807 17.4492 17.4352 18.4313 15.9654C19.4133 14.4956 19.9375 12.7677 19.9375 11C19.933 8.63102 18.9899 6.36037 17.3148 4.68525C15.6396 3.01013 13.369 2.06704 11 2.0625ZM15.2539 9.43594L10.218 14.2484C10.0878 14.3709 9.91546 14.4386 9.73672 14.4375C9.64936 14.4388 9.56261 14.4227 9.48149 14.3902C9.40036 14.3578 9.32647 14.3096 9.26407 14.2484L6.7461 11.8422C6.67626 11.7813 6.61946 11.7068 6.57912 11.6234C6.53877 11.5399 6.51572 11.4492 6.51134 11.3566C6.50696 11.264 6.52134 11.1715 6.55363 11.0847C6.58591 10.9978 6.63543 10.9183 6.69921 10.8511C6.76298 10.7838 6.83969 10.7302 6.92474 10.6933C7.00978 10.6565 7.1014 10.6372 7.19408 10.6366C7.28676 10.6361 7.37859 10.6543 7.46406 10.6902C7.54953 10.726 7.62686 10.7788 7.69141 10.8453L9.73672 12.7961L14.3086 8.43906C14.4423 8.32244 14.6159 8.262 14.7931 8.27038C14.9703 8.27876 15.1374 8.35532 15.2594 8.48405C15.3815 8.61278 15.4491 8.78371 15.4481 8.9611C15.447 9.1385 15.3775 9.30864 15.2539 9.43594Z"
                          fill="#7AB971"
                        />
                      </svg>
                      <p>Promo applied: $100 Off</p>
                    </div>
                  )}
                  {promoCode && promoCode !== "FIRST100" && <p className="form-error">Invalid code</p>}
                </div>
              </div>
              <span className="text-[16px] font-[400] text-black leading-[1.2]">
                {isDraft ? paymentTermsDraft : paymentTermsAsk}
              </span>
              <div className="flex items-center justify-center text-[12px] font-[400] text-black leading-[1.2] md:leading-[1] italic bg-[#D5C8FF] py-2 px-4 rounded-[4px]">
                Legal Review is currently only available in California. You can continue with your submission but it may
                get rejected.
              </div>
            </div>
            <div className="flex items-center justify-end w-full mt-4 gap-4">
              <button
                className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                onClick={() => setRetainerModalOpen(false)}
                id="cancel-legal-review-button"
              >
                Cancel
              </button>
              <button
                className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] md:py-3 md:px-[50px] px-[16px] py-[8px] rounded-[10px] w-[310px] h-[48px] text-center flex justify-center items-center"
                onClick={() => {
                  // if (!hasRetainerSigned) {
                  //   handlePrepareRetainer();
                  // } else {
                  createLegalReviewRequest();
                  // }
                }}
                id="raise-legal-review-button"
              >
                {
                  getRetainerStatus === "loading" || requestLegalReviewStatus === "loading" ? (
                    <Spinner size={16} className="animate-spin text-black" />
                  ) : (
                    //  !hasRetainerSigned ? (
                    //   "Review Retainer Agreement"
                    // ) : (
                    "Request Legal Review"
                  )
                  // )
                }
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
      <Modal
        centered
        // open={retainermodalOpen}
        open={false}
        onOk={() => dispatch(setRetainerModalOpen(false))}
        onCancel={() => dispatch(setRetainerModalOpen(false))}
        className="legal-review-pop-up h-fit rounded-[30px] bg-[#fff] overflow-hidden"
        footer={null}
      >
        <div className=" w-full h-full flex flex-col">
          <div className="md:w-[692px] min-h-full md:pt-11 md:pb-[25px] md:pr-[40px] md:pl-[40px] flex flex-col items-start w-full p-[40px]">
            <span className="text-[20px] md:text-[30px] font-[700] text-black flex justify-between items-center gap-4 w-full">
              Let a Lawyer Take this to the Finish Line
              <X
                size={30}
                className="hover:cursor-pointer"
                color="black"
                onClick={() => dispatch(setRetainerModalOpen(false))}
                id="cancel-legal-review-button"
              />
            </span>

            <span className="text-[16px] font-[500] text-[#626262]">
              Leveraging our expert legal AI for the initial work enables lawyers to deliver top quality results for up
              to 80% less.
            </span>
            <div className="flex flex-col gap-[28px] mt-[20px] mb-[20px]">
              {legalReviewFeaturesNew.map((feature, index) => (
                <div className="flex gap-[12px] flex-row">
                  <img className="h-[38px] w-[38px]" src={feature.image}></img>
                  <div className="flex flex-col gap-[8px]">
                    <span className="text-[16px] md:text-[20px] font-[700] text-black leading-[1]">
                      {feature.title}
                    </span>
                    <span className="text-[16px] font-[400] text-black">{feature.description}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex w-full h-[1px] bg-[#DFDFDF] my-[8px]" />
            <div className="w-full flex md:flex-row flex-col justify-between md:items-center items-start md:gap-0 gap-[16px]">
              <div className="flex justify-between items-center gap-[8px]">
                <img className="w-[16px] h-[16px]" src={info}></img>
                <span className="text-[16px] font-[400] text-black italic leading-[1]">
                  Your legal credits: ${user?.referral_discount_available}
                </span>
              </div>
              <span className="text-[14px] font-[400] text-[#626262] leading-[1]">Satisfaction gauranteed</span>
            </div>
            {/* <div className="flex justify-center md:justify-start items-center gap-[8px] mt-[16px] w-full">
              <textarea
                id="promo code"
                name="promo code"
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value);
                }}
                // rows={4}
                style={{ resize: "none" }}
                className="md:px-[12px] md:py-[8px] p-[8px] bg-[#EAEAEA] md:w-[176px] w-full rounded-[6px] text-[12px] font-[400] text-black h-[32px] placeholder:text-black placeholder:leading-[1.2] "
                placeholder="Add Promo Code"
              ></textarea>
              {promoCode && promoCode === "FIRST100" && (
                <div className="flex justify-center items-center gap-[8px]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path
                      d="M11 2.0625C9.23233 2.0625 7.50436 2.58668 6.0346 3.56874C4.56483 4.55081 3.41929 5.94665 2.74283 7.57977C2.06637 9.21288 1.88938 11.0099 2.23424 12.7436C2.57909 14.4773 3.43031 16.0698 4.68024 17.3198C5.93017 18.5697 7.52268 19.4209 9.25638 19.7658C10.9901 20.1106 12.7871 19.9336 14.4202 19.2572C16.0534 18.5807 17.4492 17.4352 18.4313 15.9654C19.4133 14.4956 19.9375 12.7677 19.9375 11C19.933 8.63102 18.9899 6.36037 17.3148 4.68525C15.6396 3.01013 13.369 2.06704 11 2.0625ZM15.2539 9.43594L10.218 14.2484C10.0878 14.3709 9.91546 14.4386 9.73672 14.4375C9.64936 14.4388 9.56261 14.4227 9.48149 14.3902C9.40036 14.3578 9.32647 14.3096 9.26407 14.2484L6.7461 11.8422C6.67626 11.7813 6.61946 11.7068 6.57912 11.6234C6.53877 11.5399 6.51572 11.4492 6.51134 11.3566C6.50696 11.264 6.52134 11.1715 6.55363 11.0847C6.58591 10.9978 6.63543 10.9183 6.69921 10.8511C6.76298 10.7838 6.83969 10.7302 6.92474 10.6933C7.00978 10.6565 7.1014 10.6372 7.19408 10.6366C7.28676 10.6361 7.37859 10.6543 7.46406 10.6902C7.54953 10.726 7.62686 10.7788 7.69141 10.8453L9.73672 12.7961L14.3086 8.43906C14.4423 8.32244 14.6159 8.262 14.7931 8.27038C14.9703 8.27876 15.1374 8.35532 15.2594 8.48405C15.3815 8.61278 15.4491 8.78371 15.4481 8.9611C15.447 9.1385 15.3775 9.30864 15.2539 9.43594Z"
                      fill="#7AB971"
                    />
                  </svg>
                  <p>Promo applied: $100 Off</p>
                </div>
              )}
              {promoCode && promoCode !== "FIRST100" && (
                //   <svg
                //   xmlns="http://www.w3.org/2000/svg"
                //   width="22"
                //   height="22"
                //   viewBox="0 0 22 22"
                //   fill="none"
                // >
                //   <path
                //     d="M11 2.0625C9.23233 2.0625 7.50436 2.58668 6.0346 3.56874C4.56483 4.55081 3.41929 5.94665 2.74283 7.57977C2.06637 9.21288 1.88938 11.0099 2.23424 12.7436C2.57909 14.4773 3.43031 16.0698 4.68024 17.3198C5.93017 18.5697 7.52268 19.4209 9.25638 19.7658C10.9901 20.1106 12.7871 19.9336 14.4202 19.2572C16.0534 18.5807 17.4492 17.4352 18.4313 15.9654C19.4133 14.4956 19.9375 12.7677 19.9375 11C19.933 8.63102 18.9899 6.36037 17.3148 4.68525C15.6396 3.01013 13.369 2.06704 11 2.0625ZM10.3125 6.875C10.3125 6.69266 10.3849 6.5178 10.5139 6.38886C10.6428 6.25993 10.8177 6.1875 11 6.1875C11.1823 6.1875 11.3572 6.25993 11.4861 6.38886C11.6151 6.5178 11.6875 6.69266 11.6875 6.875V11.6875C11.6875 11.8698 11.6151 12.0447 11.4861 12.1736C11.3572 12.3026 11.1823 12.375 11 12.375C10.8177 12.375 10.6428 12.3026 10.5139 12.1736C10.3849 12.0447 10.3125 11.8698 10.3125 11.6875V6.875ZM11 15.8125C10.796 15.8125 10.5967 15.752 10.4271 15.6387C10.2575 15.5254 10.1253 15.3643 10.0473 15.1759C9.9692 14.9875 9.94878 14.7801 9.98857 14.5801C10.0284 14.38 10.1266 14.1963 10.2708 14.052C10.415 13.9078 10.5988 13.8096 10.7988 13.7698C10.9989 13.73 11.2062 13.7504 11.3946 13.8285C11.5831 13.9066 11.7441 14.0387 11.8575 14.2083C11.9708 14.3779 12.0313 14.5773 12.0313 14.7812C12.0313 15.0548 11.9226 15.3171 11.7292 15.5105C11.5358 15.7038 11.2735 15.8125 11 15.8125Z"
                //     fill="#DD6054"
                //   />
                // </svg>
                <p className="form-error">Invalid code</p>
              )}
            </div> */}
            <div className="flex items-center justify-end w-full mt-4 gap-4">
              {/* <button
                className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                onClick={() => setRetainerModalOpen(false)}
                id="cancel-legal-review-button"
              >
                Cancel
              </button> */}
              <button
                className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] md:py-3 md:px-[50px] px-[16px] py-[8px] rounded-[10px] w-[310px] h-[48px] text-center flex justify-center items-center"
                onClick={() => {
                  // if (!hasRetainerSigned) {
                  //   handlePrepareRetainer();
                  // } else {
                  window.analytics.track("Get Free Legal Consultation", { user_email: user?.user_email });
                  createLegalReviewRequest();
                  // }
                }}
                id="raise-legal-review-button"
              >
                {
                  getRetainerStatus === "loading" || requestLegalReviewStatus === "loading" ? (
                    <Spinner size={16} className="animate-spin text-black" />
                  ) : (
                    //  !hasRetainerSigned ? (
                    //   "Review Retainer Agreement"
                    // ) : (
                    "Get Free Legal Consultation"
                  )
                  // )
                }
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={confirmationTextModal}
        onOk={() => setConfirmationTextModal(false)}
        onCancel={() => setConfirmationTextModal(false)}
        className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
        footer={null}
      >
        <div className="w-full md:w-[600px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
          <span className="text-black text-[30px] font-[800] leading-[1]">Are you sure?</span>

          <span className="text-black text-[16px] font-[500] leading-[1]">{confirmationText}</span>

          <div className="flex items-center justify-start w-full gap-4 h-full ">
            <button
              className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-[12px] px-[50px] rounded-[10px] w-[150px] flex items-center justify-center "
              onClick={() => {
                // setLoading(true);
                handleSend({ messageInput: confirmationTextSend });
                setConfirmationTextModal(false);
              }}
              id="confirm-chat-button"
            >
              Continue
            </button>
            <button
              className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
              onClick={() => setConfirmationTextModal(false)}
              id="cancel-confirm-chat-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Chat;
