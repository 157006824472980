// import logo from './logo.svg';
import "./App.css";
import React, { useEffect, useReducer, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { RequireAuth } from "./persistence/middlewares/AuthMiddleware";
// import ChatPage from "./pages/chatpage/ChatComponent";
import { Provider } from "react-redux";
import RootStore from "./persistence/stores/RootStore";
import Signin from "./pages/UserAuthentication/Signin";
import Signup from "./pages/UserAuthentication/Signup";
// import LawyerPage from "./pages/chatpage/lawyerComponent";
import MVP from "./pages/chatpage/MVP";
import AskPage from "./pages/chatpage/AskPage";
import LawyerChatComponent from "./pages/chatpage/LawyerChatComponent";
import OnboardingSuccess from "./pages/onboarding/OnboardingSuccess";
import ForgotPassword from "./pages/UserAuthentication/Forgotpassword";
import SetPassword from "./pages/UserAuthentication/Setpassword";
import VerifyEmail from "./pages/UserAuthentication/VerifyEmail";
import RequestAccess from "./pages/UserAuthentication/Requestaccess";
import OnboardingPage from "./pages/onboarding/OnboardingPage";
import UpdateProfileComponent from "./pages/UpdateProfile/UpdateProfile";
import Schedule from "./pages/onboarding/Schedule";
// import Account from "./pages/account/Account";
import ApplicationUnderReview from "./pages/UserAuthentication/ApplicationUnderReview";
// import ChatHome from "./pages/chatpage/ChatHome";
import ChatComponentDraft from "./pages/chatpage/ChatComponentDraft";
import AccountLawyer from "./pages/account/AccountLawyer";
import NotFoundPage from "./pages/chatpage/NotFoundPage";
import Shared from "./pages/chatpage/Shared";
import Templates from "./pages/Templates";
// import useFirebase from "./hooks/useFirebase";
import { ClerkProvider } from '@clerk/clerk-react'
import UserProfilePage from "./pages/account/UserProfile";

export const AuthContext = React.createContext();

function App() {
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          let token;
          if (action.token === "false") {
            token = null;
          } else token = action.token;

          return {
            ...prevState,
            userToken: token,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );
  const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY

  // const { authContext } = useFirebase(dispatch);


  return (
    // <AuthContext.Provider value={authContext}>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="https://inhouse.so/">
      <Provider store={RootStore}>
        <Router>
          <Routes>
            {/* Basic Pages */}
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/request-access" element={<RequestAccess />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/application-under-review" element={<ApplicationUnderReview />} />
            {
              process.env.REACT_APP_FRONTEND_BASE_ENDPOINT !== "https://chat.inhouse.so" &&

              <Route path="/mvp" element={<MVP />} />
            }
            <Route
              path="/account"
              element={
                <RequireAuth>
                  <UserProfilePage />
                </RequireAuth>
              }
            />
            <Route
              path="/account/lawyer"
              element={
                <RequireAuth>
                  <AccountLawyer />
                </RequireAuth>
              }
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Signin />} />
            <Route
              path="/home"
              element={
                // <RequireAuth>

                <Navigate to="/ask" replace />
                // </RequireAuth>
              }
            />
            <Route
              path="/lawyer/:chatId"
              element={
                // <RequireAuth>
                <LawyerChatComponent />
                // </RequireAuth>
              }
            />
            <Route
              path="/lawyer"
              element={
                // <RequireAuth>
                <LawyerChatComponent />
                // </RequireAuth>
              }
            />
            <Route
              path="/"
              element={
                <RequireAuth>
                  {/* <ChatHome /> */}
                  <Navigate to="/ask" replace />
                </RequireAuth>
              }
            />
            <Route
              path="/template"
              element={
                <RequireAuth>
                  <Templates />
                </RequireAuth>
              }
            />

            <Route
              path="/ask/:chatId"
              element={
                <RequireAuth>
                  <ChatComponentDraft />
                </RequireAuth>
              }
            />

            <Route
              path="/ask"
              element={
                // <RequireAuth>
                <AskPage />
                // </RequireAuth>
              }
            />

            <Route
              path="/draft"
              element={
                <RequireAuth>
                  <Navigate to="/ask" replace />
                </RequireAuth>
              }
            />
            <Route
              path="/draft/:chatId"
              element={
                <RequireAuth>
                  <ChatComponentDraft />
                </RequireAuth>
              }
            />
            <Route
              path="/onboarding/complete"
              element={
                <Navigate to="/update-profile#/update-profile" />

                // <RequireAuth>
                //   <OnboardingSuccess />
                // </RequireAuth>
              }
            />
            <Route
              path="/onboarding"
              element={
                // <Navigate to="/update-profile#/update-profile" />
                <RequireAuth>
                  <OnboardingPage />
                </RequireAuth>
              }
            />
            <Route
              path="/update-profile"
              element={
                <RequireAuth>
                  <UserProfilePage />
                </RequireAuth>
              }
            />

            <Route
              path="/onboard-user"
              element={
                <RequireAuth>
                  <UpdateProfileComponent />
                </RequireAuth>
              }
            />
            <Route
              path="/not-found"
              element={
                <RequireAuth>
                  <NotFoundPage />
                </RequireAuth>
              }
            />
            <Route
              path="/shared/:chatId"
              element={
                // <RequireAuth>
                <Shared />
                // </RequireAuth>
              }
            />
            <Route path="*" element={<Navigate to="/not-found" />} />

            {/* <Route
              path="/my-referral"
              element={
                <RequireAuth>
                  <MyReferral />
                </RequireAuth>
              }
            /> */}
          </Routes>
        </Router>
      </Provider>
    </ClerkProvider>
    // </AuthContext.Provider>
  );
}

export default App;
