import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../pages/UserAuthentication/AuthenticationSlice';
import { useAuth, useUser } from '@clerk/clerk-react';
import { use } from 'marked';
import toast, { Toaster } from "react-hot-toast";


const useValidatePageAccess = () => {
    const dispatch = useDispatch();

    // const failedDueTo418 = useSelector((state) => state.auth.failedDueTo418);
    const user = useSelector((state) => state.auth.user);
    // const userType = useSelector((state) => state.auth.userType);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    // const signinStatus = useSelector((state) => state.auth.signupStatus);
    // const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);
    const inhouseUser = useSelector((state) => state.auth.user);

    const { getToken, isLoaded, isSignedIn } = useAuth();

    useEffect(() => {
        const getMyToken = async () => {
            const token = await getToken({ template: "Backend" });
            return token;
        }
        const getTokenValue = async () => {
            const token = await getMyToken();
            if (token && typeof token === "string") {
                localStorage.setItem("accessToken2", token);
            }
        }
        getTokenValue()

    }, [getToken])
    useEffect(() => {
        setInterval(() => {
            if (isSignedIn) {
                const getMyToken = async () => {
                    const token = await getToken({ template: "Backend" });
                    return token;
                }

                const getTokenValue = async () => {
                    const token = await getMyToken();
                    if (token && typeof token === "string") {
                        localStorage.setItem("accessToken2", token);
                    }
                }
                if (isSignedIn) {
                    getTokenValue();
                }
            }
        }, 10000)
    }, [isSignedIn])

    useEffect(() => {
        if (isLoaded) {
            if (isSignedIn) {
                const getMyToken = async () => {
                    const token = await getToken({ template: "Backend" });
                    return token;
                }

                const getTokenValue = async () => {
                    const token = await getMyToken();
                    // console.log("line 56, token=", token);
                    // debugger;
                    if (token && typeof token === "string") {
                        localStorage.setItem("accessToken2", token);
                        if (!user?.user_email) {
                            dispatch(getUserDetails());
                        }
                    }
                }
                // this is called to ensure that the 
                // console.log("line 65", isSignedIn);
                if (isSignedIn) {
                    // console.log("line 67, getting token value");
                    getTokenValue();
                }
            }
            else {
                // console.log(`user is not signed in. lined 72.`);
                localStorage.removeItem("accessToken2");
                localStorage.removeItem("refreshToken2");
                localStorage.removeItem("user");
                localStorage.removeItem("userType");
                localStorage.removeItem("client_details");
                localStorage.removeItem("lawyer_info");
                localStorage.removeItem("showForm");
                window.location.href = "https://inhouse.so/auth"
            }
        }
    }, [isLoaded, isSignedIn])

    useEffect(() => {
        if (isAuthenticated) {
            if (inhouseUser && inhouseUser.user_email) {
                window.analytics.identify(inhouseUser.user_id, {
                    name: `${inhouseUser.first_name} ${inhouseUser.last_name}`,
                    email: inhouseUser.user_email,
                    organizationName: inhouseUser.organization_name ?? "",
                    industry: inhouseUser?.industry ? inhouseUser?.industry : "",
                    businessState: inhouseUser?.state ?? "",
                    incorporationState: inhouseUser?.state_incorporation ?? "",
                });
            }
        }
    }, [inhouseUser]);
}

export default useValidatePageAccess