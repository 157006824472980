// src/components/Footer/Footer.js

import React from "react";
import "./Footer.css"; // Import the associated CSS file

const Footer = () => {
  return (
    <footer className="app-footer">
      <span>&copy; 2024, Round Table Legal AI Inc. All Rights Reserved.</span>
      <div className="footer-nav">
        <a
          target="_blank"
          href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Privacy%20Policy.pdf"
          id="privacy-link-footer"
        >
          Privacy
        </a>
        <span className="divider">|</span>
        <a
          target="_blank"
          href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Terms%20of%20Service.pdf"
          id="terms-link-footer"
        >
          Terms
        </a>
      </div>
    </footer>
  );
};

export default Footer;
