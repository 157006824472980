import { Modal } from "antd";
import React, { useState, useEffect } from "react";
import { Spinner, X, Check } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import { requestLegalReview } from "../../pages/chatpage/chatSlice";
import { PopupButton } from "react-calendly";
import Tick from "../../images/Tick.png";
import { setRetainerModalOpen } from "../../pages/UserAuthentication/AuthenticationSlice";
import HandShakeImage from "../../images/legal-request-popup-handshake.png";
import ShareImage from "../../images/legal-request-popup-share.png";

const RetainerModal = ({ retainermodalOpen, chatId, subscriptionType, getSharedId, handleCopy }) => {
    const dispatch = useDispatch();
    const BASE_URL = process.env.REACT_APP_URL;

    const [reviewRequest, setReviewRequest] = useState("");
    const [promoCode, setPromoCode] = useState("");
    const [loading, setLoading] = useState(false);


    const user = useSelector((state) => state.auth.user);
    const getRetainerStatus = useSelector((state) => state.chat.getRetainerStatus);
    const requestLegalReviewStatus = useSelector((state) => state.chat.requestLegalReviewStatus);
    const legalReviewRequested = useSelector((state) => state.chat.legalReviewRequested);
    const showRetainerModal = useSelector((state) => state.auth.showRetainerModal);
    const createLegalReviewRequest = () => {
        dispatch(requestLegalReview({ threadId: chatId, reviewRequest: reviewRequest, promoCode: promoCode }));
    };

    useEffect(() => {
        if (requestLegalReviewStatus === "success") {
            dispatch(setRetainerModalOpen(false))
        }
    }, [requestLegalReviewStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* non california users regardless of subscription type */}
            {/* <Modal
                centered
                open={showRetainerModal && user?.state !== "California"}
                onOk={() => dispatch(setRetainerModalOpen(false))}
                onCancel={() => dispatch(setRetainerModalOpen(false))}
                className="legal-review-pop-up h-fit rounded-[30px] bg-[#fff] overflow-hidden w-[548px]"
                footer={null}
            >
                <div className="w-[548px] flex flex-row  px-[25px] py-[32px]">
                    <div className=" flex flex-col items-start w-full p-[40px]">
                        <span className="text-[28px] md:text-[30px] font-[700] font-[FoundersGroteskBold] text-black flex justify-between items-center gap-4 w-full ">
                            Share chat with a lawyer
                            <X
                                size={30}
                                className="hover:cursor-pointer"
                                color="black"
                                onClick={() => dispatch(setRetainerModalOpen(false))}
                                id="cancel-legal-review-button"
                            />
                        </span>

                        <p className="text-[22px] mt-3 leading-[26.4px] font-[FoundersGrotesk-medium] font-[500] text-[#626262]">
                            Integrated attorney verifications are currently available only available in California, but are coming soon to {user?.state}. For now, you can share this chat with a lawyer of your choice by sending them this link:
                        </p>

                        <div className="flex items-center justify-start w-full mt-4 gap-4">

                            <button
                                className="text-[16px] font-[700] text-white rounded-[8px] bg-[#5E59FF] px-[20px] py-[14px] text-center flex gap-[10px]"
                                onClick={() => {
                                    window.analytics.track("Generated share link for lawyer", { user_email: user?.user_email });
                                    setLoading(true);
                                    getSharedId(chatId);
                                    handleCopy(`${BASE_URL}/shared/${chatId}`);
                                }}
                                disabled={loading}
                                id="raise-legal-review-button"
                            >
                                Generate and Copy Link
                            </button>
                        </div>
                    </div>
                </div>
            </Modal> */}
            {/* california user, subscribed */}
            <Modal
                centered
                open={showRetainerModal}
                // open={showRetainerModal && user?.state === "California" && ["free", null, ""].indexOf(subscriptionType) === -1}
                // open={true}
                onOk={() => dispatch(setRetainerModalOpen(false))}
                onCancel={() => dispatch(setRetainerModalOpen(false))}
                className="legal-review-pop-up h-fit rounded-[30px] bg-[#fff] overflow-hidden w-[548px]"
                footer={null}
            >
                <div className="w-[662px] flex flex-row  px-[25px] py-[32px]">
                    {!legalReviewRequested ?
                        <div className=" flex flex-col items-start w-full p-[20px]">
                            <span className="text-[28px] md:text-[30px] font-[700] font-[FoundersGroteskBold] text-black flex justify-between items-center gap-4 w-full ">
                                Loop in a lawyer
                                <X
                                    size={20}
                                    className="hover:cursor-pointer"
                                    color="black"
                                    onClick={() => dispatch(setRetainerModalOpen(false))}
                                    id="cancel-legal-review-button"
                                />
                            </span>
                            {subscriptionType !== "trial_expired" && subscriptionType !== "subscription_expired" ?
                                <p className="text-[22px] md:text-[22px] leading-[26.4px] mt-[15px] font-[500] font-[FoundersGroteskBold] text-[#626262] flex justify-between items-center gap-4 w-full">
                                    Since you are on the {" "}
                                    {subscriptionType === "trial" ? "free trial" : "Self-Help Plan"}  you have two options:
                                </p>
                                :
                                <p className="text-[22px] md:text-[22px] leading-[26.4px] mt-[15px] font-[500] font-[FoundersGroteskBold] text-[#626262] flex justify-between items-center gap-4 w-full">
                                    Your
                                    {subscriptionType === "trial_expired" ? "trial" : "subscription"} has expired, you can no longer request legal review.
                                </p>
                            }

                            <ul className="mt-[30px]">
                                <li className="flex flex-row gap-3">
                                    <img src={HandShakeImage} class="w-[38px] h-[38px]" />
                                    <div className="flex flex-col w-full">
                                        <h3 className="text-[22px] leading-[33px] font-[700] font-[FoundersGroteskBold]">
                                            Get matched with an experienced lawyer
                                        </h3>
                                        <p className="text-[16px] leading-[24px]  font-[FoundersGroteskRegular]">
                                            To upgrade to full service, schedule a call with our customer support team
                                        </p>
                                        <div>
                                            <button
                                                className="leading-[19.2px] mt-[12px] text-[16px] font-[400] text-white rounded-[8px] bg-[#5E59FF] px-[32px] pt-[14px] pb-[10px] font-[FoundersGroteskRegular]"
                                                onClick={() => {
                                                    window.analytics.track("get custom quote button Clicked", { user_email: user?.user_email });
                                                }}
                                            >
                                                <PopupButton
                                                    url="https://calendly.com/ryanwenger"
                                                    rootElement={document.getElementsByTagName("body")[0]}
                                                    className="w-full"

                                                    text="Schedule a Meeting"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex flex-row gap-3 mt-[25px]">
                                    <img src={ShareImage} class="w-[38px] h-[38px]" />
                                    <div className="flex flex-col w-full">
                                        <h3 className="text-[22px] leading-[33px] font-[700] font-[FoundersGroteskBold]">
                                            Share it with your current lawyer

                                        </h3>
                                        <p className="text-[16px] leading-[24px]  font-[FoundersGroteskRegular]">
                                            If you already have counsel, you can share the link below to get their feedback
                                        </p>
                                        <div>
                                            <button
                                                className="leading-[19.2px] mt-[12px] text-[16px] font-[400] text-white rounded-[8px] bg-[#5E59FF] px-[32px] pt-[14px] pb-[10px] font-[FoundersGroteskRegular]"
                                                onClick={() => {
                                                    window.analytics.track("Generated share link for lawyer", { user_email: user?.user_email });
                                                    setLoading(true);
                                                    getSharedId(chatId);
                                                    handleCopy(`${BASE_URL}/shared/${chatId}`);
                                                }}
                                            >
                                                Copy Chat to Clipboard
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                        : <div className=" flex flex-col items-start w-full">
                            <div className=" flex flex-col items- w-full ">
                                <span className="text-[28px] md:text-[30px] font-[700] font-[FoundersGroteskBold] text-black flex justify-end items-end gap-4 w-full ">
                                    <X
                                        size={30}
                                        className="hover:cursor-pointer"
                                        color="black"
                                        onClick={() => dispatch(setRetainerModalOpen(false))}
                                        id="cancel-legal-review-button"
                                    />
                                </span>
                                <p className="text-[22px] mt-3 leading-[26.4px] font-[FoundersGrotesk-medium] font-[500] text-[#626262]">
                                    Legal Review has already been requested for this thread
                                    Please wait for the attorney to join the conversation.
                                </p>
                            </div>
                        </div>}
                </div>
            </Modal >
        </>
    );
};

export default RetainerModal;