import React from "react";
import { Paperclip } from "@phosphor-icons/react";


const DynamicExample = ({ example, onClick }) => {
  return (
    <div
      className="dynamicExample mt-3 flex gap-[16px] py-2 px-6 cursor-pointer   w-full border border-[#0D0D0D] rounded-[10px] group-hover:border-[#5E59FF]"
      onClick={onClick}
      id="dynamicExample"
    >
      {example.icon === "paperclip" ? (<Paperclip width={25} height={22} />) :
        <img height={18} width={18} src={example.icon} />
      }
      <div className="w-full">
        <p className=" text-[16px] font-[600]">{example.title}</p>
        <p className="text-[#626262] text-[14px] font-[400] group-hover:text-[#5E59FF]">{example.description}</p>
      </div>
    </div>
  );
};

export default DynamicExample;
