import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css"; // Create this CSS file for custom styling

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings} style={{ height: "100%" }}>
        <div className="carousel-item">
          <span className="review">
            I used to handle all my legal work myself, with the occasional law firm. Now I give it all to InHouse and
            don’t have to stress.
          </span>
          <div className="rated-stars">
            {Array.from({ length: 5 }, (_, index) => (
              <div key={index} style={{ display: "inline-block", marginRight: "5px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                  <path
                    d="M3.81475 19L5.45551 11.9732L0 7.24899L7.18624 6.62738L10.0007 0L12.8151 6.62606L20 7.24767L14.5458 11.9719L16.1866 18.9987L10.0007 15.269L3.81475 19Z"
                    fill="#FFB967"
                  />
                </svg>
              </div>
            ))}
          </div>
          <span className="review">— Jordan Rothstein, King Tide</span>
        </div>
        <div className="carousel-item">
          <span className="review">
            Lexxa gave us advice and drafted our legal docs for the same quality for my old $1,000/hour lawyer.
          </span>
          <div className="rated-stars">
            {Array.from({ length: 5 }, (_, index) => (
              <div key={index} style={{ display: "inline-block", marginRight: "5px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                  <path
                    d="M3.81475 19L5.45551 11.9732L0 7.24899L7.18624 6.62738L10.0007 0L12.8151 6.62606L20 7.24767L14.5458 11.9719L16.1866 18.9987L10.0007 15.269L3.81475 19Z"
                    fill="#FFB967"
                  />
                </svg>
              </div>
            ))}
          </div>
          <span className="review">— Jonathan Nostrant, Presence</span>
        </div>
        <div className="carousel-item">
          <span className="review">
            ChatGPT could only produce one page agreements - Lexxa drafts full and complete legal docs.
          </span>
          <div className="rated-stars">
            {Array.from({ length: 5 }, (_, index) => (
              <div key={index} style={{ display: "inline-block", marginRight: "5px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                  <path
                    d="M3.81475 19L5.45551 11.9732L0 7.24899L7.18624 6.62738L10.0007 0L12.8151 6.62606L20 7.24767L14.5458 11.9719L16.1866 18.9987L10.0007 15.269L3.81475 19Z"
                    fill="#FFB967"
                  />
                </svg>
              </div>
            ))}
          </div>
          <span className="review">— Sam Friedman, Real Estate Developer</span>
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
