// NotFoundPage.js

import { Button } from "antd";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { handleLogout } from "../UserAuthentication/AuthenticationSlice";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const [thread, setThread] = React.useState(queryParams.get("thread"));
  const [code, setCode] = React.useState(queryParams.get("code"));

  const switchAccount = () => {
    var redirectUrl = "/ask";
    if (thread) {
      redirectUrl = `/ask/${thread}`
    }
    dispatch(handleLogout(redirectUrl))
  }

  return (
    <div className="h-screen w-full flex justify-center items-center flex-col">
      {(code === "1") ? <>
        <h1 class="text-[23px] leading-[25px]">Invalid Access</h1>
        <p>The page you are looking for does not exist.</p>
        <div class="flex flex-row mt-3">

          <Link
            className={`bg-[#5E59FF] py-3 px-6 h-[42px] flex items-center text-[16px] font-[500] text-white rounded-[10px]`}
            to="/ask"
            id="request-legal-review-button"
          >
            Go to home
          </Link>
          <Button
            className={`bg-[#5E59FF] py-3 ml-3  px-6 h-[42px] flex items-center text-[16px] font-[500] text-white rounded-[10px]`}
            onClick={() => {
              switchAccount()
            }}
            id="request-legal-review-button"
          >
            Switch Account
          </Button>
        </div>
      </> : ""
      }
      {
        (code === "4") ? <>
          <h1>Not Found</h1>
          <p>The page you are looking for does not exist.</p>
          <Button
            className={`bg-[#5E59FF] py-3 px-6 h-[42px] flex items-center text-[16px] font-[500] text-white rounded-[10px]`}
            onClick={() => {
              if (userType === "lawyer") {
                navigate("/lawyer");
              } else {
                navigate("/ask");
              }
            }}
            id="request-legal-review-button"
          >
            Go to home
          </Button>
        </> : ""
      }
      {
        (code !== "1" && code !== "4") ? <>
          <h1>Not Found</h1>
          <p>The page you are looking for does not exist.</p>
          <Button
            className={`bg-[#5E59FF] py-3 px-6 h-[42px] flex items-center text-[16px] font-[500] text-white rounded-[10px]`}
            onClick={() => {
              if (userType === "lawyer") {
                navigate("/lawyer");
              } else {
                navigate("/ask");
              }
            }}
            id="request-legal-review-button"
          >
            Go to home
          </Button>
        </> : ""
      }
    </div >
  );
};

export default NotFoundPage;
