import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getLawyerInfo, getUserDetails } from "../UserAuthentication/AuthenticationSlice";
import TextArea from "antd/es/input/TextArea";

import { handleLogout } from "../UserAuthentication/AuthenticationSlice";
import { marked } from "marked";
import {
  sendMessageLawyer,
  enableNewChat,
  disableNewChat,
  setThreadId,
  getThread,
  getAdditionalMessages,
  lawyerGetAllThreads,
  updateSubmittedChats,
  submitLegalReview,
  updateMessagingDisabled,
  updateClientUnreadThreadMappings,
  resetChatPage,
} from "./chatSlice";
import { useNavigate } from "react-router-dom";
import { loadDataFromLocalStorage } from "../UserAuthentication/AuthenticationSlice";
import LawyerChatLayout from "../../components/chat/LawyerChatLayout";
import { Button, Modal } from "antd";
import { CaretLeft, Info } from "@phosphor-icons/react";
import ask_icon from "../../images/ask-icon.svg";
import draft_icon from "../../images/draft-icon.svg";
import { Dropdown, Space, Select } from "antd";
import ArrowCircleUpColored from "../../images/ArrowCircleUpColored.svg";

import lawyer_profile from "../../images/lawyer-profile.png";
import file_plus from "../../images/file-plus.svg";
import paper_plane from "../../images/paper-plane.svg";
import previous from "../../images/previous.svg";
import Thread from "../../components/chat/Thread";
import Chat from "../../components/chat/Chat";
import { resetLegalReviewStatus } from "./threadsSlice";
import { collection, onSnapshot, query, where, orderBy } from "firebase/firestore";
import { firestore } from "../../firebase";

const items = [
  {
    label: "My Account",
    key: "0",
  },
  {
    label: "Logout",
    key: "1",
  },
];

function LawyerChatComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { chatId } = useParams();

  const [message, setMessage] = useState("");

  // const [action, setAction] = useState("");
  // const [messageId, setMessageId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [userImageMapping, setUserImageMapping] = useState({});
  const [selectedClient, setSelectedClient] = useState("");
  const [submitReviewDisabled, setSubmitReviewDisabled] = useState(false);
  const [involvedUsers, setInvolvedUsers] = useState([]);
  const [threadsAsk, setThreadsAsk] = useState([]);
  const [threadsDraft, setThreadsDraft] = useState([]);
  const [userImage, setUserImage] = useState();
  const [clientDetails, setClientDetails] = useState({});
  const [reviewNotYetRequested, setReviewNotYetRequested] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [reviewNotYetAdded, setReviewNotYetAdded] = useState(true);
  const [showCompannyInfo, setShowCompannyInfo] = useState(false);
  // const [clientsList, setClientsList] = useState([]);
  const [timestamp, setTimestamp] = useState(new Date());
  const [image, setImage] = useState(null);

  const messageListRef = useRef(null);

  const user = useSelector((state) => state.auth.user);
  const getLawyerInfoStatus = useSelector((state) => state.auth.getLawyerInfoStatus);
  const lawyerInfo = useSelector((state) => state.auth.lawyerInfo);
  const clients = useSelector((state) => state.auth.clients);
  const prospective_client_ids = useSelector((state) => state.auth.prospective_client_ids);
  // const userType = useSelector((state) => state.auth.userType);
  const userType = localStorage.getItem("userType");
  const messages = useSelector((state) => state.chat.messages);
  const status = useSelector((state) => state.chat.status);
  const sendMessageLawyerStatus = useSelector((state) => state.chat.sendMessageLawyerStatus);
  const sendMessageLawyerError = useSelector((state) => state.chat.sendMessageLawyerError);
  const lastMessageId = useSelector((state) => state.chat.lastMessageId);

  // const clients = useSelector((state) => state.chat.clients);
  // console.log("lawyer_clients", prospective_client_ids);

  // This status and error is geting updated from get-lawyer-threads api
  const getAllThreadError = useSelector((state) => state.chat.getAllThreadError);
  const getAllThreadStatus = useSelector((state) => state.chat.getAllThreadStatus);

  // this is for fetching one single thread
  const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
  const getThreadError = useSelector((state) => state.chat.getThreadError);

  // const fetchedThreadId = useSelector((state) => state.chat.fetchedThreadId);
  // const userType = useSelector((state) => state.auth.userType);

  const messagingDisabled = useSelector((state) => state.chat.messagingDisabled);
  const submitLegalReviewStatus = useSelector((state) => state.chat.submitLegalReviewStatus);

  const acceptLegalReviewStatus = useSelector((state) => state.chat.acceptLegalReviewStatus);
  const acceptLegalReviewError = useSelector((state) => state.chat.acceptLegalReviewError);
  const rejectLegalReviewStatus = useSelector((state) => state.chat.rejectLegalReviewStatus);
  const rejectLegalReviewError = useSelector((state) => state.chat.rejectLegalReviewError);

  // const getAllClientsStatus = useSelector((state) => state.chat.getAllClientsStatus);
  const submittedChats = useSelector((state) => state.chat.submittedChats);
  const submittedReviews = useSelector((state) => state.chat.submittedReviews);
  const groupedthreads = useSelector((state) => state.chat.threadsNewStructure);
  const threads = useSelector((state) => state.chat.threads);
  const clientUnreadThreadMappings = useSelector((state) => state.chat.clientUnreadThreadMappings);

  useEffect(() => {
    // dispatch(getAllClients({}));
    dispatch(getLawyerInfo());
    dispatch(loadDataFromLocalStorage());
    dispatch(resetLegalReviewStatus);
    dispatch(resetChatPage());
  }, []);

  useEffect(() => {
    if (user?.profile_picture_url) {
      setImage(user.profile_picture_url);
    } else {
      const name = user?.first_name ? user.first_name[0].toUpperCase() : user?.user_email?.[0]?.toUpperCase();
      const path = `../../images/white/${name}.png`;

      setImage(path);
    }
  }, [user]);

  useEffect(() => {
    if (messages?.length > 0) {
      setMessageList(messages.slice(0));
    }
  }, [messages]);

  useEffect(() => {
    const storedClientId = localStorage.getItem("selectedClientId");
    let isClientPresent = clients?.map((client) => client.id).includes(storedClientId);
    if (storedClientId && clients && isClientPresent) {
      setSelectedClient(storedClientId);
    } else {
      setSelectedClient("");
    }
  }, [clients]);

  // useEffect(() => {
  //   if (clients ) {
  //     const updatedClients = {};
  //     Object.entries(clients).forEach(([user_id, client]) => {
  //       updatedClients[user_id] = {
  //         ...client,
  //         prospective_client: prospective_client_ids.includes(user_id),
  //       };
  //     });

  //     setClientsList(updatedClients);
  //   }
  // }, [clients, prospective_client_ids]);

  // take the user to signin page if the user is not logged in
  // redirect to home if the user is a client
  useEffect(() => {
    if (getLawyerInfoStatus === "success") {
      if (!user) {
        window.location.href = "https://inhouse.so/auth";
      }
      if (userType === "client") {
        window.location = "/ask";
      }
    } else if (getLawyerInfoStatus === "failed") {
      window.location.href = "https://inhouse.so/auth";
    }
  }, [getLawyerInfoStatus, user, userType]); // eslint-disable-line

  // clean the textarea as soon as the message is send and added to the messsage list
  useEffect(() => {
    if (sendMessageLawyerStatus === "success") {
      setMessage("");
    }
  }, [sendMessageLawyerStatus]);

  //  start editing from here

  useEffect(() => {
    if (groupedthreads && getAllThreadStatus === "success") {
      // setThreadsAsk([...groupedthreads.ask_threads]);
      // setThreadsDraft([...groupedthreads.draft_threads]);
    }
  }, [groupedthreads, getAllThreadStatus]);

  useEffect(() => {
    if (selectedClient !== "") {
      dispatch(lawyerGetAllThreads({ userId: selectedClient }));
      var client = clients.find((client) => client.id === selectedClient);
      setClientDetails(client?.info);
    }
  }, [selectedClient]);

  const convertToHtml = (text) => {
    return marked(text.replace(/\n/g, "<br>"));
  };

  useEffect(() => {
    // legalReviewSubmitted(false);
    setReviewNotYetAdded(true);
    if (chatId !== null) {
      var loggedInUserMessages = [];
      for (var i = 0; i < messages.length; i++) {
        if (messages[i].user != null && messages[i].user.user_id === user.user_id) {
          loggedInUserMessages.push(messages[i]);
        }
      }
      // const loggedInUserMessages = messages.filter((message) => message.user.user_id === user.user_id);
      if (loggedInUserMessages.length > 0) {
        setReviewNotYetAdded(false);
      }
    }
  }, [messages, chatId]);

  // // setSubmitReviewDisabled or not
  // useEffect(() => {
  //   setSubmitReviewDisabled(false);
  //   setReviewNotYetRequested(false);
  //   if (legalReviewSubmitted) {
  //     setSubmitReviewDisabled(true);
  //   } else {
  //     var currentThread = threads.find((thread) => thread.thread_id === chatId);
  //     if (currentThread !== undefined && !currentThread.legal_review_requested) {
  //       setSubmitReviewDisabled(true);
  //       setReviewNotYetRequested(true);
  //     } else if (currentThread !== undefined && currentThread.legal_review_submitted) {
  //       setSubmitReviewDisabled(true);
  //       setReviewNotYetRequested(false);
  //     } else {
  //       if (submitLegalReviewStatus === "loading") {
  //         setSubmitReviewDisabled(true);
  //       }
  //       setSubmitReviewDisabled(false);
  //     }
  //   }
  // }, [legalReviewSubmitted, threads, chatId]);

  useEffect(() => {
    if (status === "idle") {
      setMessage("");
    }
    if (status === "failed") {
      setErrorMessage("Failed to send message");
    }
  }, [status]);

  // refetch the messages when review is accepted and rejected. this will update everythign like messaging disabled, legal review accepted, legal review requested etc
  // useEffect(() => {
  //   if (acceptLegalReviewStatus === "success" || rejectLegalReviewStatus === "success") {
  //     dispatch(getThread(chatId));
  //   }
  // }, [acceptLegalReviewStatus, rejectLegalReviewStatus]);

  useEffect(() => {
    setMessage("");
    if (location.pathname !== "/lawyer") {
      dispatch(getThread(chatId));
      dispatch(updateSubmittedChats(chatId));
    }
    if (chatId !== null && selectedClient !== "") {
      dispatch(updateClientUnreadThreadMappings({ threadId: chatId, clientId: selectedClient, task: "remove" }));
    }
    setTimestamp(new Date());

    // dispatch(updateClientUnreadThreadMappings({ "threadId": chatId, "clientId": selectedClient, "mode": mode, "task": "add" }))
  }, [location.pathname]);

  if (chatId === undefined) {
    chatId = null;
    dispatch(enableNewChat());
  } else {
    dispatch(disableNewChat());
    dispatch(setThreadId(chatId));
  }

  const submitReview = () => {
    dispatch(submitLegalReview({ thread_id: chatId }));
  };

  const handleSend = () => {
    const creation_time = new Date().toISOString();
    scrollContainerToBottom();
    setMessageList([
      ...messageList,
      {
        created_at: new Date().toISOString(),
        user: user,
        text: message,
        sequence_number: messageList?.length + 1,
        id: Math.random() * 1000000,
        message_type: "conversation",
      },
    ]);
    dispatch(
      sendMessageLawyer({ message_text: message, thread_id: chatId, creation_time, message_type: "conversation" })
    );
  };
  const signout = () => {
    dispatch(handleLogout("/ask"));
  };
  const refetch = () => {
    dispatch(lawyerGetAllThreads({ userId: selectedClient }));
  };

  useEffect(() => {
    if (messageList?.length > 0) {
      var userImageMapping = {};
      var users = [];
      messageList?.forEach(function (message) {
        if (message.user != null) {
          if (message.user.user_type == "lawyer") {
            userImageMapping[message.user.user_id] = image;
          } else {
            userImageMapping[
              message.user.user_id
            ] = require(`../../images/white/${message.user.first_name[0].toUpperCase()}.png`);
          }
          if (users.indexOf(message.user.user_id) === -1) {
            users.push(message.user.user_id);
          }
        }
      });
      userImageMapping["lexxa"] = require(`../../images/lexxa-icon.png`);
      setUserImageMapping(userImageMapping);
      setInvolvedUsers(users);
    }
    try {
      var mydiv = document.getElementsByClassName("chat-content-area")[0];
      mydiv.scrollTo({ top: mydiv.scrollHeight, behavior: "smooth" });
    } catch (error) { }
  }, [messageList]);

  useEffect(() => {
    if (involvedUsers.length > 0 && Object.keys(clients).length > 0) {
      involvedUsers.forEach(function (user_id) {
        if (clients[user_id] !== undefined) {
          setSelectedClient(user_id);
        }
      });
    }
    clients?.map((client) => {
      client?.unread_thread_ids?.map((thread_id) => {
        if (thread_id !== chatId) {
          dispatch(
            updateClientUnreadThreadMappings({
              threadId: thread_id,
              clientId: client.id,
              // mode: "ask",
              task: "add",
            })
          );
        }
      });
    });
  }, [clients]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setUserImage(require(`../../images/lawyer.png`));
  }, []);

  const handleChange = (value) => {
    localStorage.setItem("selectedClientId", value);
    let client = clients.find((client) => client.id === value);
    localStorage.setItem("selectedClientDetails", JSON.stringify(client));
    setSelectedClient(value);
    navigate(`/lawyer`);
  };

  useEffect(() => {
    // Scroll to the bottom when messageList or sendMessageStatus changes
    scrollContainerToBottom();
  }, [messages, sendMessageLawyerStatus, messageList]);

  const scrollContainerToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (user && chatId && lastMessageId) {
      if (getThreadStatus === "success") {
        const collectionRef = collection(firestore, `frontend-updates-lawyer/${user.user_email}/new-messages`);
        var date = new Date();
        var q = query(collectionRef, where("timestamp", ">", date.toISOString()), orderBy("timestamp", "asc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          querySnapshot.docChanges().forEach((doc) => {
            var thread_id = doc.doc.data()["thread_id"];
            var clientId = doc.doc.data()["client_id"];
            var mode = doc.doc.data()["mode"];
            if (doc.type === "added") {
              if (thread_id !== chatId) {
                dispatch(updateClientUnreadThreadMappings({ threadId: thread_id, clientId: clientId, task: "add" }));
              } else {
                dispatch(getAdditionalMessages({ chatId: chatId, messageId: lastMessageId, task: "add" }));
                var isMessagingDisabled = doc.doc.data()["messaging_disabled"];
                dispatch(updateMessagingDisabled(isMessagingDisabled));
              }
            }
          });
        });
        return () => {
          unsubscribe();
        };
      }
    }
    // getThreadStatus == list-messages fetch status
  }, [getThreadStatus]); // eslint-disable-line

  return (
    <LawyerChatLayout>
      <section className="chat-body h-full w-full flex ">
        <div className="flex fex-row  w-full h-full">
          <div className="side-nav h-full flex w-[264px] pt-[30px] pb-[30px] flex-col flex-shrink-0">
            <div className="w-full flex justify-center items-center">
              {getLawyerInfoStatus === "loading" ? (
                <div class="skeleton-loader"></div>
              ) : (
                <Select
                  className="select-client-dropdown w-full"
                  style={{ width: 120 }}
                  value={selectedClient || null}
                  onChange={handleChange}
                  placeholder="Select a Client"
                  options={clients?.map((client) => ({
                    label: `${client?.info?.first_name} ${client?.info?.last_name}`,
                    value: client?.id,
                    unreadCount: clientUnreadThreadMappings?.[client.id]?.length || "",
                  }))}
                  optionLabelProp="label"
                  optionRender={(option) => (
                    <Space className="flex gap-[10px] justify-between">
                      {option.data.label}
                      <div className="badge rounded-full bg-[#46D78C] text-[13px] text-white font-[700] flex justify-center items-center">
                        {option.data.unreadCount}
                      </div>
                    </Space>
                  )}
                />
              )}
            </div>
            {selectedClient && (
              <div
                className="flex items-center gap-2 hover:cursor-pointer px-3 mt-[16px]"
                onClick={() => {
                  setShowCompannyInfo(true);
                }}
              >
                <Info size={25} className="text-[#6E40FF]" />
                <span className="text-black font-[500] text-[16px]">Company Information</span>
              </div>
            )}
            <div className="flex flex-col justify-between w-full h-full pl-4 pr-10 overflow-hidden mt-[20px]">
              {" "}
              <div className="side-nav-threads flex flex-col gap-6 h-full">
                {selectedClient && (
                  <div className="grid h-full overflow-hidden">
                    <div className="w-full h-full overflow-hidden gap-3 flex flex-col">
                      <h2
                        className={`mt-6 w-full flex justify-start gap-[12px] items-center  border-black flex-shrink-0  text-[14px] font-[500] mb-[8px]`}
                      >
                        <img height={18} width={18} src={previous} />
                        Previous
                      </h2>
                      <div className="w-full h-full overflow-scroll flex flex-col flex-grow">
                        {getAllThreadStatus === "loading" ? (
                          <div class="skeleton-loader"></div>
                        ) : threads?.length > 0 ? (
                          threads?.map((thread) => (
                            <Thread thread={thread} source="lawyer" chatId={chatId} mode="ask" />
                          ))
                        ) : (
                          <div className="flex justify-center items-center h-full w-full">
                            {/* @hemanth todo: add style here */}
                            <p className="text-[16px] font-[500] text-black">No threads to show</p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="w-full h-full overflow-hidden gap-3 flex flex-col">
                      <div
                        //   type="text"
                        className={`flex gap-[12px] items-center p-0 flex-shrink-0  text-[24px] font-[600] text-[#5E59FF] hover:text-[#5E59FF] `}
                      >
                        <img src={draft_icon} width={15} height={15} alt={draft_icon} />
                        Draft
                      </div>
                      <div className="w-full h-full overflow-scroll flex flex-col flex-grow">
                        {
                          getAllThreadStatus === "loading" ? (
                            <div class="skeleton-loader"></div>
                          ) : threadsDraft?.length > 0 ? (
                            threadsDraft?.map((thread) => (
                              <Thread
                                thread={thread}
                                source="lawyer"
                                chatId={chatId}
                                mode="draft"
                                clientId={selectedClient}
                              />
                            ))
                          ) : (
                            <div className="flex justify-center items-center h-full w-full">
                              <p className="text-[16px] font-[500] text-black">No threads to show</p>
                            </div>
                          )

                          // threadsDraft?.length > 0 &&
                          // threadsDraft?.map((thread) => <Thread thread={thread} source="lawyer" />)
                        }
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
              <Dropdown
                dropdownRender={(menu, index) => (
                  <div
                    className="nav-footer-dropdown flex flex-col items-start w-full gap-4 p-4 bg-white rounded-[5px] border"
                    key={index}
                  >
                    {items.map((item) => (
                      <>
                        {item.label !== "Logout" ? (
                          <Button
                            type="text"
                            className="text-black text-[16px] font-[500] p-0 h-fit"
                            onClick={() => {
                              navigate("/account/lawyer");
                            }}
                            id="navigation-account-lawyer"
                          >
                            {item.label}
                          </Button>
                        ) : (
                          <Button
                            type="text"
                            className="text-black text-[16px] font-[500] p-0 h-fit"
                            onClick={signout}
                            id="logout-button"
                          >
                            {item.label}
                          </Button>
                        )}
                      </>
                    ))}
                  </div>
                )}
                trigger={["click"]}
                className="hover:cursor-pointer"
              >
                <Space className="flex items-center gap-4 w-full">
                  <img src={image} alt="lawyer-profile" width={35} height={35} className="rounded-full flex-shrink-0" />
                  <div className=" text-black text-[16px] flex truncate  max-w-[104px]">
                    <p className="font-[700] truncate">{user?.first_name}</p>
                  </div>
                </Space>
              </Dropdown>
            </div>
          </div>

          {chatId && (
            <div className=" h-full w-full flex flex-col justify-between items-center ">
              {chatId ? (
                getThreadStatus !== "loading" ? (
                  <>
                    <div
                      ref={messageListRef}
                      className="h-full w-full max-w-[913px] m-auto pt-[50px] overflow-y-scroll"
                    >
                      {messageList?.length === 0 && <p>No messages to show</p>}
                      {messageList?.map((message, index) => (
                        <Chat
                          key={message.id}
                          isAIReply={message.user == null ? true : false}
                          imageUrl={
                            message.user == null ? userImageMapping["lexxa"] : userImageMapping[message.user?.user_id]
                          }
                          chatInfo={message}
                          viewerMode="lawyer"
                        />
                      ))}
                    </div>
                    {messageList?.length > 0 && sendMessageLawyerStatus === "loading" && (
                      <div className="loading-response w-full flex justify-center items-center">
                        {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                        <div class="rotating-cursor"></div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-full h-full flex justify-center items-center">
                    {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                    <div class="rotating-cursor"></div>
                  </div>
                )
              ) : (
                <div className="w-full h-full flex flex-col justify-end mb-6 items-center gap-[140px] max-w-[913px]"></div>
              )}
              <div className="w-full flex flex-col items-center justify-center">
                {sendMessageLawyerStatus === "failed" ? <p class="text-danger">{sendMessageLawyerError}</p> : null}
                <div className="chat-inputs-container mt-2 flex w-full max-w-[913px] mb-8 overflow-hidden gap-[10px] items-end">
                  {/* <img
                    className="cursor-pointer"
                    height={24}
                    width={24}
                    src={file_plus}
                    alt="file upload"
                    // onClick={() => {
                    //   setImageUploader(true);
                    // }}
                    id="file-upload-button"
                  /> */}
                  <TextArea
                    variant="borderless"
                    className="chat-input-text-area flex w-full flex-grow items-center"
                    placeholder={`Message ${clientDetails?.first_name || ""} ${clientDetails?.last_name || ""}`}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setErrorMessage("");
                    }}
                    autoSize={{ minRows: 1, maxRows: 8 }}
                    disabled={
                      sendMessageLawyerStatus === "loading" ||
                      messagingDisabled ||
                      submitLegalReviewStatus === "success"
                    }
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        !e.shiftKey &&
                        (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)
                      ) {
                        handleSend();
                      }
                    }}
                  />
                  <button
                    className="btn  btn-sm outline-[0px] border-[0px]"
                    onClick={handleSend}
                    disabled={sendMessageLawyerStatus === "loading" || messagingDisabled || message === ""}
                    id="send-message-button"
                  >
                    {/* <img height={24} width={24} src={paper_plane} alt="send message" /> */}
                    <img height={24} width={24} src={ArrowCircleUpColored} alt="send message" />

                  </button>
                </div>

                <div className="w-full py-2 justify-center items-center bg-[#F7F7F7]">
                  <p className="text-center text-[#757575] text-[12px] font-[500]">
                    Always consult your lawyer before relying on InHouse AI
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Modal
        centered
        open={showCompannyInfo}
        onOk={() => setShowCompannyInfo(false)}
        onCancel={() => setShowCompannyInfo(false)}
        className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
        footer={null}
      >
        {clients && selectedClient && (
          <div className="w-[831px] h-full flex flex-col items-start px-[28px] py-[40px] gap-[38px]">
            <div className="flex items-center justify-between w-full hover:cursor-pointer">
              <span className="text-black text-[30px] font-[800] leading-[1]">{`${clientDetails?.first_name}  ${" "}  ${clientDetails?.last_name
                }`}</span>

              <p
                className="text-[16px] font-[700] text-[#626262] leading-[1]"
                onClick={() => setShowCompannyInfo(false)}
              >
                CLOSE
              </p>
            </div>
            <div className="flex w-full gap-3">
              <div className="flex flex-col gap-[28px] items-start text-start text-[#0D0D0D] text-[16px] font-[500] leading-[1]">
                <span>Company:</span>
                <span>Industry:</span>
                <span>Email:</span>
                <span>Phone Number:</span>
                <span>Primary State of Business: </span>
              </div>
              <div className="flex flex-col gap-[28px] items-start text-start text-[#0D0D0D] text-[16px] font-[400] leading-[1]">
                <span>{clientDetails?.organization_name}</span>
                <span>{(clientDetails?.industry && clientDetails?.industry) || "-"}</span>
                <span>{clientDetails?.email || "-"}</span>
                <span>{clientDetails?.phone_number || "-"}</span>
                <span>{clientDetails?.state}</span>
              </div>
            </div>
            <div className="flex w-full gap-3">
              <div className="flex flex-col gap-[28px] items-start text-start text-[#0D0D0D] text-[16px] font-[500] leading-[1]">
                <span>How long has King Tide existed?</span>
                <span>How many employees does it have?</span>
                <span>How does your company handle legal work?</span>
                <span>How frequently does your company have legal needs?</span>
                <span>How much do you spend annually on legal work?</span>
              </div>
              <div className="flex flex-col gap-[28px] items-start text-start text-[#0D0D0D] text-[16px] font-[400] leading-[1]">
                <span>{clientDetails?.age_of_business}</span>
                <span>{clientDetails?.num_employees}</span>
                <span>{clientDetails?.current_legal_source}</span>
                <span>{clientDetails?.legal_work_frequency}</span>
                <span>{clientDetails?.annual_legal_spend} </span>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </LawyerChatLayout>
  );
}

export default LawyerChatComponent;
