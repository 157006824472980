import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "./ChatHeader.css"; // Import the associated CSS file
import logoImage from "../../images/lexxa-logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Dropdown, Space, ConfigProvider, Modal, Checkbox, Rate } from "antd";
import lawyer_profile from "../../images/lawyer-profile.png";
import lawyer_profile_svg from "../../images/lawyer-profile.svg";
import expert from "../../images/expert.svg";
import time from "../../images/time.svg";
import chat from "../../images/chat.svg";
import info from "../../images/info.svg";
import { PopupButton } from "react-calendly";
import { addLegalReviewFeedback, getRetainer, requestLegalReview } from "../../pages/chatpage/chatSlice";
import { useDispatch } from "react-redux";
import { Spinner, X } from "@phosphor-icons/react";
import { use } from "marked";
import { updateThreadFeedBackLocal } from "../../pages/chatpage/threadsSlice";
import Joyride, { CallBackProps, ACTIONS, EVENTS, STATUS } from "react-joyride";

const engagementContract = `Date Created: 12/12/2024

Lorem ipsum dolor sit amet consectetur. Habitant at nulla turpis mauris et nec. Amet dictum neque nunc quis tincidunt adipiscing integer. Purus pellentesque nunc auctor curabitur. Pellentesque tellus fermentum leo eu accumsan enim amet est sit. Eu velit in odio pellentesque est ullamcorper. Quam id rhoncus orci viverra eget lobortis. Ut et mattis sollicitudin lobortis faucibus amet quam adipiscing ut. Neque sagittis lectus sagittis lacus scelerisque. Ac platea nisl egestas eget eget dolor augue faucibus. Vitae aliquam facilisis risus urna ipsum dictum non. Viverra nisl lectus sit nulla ornare libero. Varius massa proin ultricies et enim adipiscing proin tincidunt. Quis hac ligula sit urna sollicitudin. Augue urna sed nunc cursus neque dictumst. 

Viverra lorem aliquam et maecenas velit non blandit. Adipiscing risus netus enim donec at. Consectetur ac feugiat vel netus sollicitudin pharetra aliquam pretium. At malesuada nullam elit nibh. Ut enim eu auctor lobortis duis. Rhoncus velit sed eget tortor. Amet non gravida risus neque mattis a blandit cursus. Imperdiet sit tincidunt tellus mi bibendum nullam magna. Sit orci cras nulla massa mattis tortor ultricies morbi. Suspendisse volutpat nibh magnis non ut vel integer sit. In pharetra purus magna cursus. Metus nulla arcu egestas iaculis dolor condimentum eget vitae facilisi. Libero pharetra quis urna eleifend amet at. Scelerisque tellus proin morbi egestas nisl. `;

const cost = 0;

export const legalReviewFeaturesNew = [
  {
    title: "Expert Lawyer Match",
    description:
      "InHouse will match you with a top rated lawyer who specializes in whatever work you need. We have access to over 100,000 lawyers in dozens of practice areas in all 50 states, from big firm partners to solo-practioners.",
    image: expert,
  },
  {
    title: "Prompt Response Time",
    description:
      "We’ll email you our suggested lawyer within 2 business days. They’ll review your chat history and client information for fast, easy onboarding.",
    image: time,
  },
  {
    title: "Free Consultation",
    description:
      "You’ll have the opportunity to schedule a free 20 minute consultation call. If you decide to hire the lawyer, you’ll pay hourly to finalize the AI workproduct.",
    image: chat,
  },
];

const legalReviewFeaturesAsk = [
  {
    title: "Share legal issue",
    description: "Your chat will be shared with your lawyer, but you can add missing context or instructions ",
  },
  {
    title: "Get a response",
    description:
      "Your lawyer has 2 business days to provide an initial response - which could mean doing the work, asking questions, or providing a time estimate for longer projects",
  },
  {
    title: "Pay only for lawyer time",
    description: "You’ll only be charged for your lawyer’s time and you’ll know the price before any work begins. ",
  },
];

const legalReviewFeaturesDraft = [
  {
    title: "Share legal issue",
    description: "Your chat will be shared with your lawyer, but you can add missing context or instructions",
  },
  {
    title: "Get a response",
    description:
      "Your lawyer has 2 business days to provide an initial response - which could mean doing the work, asking questions, or providing a time estimate for longer projects",
  },
  {
    title: "Pay only for lawyer time",
    description: "You’ll only be charged for your lawyer’s time and you’ll know the price before any work begins. ",
  },
];

const paymentTermsDraft =
  "Before any work begins, you will receive an estimate from your lawyer on how long it will take to review and edit your document.  During the Pilot, you can arrange payment with your lawyer directly. ";

const paymentTermsAsk =
  "Before any work begins, you will receive an estimate from your lawyer on how long it will take to review and edit your document.  During the Pilot, you can arrange payment with your lawyer directly. ";

const ChatHeader = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { chatId } = useParams();

  const [retainermodalOpen, setRetainerModalOpen] = useState(false);
  // const [retainerNewmodalOpen, setRetainerNewmodalOpen] = useState(false);
  const [retainerEmbedmodalOpen, setRetainerEmbedModalOpen] = useState(false);
  // const [retainerSigned, setRetainerSigned] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [engagementmodalOpen, setEngagementModalOpen] = useState(false);
  const [engagementSigned, setengagementSigned] = useState(false);
  const [checked, setChecked] = useState(false);
  const [rating, setRating] = useState(4);
  const [embedLink, setEmbedLink] = useState("");
  const [hasRetainerSigned, setHasRetainerSigned] = useState(false);
  // const raiseLegalReview = useRef(null);
  const [run, setRun] = useState(true);
  const [steps, setSteps] = useState([
    {
      target: ".request-legal-review",
      content: "Once you’re done chatting with AI, click here to get a human lawyer to verify the AI’s responses.",
      placement: "bottom",
      disableBeacon: true,
      hideFooter: true,
      spotlightPadding: 0,
    },
    // {
    //   target: ".my-second-step",
    //   content: "This step focuses on another element",
    //   placement: "bottom",
    // },
    // Add more steps as needed
  ]);

  const showTutorial = localStorage.getItem("showTutorial");

  const isDraft = window.location.pathname.includes("draft");

  const legalReviewFeatures = isDraft ? legalReviewFeaturesDraft : legalReviewFeaturesAsk;

  // const userType = useSelector((state) => state.auth.userType);
  // const retainerAgreement = useSelector((state) => state.chat.retainerAgreement);
  const threadLegalReviewStatus = useSelector((state) => state.chat.status);
  const [reviewStatus, setReviewStatus] = useState("pending");
  const [reviewRequest, setReviewRequest] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [threadSelected, setThreadSelected] = useState({});
  const [legalReviewAvailable, setLegalReviewAvailable] = useState(false);
  const [legalReviewRequestCreated, setLegalReviewRequestCreated] = useState(false);
  const [lawyer, setLawyer] = useState({});

  const user = useSelector((state) => state.auth.user);
  // const user = useSelector((state) => state.auth.user);
  // const is_retainer_signed = useSelector((state) => state.auth.is_retainer_signed);
  const legalReviewRequested = useSelector((state) => state.chat.legalReviewRequested);
  const legalReviewAccepted = useSelector((state) => state.chat.legalReviewAccepted);
  const requestLegalReviewStatus = useSelector((state) => state.chat.requestLegalReviewStatus);
  const legalReviewSubmitted = useSelector((state) => state.chat.legalReviewSubmitted);
  const legalReviewViewed = useSelector((state) => state.chat.legal_review_viewed);
  const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
  const legalReviewFedbackStatus = useSelector((state) => state.chat.legalReviewFedbackStatus);
  const selectedThread = useSelector((state) => state.chat.selectedThread);

  const is_lawyer_prospective = useSelector((state) => state.auth.is_lawyer_prospective);
  const lawyerDetails = useSelector((state) => state.auth.lawyerInfo);

  const getRetainerStatus = useSelector((state) => state.chat.getRetainerStatus);
  const getRetainerError = useSelector((state) => state.chat.getRetainerError);
  const retainerLink = useSelector((state) => state.chat.retainerLink);

  let name = lawyerDetails?.first_name
    ? lawyerDetails?.first_name?.[0].toUpperCase()
    : lawyerDetails?.user_email?.[0]?.toUpperCase() || "A";
  const imagePath = lawyerDetails?.profile_picture_url
    ? lawyerDetails?.profile_picture_url
    : require(`../../images/white/${name}.png`);

  useEffect(() => {
    if (user && user?.is_retainer_signed) {
      setHasRetainerSigned(true);
    }
  }, [user]);

  useEffect(() => {
    setReviewStatus("");
    setReviewRequest("");
    setFeedbackMessage("");
    setLegalReviewAvailable(false);
    setLegalReviewRequestCreated(false);
  }, [chatId]);

  useImperativeHandle(ref, () => ({
    // Define methods to be exposed via ref
    // For example, you can define a method to focus on the header element
    focus: () => {
      // Access the header element using ref.current and perform actions
      if (ref.current) {
        ref.current.focus();
      }
    },
    // Add more methods as needed
  }));

  // useEffect(() => {
  //   if (retainerDocumentSigned) {
  //     setengagementSigned(true);
  //     setEngagementModalOpen(false);
  //     createLegalReviewRequest();
  //   }
  // }, [retainerDocumentSigned]);

  // if the thread has legalreviewaccepted, then we will mark the status as complete
  useEffect(() => {
    let status = "";

    if (legalReviewSubmitted) {
      status = "completed";
    } else if (legalReviewAccepted && !legalReviewSubmitted) {
      status = "in-progress";
    } else if (legalReviewRequested && !legalReviewAccepted) {
      status = "pending";
    } else if (!legalReviewRequested) {
      status = "";
    }
    setReviewStatus(status);
  }, [legalReviewAccepted, legalReviewRequested, legalReviewSubmitted]);

  // if the lawyer is not assigned to the user, we will mark the review status as pending
  // we will also mark engagementSigned as false
  // useEffect(() => {
  //   if (!lawyerDetails) {
  //     setReviewStatus("pending");
  //   }
  // }, [lawyerDetails]);

  // as soon as the retainer is signed, we will open the engagement modal open
  // useEffect(() => {
  //   if (retainerSigned) {
  //     setEngagementModalOpen(true);
  //   }
  // }, [retainerSigned]);

  // if the engagement is signed, we will set the review status as pending.
  useEffect(() => {
    if (engagementSigned) {
      setReviewStatus("pending");
    }
  }, [engagementSigned]);

  useEffect(() => {
    setThreadSelected(selectedThread);
  }, [selectedThread]);

  useEffect(() => {
    if (legalReviewFedbackStatus === "success") {
      setReviewModalOpen(false);
      dispatch(
        updateThreadFeedBackLocal({ threadId: chatId, mode: isDraft ? "draft" : "ask", legal_review_feedback: rating })
      );
      setThreadSelected({ ...selectedThread, legal_review_feedback: rating });
    }
  }, [legalReviewFedbackStatus]);

  useEffect(() => {
    if (legalReviewRequested) {
      setLegalReviewRequestCreated(true);
    }
    if (engagementSigned) {
      setLegalReviewAvailable(true);
    }
  }, [legalReviewRequested, engagementSigned]); // eslint-disable-line

  const hasAgreed = user?.has_agreed || false;

  useEffect(() => {
    if (lawyerDetails) {
      setLawyer({
        name: lawyerDetails.first_name + " " + lawyerDetails.last_name,
        experience: lawyerDetails.num_years_experience,
        bar: "California Bar",
        institution: lawyerDetails.education,
        ratings: lawyerDetails.num_5star_reviews,
        calendly_link: lawyerDetails.calendly_link,
      });
    } else {
      setLawyer({
        name: "Alexa Johnson",
        experience: 10,
        bar: "California Bar",
        institution: "UCLA",
        ratings: 350,
      });
    }
  }, [lawyerDetails]);

  useEffect(() => {
    if (requestLegalReviewStatus === "success" || requestLegalReviewStatus === "failed") {
      window.analytics.track("Legal Review Requested Successfully", { user_email: user?.user_email });
      setEmbedLink("");
      setRetainerModalOpen(false);
    }
  }, [requestLegalReviewStatus]);

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const getThreadLegalReviewStatus = (status) => {
    switch (status) {
      case "pending":
        return "Pending";
      case "in-progress":
        return "In Progress";
      case "completed":
        return "Completed";
      default:
        return "Pending";
    }
  };

  const handleReviewRequestChange = (event) => {
    setReviewRequest(event.target.value);
  };

  const handleFeedbackChange = (event) => {
    setFeedbackMessage(event.target.value);
  };

  const createLegalReviewRequest = () => {
    dispatch(requestLegalReview({ threadId: chatId, reviewRequest: reviewRequest }));
  };

  useEffect(() => {
    const handleMessage = (params) => {
      if (params.origin !== "https://app.boldsign.com") {
        return;
      }

      switch (params.data.action) {
        case "onDocumentSigned":
          // setengagementSigned(true);
          setRetainerEmbedModalOpen(false);
          createLegalReviewRequest();
          setEmbedLink("");
          setHasRetainerSigned(true);
          // console.log("document signed");

          break;
        case "onDocumentSigningFailed":
          // handle document signing failure
          setEmbedLink("");
          setRetainerEmbedModalOpen(false);
          // console.log("document signing failed");
          break;
        case "onDocumentReassigned":
          // handle document reassigning success
          break;
        case "onDocumentReassigningFailed":
          // handle document reassigning failure
          break;
        case "onDocumentDeclined":
          // handle document declining success
          setEmbedLink("");
          setRetainerEmbedModalOpen(false);
          break;
        case "onDocumentDecliningFailed":
          // handle document declining failure
          setEmbedLink("");
          setRetainerEmbedModalOpen(false);
          break;
        default:
          break;
      }
    };

    if (retainerLink && getRetainerStatus === "success") {
      setEmbedLink(retainerLink);
      setRetainerEmbedModalOpen(true);
      setRetainerModalOpen(false);

      // setEngagementModalOpen(false);
      window.addEventListener("message", handleMessage);
    } else if (getRetainerStatus === "failed") {
      setEngagementModalOpen(false);
      // createLegalReviewRequest();
    }

    return () => {
      if (retainerLink) {
        window.removeEventListener("message", handleMessage);
      }
    };
  }, [retainerLink, getRetainerStatus]); // eslint-disable-line

  const handlePrepareRetainer = () => {
    dispatch(getRetainer());
  };

  const handleJoyrideCallback = (data) => {
    const { status, type, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || type === EVENTS.TOUR_END) {
      setRun(false);
    }
    if (action === "close") {
      // localStorage.removeItem("showTutorial");
    }
  };

  // useEffect(() => {
  //   if ((chatId, raiseLegalReview, showTutorial, isDraft)) {
  //     setTimeout(() => {
  //       setRun(true);
  //     }, 2000);
  //   }
  // }, [chatId, raiseLegalReview, showTutorial]);

  return (
    <>
      <header className="chat-app-header flex">
        <img
          src={logoImage}
          alt="Logo"
          className="logo-image hover:cursor-pointer"
          onClick={() => {
            if (window.location.pathname.includes("shared")) {
              window.location.href = "https://www.inhouse.so/";
            } else {
              navigate("/ask");
            }
          }}
          id="navigation-home"
        />
        {!window.location.pathname.includes("shared") ? (
          <div className="flex items-center gap-6 h-full ">
            {reviewStatus && chatId ? (
              <div
                className={`text-[#5E59FF] text-[16px] font-[500] text-start mr-6  flex flex-col items-start justify-center leading-[1] ${getThreadStatus === "loading" && "hidden"
                  }`}
              >
                Status: {getThreadLegalReviewStatus(reviewStatus)}
                {reviewStatus === "completed" && (
                  <span
                    className="underline cursor-pointer"
                    onClick={() => {
                      window.analytics.track("Leave Review", { user_email: user?.user_email });
                      setReviewModalOpen(true);
                    }}
                    id="leave-review-button"
                  >
                    Leave Review
                  </span>
                )}
              </div>
            ) : chatId && !legalReviewRequestCreated ? (
              <>
                {/* <Button
                  className={`request-legal-review bg-[#5E59FF] py-3 px-6 h-[42px] flex items-center text-[16px] font-[500] text-white rounded-[10px] ${getThreadStatus === "loading" && "hidden"
                    }`}
                  onClick={() => {
                    window.analytics.track("Get Free Legal Consultation");
                    setRetainerModalOpen(true);
                    // setRetainerModalOpen(true);
                  }}
                  id="request-legal-review-button"
                >
                  Get Free Legal Consultation
                </Button> */}
              </>
            ) : null}
            {lawyerDetails && lawyer && (
              <Dropdown
                dropdownRender={(menu) => (
                  <div className="chat-header-dropdown flex flex-col justify-center items-start w-[250px] gap-4 p-6 bg-white rounded-[5px] border mt-[30px]">
                    <p className="text-black text-[16px] font-[700]">Experience</p>
                    <div className="flex flex-col justify-center items-start gap-1">
                      <div className="flex items-center justify-start gap pl-2 text-black text-[16px] font-[400] gap-2">
                        <div className="h-1 w-1 bg-black rounded-full" />
                        {lawyer.bar}
                      </div>
                      <div className="flex items-center justify-start gap pl-2 text-black text-[16px] font-[400] gap-2">
                        <div className="h-1 w-1 bg-black rounded-full" />
                        {lawyer.institution}
                      </div>
                      <div className="flex items-center justify-start gap pl-2 text-black text-[16px] font-[400] gap-2">
                        <div className="h-1 w-1 bg-black rounded-full" />
                        {`${lawyer.experience} Years of experience`}
                      </div>
                      <div className="flex items-center justify-start gap pl-2 text-black text-[16px] font-[400] gap-2">
                        <div className="h-1 w-1 bg-black rounded-full" />
                        {`${lawyer.ratings} 5-Star Reviews`}
                      </div>
                    </div>

                    <PopupButton
                      id="schedule-video-call-button"
                      url={lawyer.calendly_link}
                      rootElement={document.getElementById("root")}
                      text="Schedule Video Call"
                      onClick={() => {
                        window.analytics.track("Schedule Video Call clicked", {
                          lawyer: lawyer.name,
                          url: window.location.href,
                          path: window.location.pathname || window.location.path,
                          user_email: user?.user_email
                        })
                      }}

                      className="text-[#5E59FF] text-[16px] font-[500] hover:bg-none"
                    />
                  </div>
                )}
                trigger={["click"]}
                className="hover:cursor-pointer"
              >
                <Space>
                  <Space className="hidden md:flex items-center gap-4">
                    <div className="text-end text-black text-[16px] flex flex-col items-end justify-center">
                      <p className="font-[500]">
                        {is_lawyer_prospective ? "Your Prospective Lawyer" : "Your Lawyer,"}{" "}
                      </p>
                      <p className="font-[700]">{lawyer.name}</p>
                    </div>
                    <img
                      src={imagePath}
                      alt="lawyer-profile"
                      width={35}
                      height={35}
                      className="rounded-full flex-shrink-0"
                    />
                  </Space>
                  {!chatId && (
                    <Space className="flex md:hidden items-center gap-4">
                      <div className="text-end text-black text-[16px] flex flex-col items-end justify-center">
                        <p className="font-[500]">
                          {is_lawyer_prospective ? "Your Prospective Lawyer" : "Your Lawyer,"}{" "}
                        </p>
                        <p className="font-[700]">{lawyer.name}</p>
                      </div>
                      <img
                        src={imagePath}
                        alt="lawyer-profile"
                        width={35}
                        height={35}
                        className="rounded-full flex-shrink-0"
                      />
                    </Space>
                  )}
                </Space>
              </Dropdown>
            )}
          </div>
        ) : (
          <Button
            className={`bg-[#5E59FF] py-3 px-6 h-[42px] flex items-center text-[16px] font-[500] text-white rounded-[10px] `}
            onClick={() => { window.location.href = "https://inhouse.so/auth" }}
            id="request-legal-review-button"
            ref={ref}
          >
            Sign Up
          </Button>
        )}
        {/* <Modal
          centered
          open={retainermodalOpen}
          onOk={() => setRetainerModalOpen(false)}
          onCancel={() => setRetainerModalOpen(false)}
          className="legal-review-pop-up h-fit rounded-[30px] bg-[#fff] overflow-hidden"
          footer={null}
        >
          <div className="w-full h-full flex ">
            <div className="md:flex flex-col hidden w-[300px] min-h-full bg-[#6E40FF] pt-[60px] pl-[30px] pr-5">
              <span className="text-[#fff] text-[22px] font-[700] leading-normal">
                Using Lexxa for the initial work saves ~90%{" "}
              </span>
              <div className="flex flex-col items-start gap-10 mt-10">
                {legalReviewFeatures.map((feature, index) => (
                  <div className="flex items-start gap-3 text-white">
                    <div className=" flex items-start justify-center">
                      <span className=" text-[18px] font-[700]">{index + 1}.</span>
                    </div>
                    <div className="flex flex-col items-start justify-center text-start">
                      <span className=" text-[18px] font-[700] leading-[1.2]">{feature.title}</span>
                      <span className=" text-[12px] font-[400] leading-[1.2]">{feature.description}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="md:w-[750px] min-h-full md:pt-11 md:pb-[25px] md:pr-[40px] md:pl-[50px] flex flex-col items-start w-full p-[40px]">
              <span className="text-[20px] md:text-[30px] font-[700] text-black ">
                Request Legal Review - {isDraft ? "Draft" : "Ask"}{" "}
              </span>
              <span className="text-[16px] font-[500] text-black">
                This chat {isDraft && "and document"} will be shared with your {!engagementSigned && "prospective"}{" "}
                attorney:
              </span>
              <div className="w-full mt-6 gap-[30px] md:flex md:flex-row flex-col mb-10">
                <div className="flex h-[200px] w-full md:h-[100px] md:w-[100px] flex-shrink-0 overflow-hidden md:mb-0 mb-[30px] ">
                  <img
                    src={imagePath}
                    className="flex-shrink-0"
                    style={{ objectFit: "cover", width: "100%", height: "100%" }}
                  />
                </div>
                <div className="flex flex-col items-start">
                  <div className="flex-col md:flex-row gap-2 leading-[1.2]">
                    <span className="text-[18px] font-[700] text-black ">{lawyer.name}</span>{" "}
                    <a href="" className="italic text-[16px] font-[400] text-[#5E59FF]">
                      Schedule A Video Call
                    </a>
                  </div>
                  <div className="flex flex-col items-start text-[16px] font-[400] text-black leading-[1.2]">
                    <span>Education: {lawyer.institution}</span>
                    <span>Years Experience: {lawyer.experience}</span>
                    <span>5 Star Reviews: {lawyer.ratings}</span>
                    <span>Specialty: Contracts, Formation, Trademarks, General Business</span>
                  </div>
                </div>
              </div>
              <textarea
                id="review request"
                name="review request"
                value={reviewRequest}
                onChange={handleReviewRequestChange}
                // rows={4}
                style={{ resize: "none" }}
                className="md:px-6 md:py-4 p-[8px] bg-[#EAEAEA] w-full rounded-[10px] text-[16px] font-[400] text-black h-[100px] placeholder:italic placeholder:text-black placeholder:leading-[1.2]"
                placeholder="Feel free to describe your desired scope of work, business goals, or extra context"
              ></textarea>
              <div className="flex items-start flex-col w-full mt-10 gap-4">
                <div className="flex items-center gap-[16px] flex-col md:flex-row">
                  <span className="text-[16px] font-[700] text-black leading-[1]">
                    Payment Terms: {"Hourly"}
                  </span>
                </div>
                <span className="text-[16px] font-[400] text-black leading-[1.2]">
                  {isDraft ? paymentTermsDraft : paymentTermsAsk}
                </span>
                <div className="flex items-center justify-center text-[12px] font-[400] text-black leading-[1.2] md:leading-[1] italic bg-[#D5C8FF] py-2 px-4 rounded-[4px]">
                  Legal Review is currently only available in California. You can continue with your submission but it
                  may get rejected.
                </div>
              </div>
              <div className="flex items-center justify-end w-full mt-4 gap-4">
                <button
                  className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                  onClick={() => setRetainerModalOpen(false)}
                  id="cancel-legal-review-button"
                >
                  Cancel
                </button>
                <button
                  className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] md:py-3 md:px-[50px] px-[16px] py-[8px] rounded-[10px] w-[310px] h-[48px] text-center flex justify-center items-center"
                  onClick={() => {
                    // if (!hasRetainerSigned) {
                    //   handlePrepareRetainer();
                    // } else {
                    createLegalReviewRequest();
                    // }
                  }}
                  id="raise-legal-review-button"
                >
                  {
                    getRetainerStatus === "loading" || requestLegalReviewStatus === "loading" ? (
                      <Spinner size={16} className="animate-spin text-black" />
                    ) : (
                      //  !hasRetainerSigned ? (
                      //   "Review Retainer Agreement"
                      // ) : (
                      "Request Legal Review"
                    )
                    // )
                  }
                </button>
              </div>
            </div>
          </div>
        </Modal> */}
        <Modal
          centered
          open={engagementmodalOpen}
          onOk={() => setEngagementModalOpen(false)}
          onCancel={() => setEngagementModalOpen(false)}
          className="engagement-pop-up h-fit rounded-[30px] bg-[#fff] overflow-hidden"
          footer={null}
        >
          <div className="w-[900px] h-full flex flex-col items-start px-[30px] py-[50px] gap-[30px]">
            <span className="text-black text-[24px] font-[600]">Sign Engagement Letter</span>
            <textarea
              id="engage contract"
              name="engage contract"
              value={engagementContract}
              // onChange={handleFeedbackChange}
              // rows={4}
              style={{ resize: "none" }}
              className="px-6 py-4 bg-[#EAEAEA] w-full rounded-[10px] text-[12px] font-[500] text-black h-[260px] placeholder:italic placeholder:text-black overflow-auto scrollbar-thin scrollbar-thumb-gray-500"
            // placeholder="Feel free to describe your desired scope of work, business goals, or extra context"
            ></textarea>
            <Checkbox checked={checked} onChange={onChange} className="flex gap-1 text-black text-[16px] font-[400]">
              I have read and agree with the terms of this engagement letter
            </Checkbox>
            <div className="flex flex-row justify-between items-end  w-full">
              <div className="flex flex-col items-start gap-2">
                {/* <span className="text-black text-[16px] font-[700]">Draw Signature</span> */}
                {/* <textarea
                id="signature"
                name="signature"
                // value={engagementContract}
                // onChange={handleFeedbackChange}
                // rows={4}
                style={{ resize: "none" }}
                className="px-6 py-4 bg-[#EAEAEA] w-[370px] rounded-[13px] text-[12px] font-[500] text-black h-[84px] placeholder:italic placeholder:text-black overflow-auto scrollbar-thin scrollbar-thumb-gray-500"
                // placeholder="Feel free to describe your desired scope of work, business goals, or extra context"
              ></textarea> */}
              </div>
              <div className="flex items-center justify-end w-full gap-4 h-full ">
                <button
                  className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                  onClick={() => setEngagementModalOpen(false)}
                  id="cancel-engagement-button"
                >
                  Cancel
                </button>
                <button
                  disabled={!checked}
                  className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-3 px-[50px] rounded-[10px]"
                  onClick={() => {
                    handlePrepareRetainer();
                  }}
                  id="submit-engagement-button"
                >
                  Accept & Submit
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          centered
          open={reviewModalOpen}
          onOk={() => setReviewModalOpen(false)}
          onCancel={() => setReviewModalOpen(false)}
          className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
          footer={null}
        >
          <div className="md:w-[690px] w-full h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
            <span className="text-black text-[30px] font-[800] leading-[1]">Leave a Review</span>
            <span className="text-black text-[16px] font-[500] leading-[1]">
              This review will be kept private and used by the InHouse team to improve our service.
            </span>

            <Rate
              value={threadSelected?.legal_review_feedback ? threadSelected?.legal_review_feedback : rating}
              defaultValue={4}
              onChange={setRating}
              id="review-rating"
              disabled={threadSelected?.legal_review_feedback}
            />

            <div className="flex flex-col items-start gap-3 w-full">
              <span className="text-black text-[16px] font-[500] leading-[1]">
                Please share any additional feedback on this request.{" "}
              </span>
              <textarea
                id="feedback"
                name="feedback"
                value={feedbackMessage}
                onChange={handleFeedbackChange}
                // rows={4}
                style={{ resize: "none" }}
                className="px-6 py-4 bg-[#EAEAEA] w-full rounded-[13px] text-[12px] font-[500] text-black h-[84px]  placeholder:text-black overflow-auto scrollbar-thin scrollbar-thumb-gray-500"
                placeholder="Share feedback"
              ></textarea>
            </div>
            <div className="flex items-center justify-start w-full gap-4 h-full ">
              <button
                disabled={legalReviewFedbackStatus === "loading" || threadSelected?.legal_review_feedback}
                className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-3 px-[50px] rounded-[10px] disabled:cursor-not-allowed disabled:opacity-50"
                onClick={() => {
                  dispatch(addLegalReviewFeedback({ threadId: chatId, rating, feedbackMessage }));
                  window.analytics.track("Legal Review feedback submitted", { user_email: user?.user_email });
                }}
                id="submit-review-button"
              >
                {legalReviewFedbackStatus === "loading" ? (
                  <Spinner size={16} className="animate-spin text-black" />
                ) : threadSelected?.legal_review_feedback ? (
                  "REVIEW SUBMITTED"
                ) : (
                  "SUBMIT REVIEW"
                )}
              </button>
            </div>
          </div>
        </Modal>
        {embedLink && getRetainerStatus === "success" && (
          <Modal
            centered
            open={retainerEmbedmodalOpen}
            onOk={() => setRetainerEmbedModalOpen(false)}
            // onCancel={() => setRetainerEmbedModalOpen(false)}
            className="retainer-pop-up h-[90vh] w-[90vw] rounded-[30px] bg-[#fff] overflow-hidden"
            footer={null}
          >
            <div className="h-[90vh] w-[90vw]">
              <iframe src={embedLink} title="retainer" width="100%" height="100%"></iframe>
            </div>
          </Modal>
        )}
      </header>
      <Modal
        centered
        open={retainermodalOpen}
        onOk={() => setRetainerModalOpen(false)}
        onCancel={() => setRetainerModalOpen(false)}
        className="legal-review-pop-up h-fit rounded-[30px] bg-[#fff] overflow-hidden"
        footer={null}
      >
        <div className=" w-full h-full flex flex-col">
          <div className="md:w-[692px] min-h-full md:pt-11 md:pb-[25px] md:pr-[40px] md:pl-[40px] flex flex-col items-start w-full p-[40px]">
            <span className="text-[20px] md:text-[30px] font-[700] text-black flex justify-between items-center gap-4 w-full">
              Let a Lawyer Take this to the Finish Line
              <X
                size={30}
                className="hover:cursor-pointer"
                color="black"
                onClick={() => setRetainerModalOpen(false)}
                id="cancel-legal-review-button"
              />
            </span>

            <span className="text-[16px] font-[500] text-[#626262]">
              Leveraging our expert legal AI for the initial work enables lawyers to deliver top quality results for up
              to 80% less.
            </span>
            <div className="flex flex-col gap-[28px] mt-[20px] mb-[20px]">
              {legalReviewFeaturesNew.map((feature, index) => (
                <div className="flex gap-[12px] flex-row">
                  <img className="h-[38px] w-[38px]" src={feature.image}></img>
                  <div className="flex flex-col gap-[8px]">
                    <span className="text-[16px] md:text-[20px] font-[700] text-black leading-[1]">
                      {feature.title}
                    </span>
                    <span className="text-[16px] font-[400] text-black">{feature.description}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex w-full h-[1px] bg-[#DFDFDF] my-[8px]" />
            <div className="w-full flex md:flex-row flex-col justify-between md:items-center items-start md:gap-0 gap-[16px]">
              <div className="flex justify-between items-center gap-[8px]">
                <img className="w-[16px] h-[16px]" src={info}></img>
                <span className="text-[16px] font-[400] text-black italic leading-[1]">
                  Your legal credits: ${user?.referral_discount_available}
                </span>
              </div>
              <span className="text-[14px] font-[400] text-[#626262] leading-[1]">Satisfaction gauranteed</span>
            </div>
            {/* <div className="flex justify-center md:justify-start items-center gap-[8px] mt-[16px] w-full">
              <textarea
                id="promo code"
                name="promo code"
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value);
                }}
                // rows={4}
                style={{ resize: "none" }}
                className="md:px-[12px] md:py-[8px] p-[8px] bg-[#EAEAEA] md:w-[176px] w-full rounded-[6px] text-[12px] font-[400] text-black h-[32px] placeholder:text-black placeholder:leading-[1.2] "
                placeholder="Add Promo Code"
              ></textarea>
              {promoCode && promoCode === "FIRST100" && (
                <div className="flex justify-center items-center gap-[8px]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path
                      d="M11 2.0625C9.23233 2.0625 7.50436 2.58668 6.0346 3.56874C4.56483 4.55081 3.41929 5.94665 2.74283 7.57977C2.06637 9.21288 1.88938 11.0099 2.23424 12.7436C2.57909 14.4773 3.43031 16.0698 4.68024 17.3198C5.93017 18.5697 7.52268 19.4209 9.25638 19.7658C10.9901 20.1106 12.7871 19.9336 14.4202 19.2572C16.0534 18.5807 17.4492 17.4352 18.4313 15.9654C19.4133 14.4956 19.9375 12.7677 19.9375 11C19.933 8.63102 18.9899 6.36037 17.3148 4.68525C15.6396 3.01013 13.369 2.06704 11 2.0625ZM15.2539 9.43594L10.218 14.2484C10.0878 14.3709 9.91546 14.4386 9.73672 14.4375C9.64936 14.4388 9.56261 14.4227 9.48149 14.3902C9.40036 14.3578 9.32647 14.3096 9.26407 14.2484L6.7461 11.8422C6.67626 11.7813 6.61946 11.7068 6.57912 11.6234C6.53877 11.5399 6.51572 11.4492 6.51134 11.3566C6.50696 11.264 6.52134 11.1715 6.55363 11.0847C6.58591 10.9978 6.63543 10.9183 6.69921 10.8511C6.76298 10.7838 6.83969 10.7302 6.92474 10.6933C7.00978 10.6565 7.1014 10.6372 7.19408 10.6366C7.28676 10.6361 7.37859 10.6543 7.46406 10.6902C7.54953 10.726 7.62686 10.7788 7.69141 10.8453L9.73672 12.7961L14.3086 8.43906C14.4423 8.32244 14.6159 8.262 14.7931 8.27038C14.9703 8.27876 15.1374 8.35532 15.2594 8.48405C15.3815 8.61278 15.4491 8.78371 15.4481 8.9611C15.447 9.1385 15.3775 9.30864 15.2539 9.43594Z"
                      fill="#7AB971"
                    />
                  </svg>
                  <p>Promo applied: $100 Off</p>
                </div>
              )}
              {promoCode && promoCode !== "FIRST100" && (
                //   <svg
                //   xmlns="http://www.w3.org/2000/svg"
                //   width="22"
                //   height="22"
                //   viewBox="0 0 22 22"
                //   fill="none"
                // >
                //   <path
                //     d="M11 2.0625C9.23233 2.0625 7.50436 2.58668 6.0346 3.56874C4.56483 4.55081 3.41929 5.94665 2.74283 7.57977C2.06637 9.21288 1.88938 11.0099 2.23424 12.7436C2.57909 14.4773 3.43031 16.0698 4.68024 17.3198C5.93017 18.5697 7.52268 19.4209 9.25638 19.7658C10.9901 20.1106 12.7871 19.9336 14.4202 19.2572C16.0534 18.5807 17.4492 17.4352 18.4313 15.9654C19.4133 14.4956 19.9375 12.7677 19.9375 11C19.933 8.63102 18.9899 6.36037 17.3148 4.68525C15.6396 3.01013 13.369 2.06704 11 2.0625ZM10.3125 6.875C10.3125 6.69266 10.3849 6.5178 10.5139 6.38886C10.6428 6.25993 10.8177 6.1875 11 6.1875C11.1823 6.1875 11.3572 6.25993 11.4861 6.38886C11.6151 6.5178 11.6875 6.69266 11.6875 6.875V11.6875C11.6875 11.8698 11.6151 12.0447 11.4861 12.1736C11.3572 12.3026 11.1823 12.375 11 12.375C10.8177 12.375 10.6428 12.3026 10.5139 12.1736C10.3849 12.0447 10.3125 11.8698 10.3125 11.6875V6.875ZM11 15.8125C10.796 15.8125 10.5967 15.752 10.4271 15.6387C10.2575 15.5254 10.1253 15.3643 10.0473 15.1759C9.9692 14.9875 9.94878 14.7801 9.98857 14.5801C10.0284 14.38 10.1266 14.1963 10.2708 14.052C10.415 13.9078 10.5988 13.8096 10.7988 13.7698C10.9989 13.73 11.2062 13.7504 11.3946 13.8285C11.5831 13.9066 11.7441 14.0387 11.8575 14.2083C11.9708 14.3779 12.0313 14.5773 12.0313 14.7812C12.0313 15.0548 11.9226 15.3171 11.7292 15.5105C11.5358 15.7038 11.2735 15.8125 11 15.8125Z"
                //     fill="#DD6054"
                //   />
                // </svg>
                <p className="form-error">Invalid code</p>
              )}
            </div> */}
            {/* <div className="flex items-center justify-end w-full mt-4 gap-4">
              <button
                className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] md:py-3 md:px-[50px] px-[16px] py-[8px] rounded-[10px] w-[310px] h-[48px] text-center flex justify-center items-center"
                onClick={() => {
                  // if (!hasRetainerSigned) {
                  //   handlePrepareRetainer();
                  // } else {
                  createLegalReviewRequest();
                  window.analytics.track("Get Free Legal Consultation");
                  // }
                }}
                id="raise-legal-review-button"
              >
                {
                  getRetainerStatus === "loading" || requestLegalReviewStatus === "loading" ? (
                    <Spinner size={16} className="animate-spin text-black" />
                  ) : (
                    //  !hasRetainerSigned ? (
                    //   "Review Retainer Agreement"
                    // ) : (
                    "Get Free Legal Consultation"
                  )
                  // )
                }
              </button>
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
});

export default ChatHeader;
