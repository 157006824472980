import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { Spinner } from "@phosphor-icons/react";
import { useDispatch, useSelector } from 'react-redux';
import toast from "react-hot-toast";
import { generateSharedId } from '../../../../pages/chatpage/threadsSlice';


const notify = () => toast.success("Copied shared conversation URL to clipboard!");


const ShareThreadModal = (props) => {

    const {
        open,
        setActionOnThread,
        loading,
        setLoading,
        thread,
        mode
    } = props;

    const BASE_URL = process.env.REACT_APP_URL;

    const dispatch = useDispatch();

    const shareThreadStatus = useSelector((state) => state.threads.shareThreadStatus);


    const handleCopy = (url) => {
        navigator.clipboard
            .writeText(url)
            .then(() => {
                // setShareLinkModalOpen(false);
                notify();
            })
            .catch((err) => console.error("Error copying text: ", err));
    };

    const getSharedId = (threadId) => {
        dispatch(generateSharedId({ threadId: threadId, mode: mode.toLowerCase() }));
    };

    useEffect(() => {
        if (shareThreadStatus === "success") {
            setActionOnThread("");
            setLoading(false);
        } else if (shareThreadStatus === "failed") {
            setLoading(false);
        }
    }, [shareThreadStatus]);


    return (
        <Modal
            centered
            open={open}
            onOk={() => setActionOnThread("")}
            onCancel={() => setActionOnThread("")}
            className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
            footer={null}
        >
            <div className="w-full md:w-[600px] max-w-[800px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
                <span className="text-black text-[30px] font-[800] leading-[1]">Share Chat</span>
                {/* {sharedId ? (
            <span className="text-black text-[16px] font-[500] leading-[1]">The thread is already shared.</span>
          ) : ( */}
                <span className="text-black text-[16px] font-[500] leading-[1]">
                    Are you sure you want to share the thread <span className="font-extrabold">{thread.title}</span>?
                </span>
                {/* )} */}

                {thread?.shared_id ? (
                    <div className="flex items-center justify-start w-full gap-4 h-full ">
                        <button
                            className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-[12px] px-[20px] rounded-[10px] w-[200px] flex items-center justify-center"
                            onClick={() => {
                                setActionOnThread("");
                                handleCopy(`${BASE_URL}/shared/${thread.id}`);
                            }}
                            disabled={loading}
                            id="copy-url-button"
                        >
                            Copy
                        </button>
                    </div>
                ) : (
                    <div className="flex flex-col md:flex-row items-center justify-start w-full gap-4 h-full ">
                        <button
                            className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-[12px] px-[20px] rounded-[10px] w-full md:w-[230px] flex items-center justify-center "
                            onClick={() => {
                                setLoading(true);
                                getSharedId(thread.id);
                                handleCopy(`${BASE_URL}/shared/${thread.id}`);
                            }}
                            disabled={loading}
                            id="share-chat-button"
                        >
                            {loading ? <Spinner size={16} className="animate-spin" /> : "Generate and Copy Link"}
                        </button>
                        <button
                            className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                            onClick={() => setActionOnThread("")}
                            disabled={loading}
                            id="cancel-share-chat-button"
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default ShareThreadModal