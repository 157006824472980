import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { auth } from "../../firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { verifyEmail, getUserDetails } from "./AuthenticationSlice";
import SignUpLayout from "../../components/SignUpLayout/SignUpLayout";
import "./Signin.css";
import { Spinner } from "@phosphor-icons/react";

const SetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [notice, setNotice] = useState("");
    const [showLoading, setShowLoading] = useState(false);

    // const accessToken = useSelector((state) => state.auth.accessToken);
    // const refreshToken = useSelector((state) => state.auth.refreshToken);
    // const authError = useSelector((state) => state.auth.error);
    // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const user = useSelector((state) => state.auth.user);
    const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);
    // const disabled = useSelector((state) => state.auth.status === "loading");
    // const confirmThePasswordResetError = useSelector((state) => state.auth.confirmThePasswordResetError);
    // const confirmThePasswordResetStatus = useSelector((state) => state.auth.confirmThePasswordResetStatus);

    useEffect(() => {
        window.analytics.page("Verify Email Page Loaded");
    }, []);
    useEffect(() => {
        dispatch(getUserDetails())
    }, [window.location])

    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const actionCode = urlParams.get('oobCode');
    const continueUrl = urlParams.get('continue_url')
    var email = urlParams.get('email');
    if (email && email.indexOf(" ") > -1) {
        email = email.replace(/\s/g, "+");
    }

    if (mode === 'verifyEmail' && actionCode) {
        dispatch(verifyEmail({ "oobCode": actionCode, "continue_url": continueUrl, 'email': email }));
    }
    useEffect(() => {
        if (getUserDetailsStatus === "success") {
            if (user) {
                if (user?.user_email) {
                    navigate("/update-profile#/update-profile");
                }
            }
        }
    }, [getUserDetailsStatus])
    return (
        <SignUpLayout>
            <div className="container">
                <div className="row justify-content-center mt-5">
                    <div className="sign-up-form-container text-center flex justify-center items-center">
                        <h1 className="h3 pb-5 mb-5">Verifying your email address</h1>
                        <div class="rotating-cursor mt-5"></div>
                    </div>
                </div>
            </div>
        </SignUpLayout >
    );
};

export default SetPassword;