import React, { useEffect, useState } from "react";
import { Dropdown, Space, Modal } from "antd";
import { Spinner } from "@phosphor-icons/react";
import {
  deleteTemplate,
  deleteTemplateLocal,
  editTemplateLocal,
  renameTemplate,
  listTemplates,
  updateTemplateTitleLocal,
} from "../../pages/chatpage/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useValidatePageAccess from "../../hooks/useValidatePageAccess.js";

const getItems = (isInternalTemplate) => {
  const items = [
    {
      label: "Rename Template",
      // icon: <ShareNetwork size={10} color="#626262" className="font-[700]" />,
      key: "1",
    },
    {
      label: "Delete Template",
      // icon: <Trash size={10} color="#626262" className="font-[700]" />,
      key: "2",
    },
  ]

  if (isInternalTemplate) {
    return [
      {
        label: "View chat",
        // icon: <PencilSimple size={10} color="#626262" className="font-[700]" />,
        key: "0",
      },
      ...items,
    ]
  }

  return items;
}

const Template = ({
  info,
  setTemplatesList,
  templatesList,
  selectedTemplate,
  setselectedTemplate,
  setNewTitle,
  newTitle,
  isInternalTemplate
}) => {
  const currentDate = new Date(info?.creation_date);

  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    // hour: "numeric",
    // minute: "numeric",
    // hour12: true,
    // timeZoneName: "short",
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const formattedDate = currentDate.toLocaleString("en-US", options);
  // const [newTitle, setNewTitle] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateTitleModalOpen, setUpdateTitleModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const templateDeleteStatus = useSelector((state) => state.chat.templateDeleteStatus);
  const templateDeleteError = useSelector((state) => state.chat.templateDeleteError);

  const templateRenameStatus = useSelector((state) => state.chat.templateRenameStatus);
  const templateRenameError = useSelector((state) => state.chat.templateRenameError);

  const templateList = useSelector((state) => state.chat.templateList);

  const templateSaveStatus = useSelector((state) => state.chat.templateSaveStatus);
  const templateSaveError = useSelector((state) => state.chat.templateSaveError);
  const templateSaved = useSelector((state) => state.chat.templateSaved);

  useValidatePageAccess();

  const updateTemplateName = () => {
    dispatch(
      renameTemplate({
        template_id: info.id,
        name: newTitle,
        template_type: info.status ? "external" : "internal",
      })
    );
  };

  const templateDelete = () => {
    dispatch(deleteTemplate({ template_id: info.id, template_type: info.status ? "external" : "internal" }));
  };

  useEffect(() => {
    if (templateRenameStatus === "success") {
      setNewTitle("");
      setUpdateTitleModalOpen(false);
    }
  }, [templateRenameStatus]);

  useEffect(() => {
    if (templateDeleteStatus === "success") {
      setDeleteModalOpen(false);
      dispatch(listTemplates());
    }
  }, [templateDeleteStatus]);

  return (
    <div className="w-full p-[16px] rounded-[4px] border-[1px] border-black flex flex-col gap-[20px]">
      <div className="w-full flex justify-between items-center">
        <span className="">{info.name}</span>
        {!info.deleted && (
          <Dropdown
            onOpenChange={(open) => {
              setDropdownOpen(open);
            }}
            open={dropdownOpen}
            dropdownRender={(menu) => (
              <div className="thread-action-dropdown bg-white w-[150px] p-[10px] border border-[#F3F3F3] rounded-[2px]">
                <div className="flex flex-col justify-center items-start gap-[10px]">
                  {getItems(isInternalTemplate).map((item) => (
                    <div
                      className="flex items-center text-black text-[12px] font-[400] gap-2 hover:cursor-pointer"
                      onClick={() => {
                        if (item.label === "Rename Template") {
                          // updateThreadtoEdit(thread.id);
                          setNewTitle(info.name);
                          setDropdownOpen(false);
                          setUpdateTitleModalOpen(true);
                        } else if (item.label === "Delete Template") {
                          // deleteGivenThread(thread.id);
                          setDropdownOpen(false);
                          setDeleteModalOpen(true);
                        } else {
                          setDropdownOpen(false);
                          navigate(`/ask/${info.thread_id}`);
                        }
                      }}
                      id="thread-action-dropdown-item"
                    >
                      <div className="flex flex-shrink-0">{item.icon}</div>
                      {item.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
            trigger={["click"]}
            className={` cursor-pointer`}
            onClick={() => {
              setselectedTemplate({ ...info });
            }}
          >
            <Space className="">
              <div className="w-6 h-6 rounded-full  flex justify-center items-center gap-[2px]">
                <div className="w-1 h-1 rounded-full bg-[#D9D9D9]" />
                <div className="w-1 h-1 rounded-full bg-[#D9D9D9]" />
                <div className="w-1 h-1 rounded-full bg-[#D9D9D9]" />
              </div>
            </Space>
          </Dropdown>
        )}
      </div>
      <div className="w-full flex justify-between items-center">
        <div className="flex gap-[16px] items-center">
          <p className="text-[#8C8C8C] text-[12px] font-[400]">{formattedDate}</p>
          {info.status && (
            <div className="text-black bg-[#F7F7F7] rounded-[42px] h-[28px] flex justify-center items-center text-[12px] font-[400] px-[10px]">
              {info.status}
            </div>
          )}
        </div>
        {!info.deleted && (
          <div className="flex gap-[8px]">
            <button
              className={`h-[35px] rounded-[8px] p-[12px] ${info.status === 'Processing' ? 'bg-[#D3D3D3]' : 'bg-[#5E59FF]'} flex justify-center items-center text-white`}
              onClick={() => {
                dispatch(editTemplateLocal({ template: info }));
                navigate("/ask");
              }}
              disabled={info.status === 'Processing'}
            >
              Edit with Inhouse AI
            </button>

            <a href={info.google_doc_url} target="_blank" rel="noopener noreferrer">
              <button className={`h-[35px] rounded-[8px] p-[12px] ${info.status === 'Processing' ? 'bg-[#D3D3D3]' : 'bg-[#4484EB]'} flex justify-center items-center text-white`}
                disabled={info.status === 'Processing'}>
                View in Google Docs
              </button>
            </a>
          </div>
        )}
      </div>
      <Modal
        centered
        open={updateTitleModalOpen}
        onOk={() => setUpdateTitleModalOpen(false)}
        onCancel={() => setUpdateTitleModalOpen(false)}
        className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
        footer={null}
      >
        <div className="w-full md:w-[690px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
          <span className="text-black text-[30px] font-[800] leading-[1]">Rename Template</span>

          <div className="flex flex-col items-start gap-3 w-full">
            {/* <span className="text-black text-[16px] font-[500] leading-[1]">Add New Template Name</span> */}
            <textarea
              id="update-title"
              name="updateTitle"
              value={newTitle}
              onChange={(e) => {
                setNewTitle(e.target.value);
              }}
              // rows={4}
              style={{ resize: "none" }}
              className="px-6 py-4 bg-[#EAEAEA] w-full rounded-[13px] text-[12px] font-[500] text-black h-[84px]  placeholder:text-black overflow-auto scrollbar-thin scrollbar-thumb-gray-500"
              placeholder="Template Name"
            ></textarea>
          </div>
          <div className="flex items-center justify-start w-full gap-4 h-full ">
            <button
              disabled={templateRenameStatus === "loading" || !newTitle}
              className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-3 px-[12px] md:px-[50px] rounded-[10px] disabled:bg-[#6d70ff] w-[210px] flex justify-center items-center"
              onClick={() => {
                setLoading(true);
                updateTemplateName();
              }}
              id="update-title-button"
            >
              {templateRenameStatus === "loading" ? (
                <Spinner size={16} className="animate-spin" color="black" />
              ) : (
                "Update Name"
              )}
            </button>
            <button
              className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
              onClick={() => setUpdateTitleModalOpen(false)}
              disabled={templateRenameStatus === "loading"}
              id="cancel-update-title-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={deleteModalOpen}
        onOk={() => setDeleteModalOpen(false)}
        onCancel={() => setDeleteModalOpen(false)}
        className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
        footer={null}
      >
        <div className="w-full md:w-[600px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
          <span className="text-black text-[30px] font-[800] leading-[1]">Delete Template?</span>

          <span className="text-black text-[16px] font-[500] leading-[1]">
            This will delete <span className="font-extrabold">{info.name}</span>
          </span>

          <div className="flex items-center justify-start w-full gap-4 h-full ">
            <button
              className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-[12px] px-[50px] rounded-[10px] w-[150px] flex items-center justify-center "
              onClick={() => {
                setLoading(true);
                templateDelete();
              }}
              disabled={templateDeleteStatus === "loading"}
              id="delete-chat-button"
            >
              {templateDeleteStatus === "loading" ? <Spinner size={16} className="animate-spin" /> : "Delete"}
            </button>
            <button
              className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
              onClick={() => setDeleteModalOpen(false)}
              disabled={templateDeleteStatus === "loading"}
              id="cancel-delete-chat-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div >
  );
};

export default Template;
