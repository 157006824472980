import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { getUserDetails } from "./AuthenticationSlice";
import SignUpLayout from "../../components/SignUpLayout/SignUpLayout";
import "./Signin.css";

const RequestAccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.auth.accessToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const user = useSelector((state) => state.auth.user);

  const location = useLocation();

  const data = location?.state?.data;
  console.log(data);

  useEffect(() => {
    if (isAuthenticated) {
      // localStorage.setItem("accessToken", accessToken);
      // localStorage.setItem("refreshToken", refreshToken);
      dispatch(getUserDetails());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      var userType = localStorage.getItem("userType");
      if (userType === "lawyer") {
        navigate("/lawyer");
      } else if (userType === "client") {
        if (user.first_name) {
          navigate("/");
        } else {
          window.location.href = "/update-profile#/update-profile";
        }
      }
    }
  }, [user]);

  const submitForm = (e) => {
    window.location.href = "/update-profile#/update-profile";
  };

  return (
    <SignUpLayout>
      <h2 className="sign-up-layout-title">Create Your Account</h2>

      <div className="container">
        <div className="row justify-content-center">
          <div className="sign-up-form-container">
            <form className="sign-up-form">
              <div className="form-component">
                <span className="form-title">No account was found with that email</span>
                <span className="form-description">
                  Unfortunately, you do not have access to the platform yet. To apply for our Pilot, please click below.
                </span>
              </div>

              <button type="button" className="form-submit" onClick={(e) => submitForm()} id="request-access-button">
                REQUEST ACCESS TO OUR BETA
              </button>
            </form>
            <div className="form-footer">
              <Link to="/signup" id="navigation-singup" >Think this was an error? Try logging in with a different email. </Link>
            </div>
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default RequestAccess;
