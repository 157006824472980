import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Spinner } from "@phosphor-icons/react";
import { useSelector } from 'react-redux';

const UpdateThreadTitleModal = (props) => {
    const {
        open,
        setActionOnThread,
        loading,
        setLoading,
        updateThreadTitle,
        thread,
    } = props;

    const [newTitle, setNewTitle] = useState("");

    const updateTitleStatus = useSelector((state) => state.chat.updateTitleStatus);

    useEffect(() => {
        setNewTitle(thread.title);
    }, [thread.title, open])


    useEffect(() => {
        if (updateTitleStatus === "success") {
            setNewTitle("");
            setActionOnThread("");
            setLoading(false);
        }
    }, [updateTitleStatus]);

    return (
        <Modal
            centered
            open={open}
            onOk={() => setActionOnThread("")}
            onCancel={() => setActionOnThread("")}
            className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
            footer={null}
        >
            <div className="w-full md:w-[690px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
                <span className="text-black text-[30px] font-[800] leading-[1]">Update Chat Title</span>

                <div className="flex flex-col items-start gap-3 w-full">
                    <span className="text-black text-[16px] font-[500] leading-[1]">Add New Chat Title</span>
                    <textarea
                        id="update-title"
                        name="updateTitle"
                        value={newTitle}
                        onChange={(e) => {
                            setNewTitle(e.target.value);
                        }}
                        // rows={4}
                        style={{ resize: "none" }}
                        className="px-6 py-4 bg-[#EAEAEA] w-full rounded-[13px] text-[12px] font-[500] text-black h-[84px]  placeholder:text-black overflow-auto scrollbar-thin scrollbar-thumb-gray-500"
                        placeholder="Add New Chat Title"
                    ></textarea>
                </div>
                <div className="flex items-center justify-start w-full gap-4 h-full ">
                    <button
                        disabled={loading || !newTitle}
                        className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-3 px-[12px] md:px-[50px] rounded-[10px] disabled:bg-[#6d70ff] w-[195px] flex justify-center items-center"
                        onClick={() => {
                            setLoading(true);
                            updateThreadTitle(thread.id, newTitle);
                        }}
                        id="update-title-button"
                    >
                        {loading ? <Spinner size={16} className="animate-spin" color="black" /> : "Update Title"}
                    </button>
                    <button
                        className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                        onClick={() => setActionOnThread("")}
                        disabled={loading}
                        id="cancel-update-title-button"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default UpdateThreadTitleModal