import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setShowSubscriptionModal,
  setRetainerModalOpen,
} from "../UserAuthentication/AuthenticationSlice";

import TemplatePreview, { TemplateReferencePreview } from "./TemplatePreview/index.js";
import SendMessagesService from "../../components/chat/Services";
import {
  sendMessage,
  enableNewChat,
  disableNewChat,
  setThreadId,
  getThread,
  resetChatPage,
  updateViewedChats,
  resetChangesAccepted,
  sendFeedback,
  sendFeedbackWithMessage,
  // updateTitle,
  addFeedbackToMessage,
  convertDocument,
  updateMessagingDisabled,
  updateNewThreadCreated,
  clearEditTemplateLocal,
  setMessagingSentAt,
  getAdditionalMessages,
  createNewMessage,
  scrollContainerToBottom,
  setLastConversationalMessage,
  updateUserMessageCount,
  updateViewChatLinks,
  updateSaveAsTemplateChats,
  updateLegalReviewRequestChats
} from "./chatSlice";
import { useNavigate } from "react-router-dom";
import { getPortalSession } from "../UserAuthentication/AuthenticationSlice";

import SideNav from "./SideNav.js";
import ChatLayout from "../../components/chat/ChatLayout";
import {
  Export,
  List,
  NotePencil,
  // PaperPlaneTilt,
  Spinner,
  Paperclip,
  X,
} from "@phosphor-icons/react";

import ArrowCircleUpColored from "../../images/ArrowCircleUpColored.svg";

// import Thread from "../../components/chat/Thread";
import Chat from "../../components/chat/Chat";
// import previous from "../../images/previous.svg";

import {
  // getAllThreadsV2,
  getTitle,
  // removeDeletedThreadFromList,
  // updateThreadTitleLocal,
  addUnreadAskThread,
  addUnreadDraftThread,
  deleteUnreadAskThread,
  deleteUnreadDraftThread,
  addNewThreadLocal,
  generateSharedId,
  resetThreadStates,
  markThreadRead,
} from "./threadsSlice";

import TextArea from "antd/es/input/TextArea";
import ImageUploader from "../../components/chat/ImageUploader";
import ChatLoading from "../../components/chat/LoadingChat";
import { useFavicon } from "../../components/chat/useFavicon";
import toast, { Toaster } from "react-hot-toast";
import SubscriptionModal from "../../components/chat/Subscription.js";
import RetainerModal from "../../components/chat/RetainerModals.js"
import SubscriptionStatusRefreshModal from "./Modals/SubscriptionStatusRefreshModal/index.js";
import ShareLinkModal from "./Modals/ShareLinkModal/index.js";
// import Threads from "./Threads/index.js";
import FilePreview from "./FilePreview/index.js";
import TextInput from "./TextInput/index.js";
import useValidatePageAccess from "../../hooks/useValidatePageAccess.js";

import { useUser } from "@clerk/clerk-react"
import applicationStore from "../../persistence/stores/RootStore.js"

const notify = () => toast.success("Copied shared conversation URL to clipboard!");


function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    name + "=" + (value || "") + expires + "; path=/;";
}


function getCookie(name) {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    if (cookie[0] === name) {
      return decodeURIComponent(cookie[1]);
    }
  }
  return null; // Return null if cookie with given name is not found
}

function deleteCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}


const ChatComponentDraft = forwardRef((props, ref) => {

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [extractedMessage, setExtractedMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [action, setAction] = useState("");
  const [messageId, setMessageId] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  const [copiedItem, setCopiedItem] = useState("");
  const [feedback, setFeedback] = useState("");
  // const [legalReviewDisabled, setLegalReviewDisabled] = useState(false);
  // const [legalReviewAvailable, setLegalReviewAvailable] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [mode, setMode] = useState("Ask");
  const [imageUploader, setImageUploader] = useState(false);
  // const [showOnboardingForm, setShowOnboardingForm] = useState(false);
  const [notificationCount, setNotificationCount] = useFavicon("");
  const [previousCount, setPreviousCount] = useState(0);
  const [title] = useState("InHouse");
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sharedId, setSharedId] = useState("");
  const [selectedThread, setSelectedThread] = useState(null);
  const [isNavVisible, setIsNavVisible] = useState(false);
  // const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  // const [showSubscriptionStatusRefreshModal, setShowSubscriptionStatusRefreshModal] = useState(false);
  // const [retainermodalOpen, setRetainerModalOpen] = useState(false);
  const [userAction, setUserAction] = useState("new-thread");
  const [threadList, setThreadList] = useState([]);
  const [messageReceiverName, setMessageReceiverName] = useState("InHouse AI")
  const [startDate, setStartDate] = useState(new Date('2024-07-24T23:59:00'))
  const [threadsCreatedAfterDate, setThreadsCreatedAfterDate] = useState([]);
  const [starterCookieMessage, setStarterCookieMessage] = useState(null);
  const [intervalId, setIntervalId] = useState([])

  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  // const [objects, setObjects] = useState([]);
  // const [unsub, setUnsub] = useState(null);

  // const [actionOnThread, setActionOnThread] = useState(""); // actions that can be done on a thread i.e Rename, Delete, Share
  // const [updateThreadInfo, setUpdateThreadInfo] = useState({}); // thread which we are updating
  // const [updateActionLoading, setUpdateActionLoading] = useState(false);

  const showSubscriptionModal = useSelector((state) => state.auth.showSubscriptionModal);
  const showSubscriptionStatusRefreshModal = useSelector((state) => state.auth.showSubscriptionStatusRefreshModal);
  const retainermodalOpen = useSelector((state) => state.auth.retainermodalOpen);

  const sendMessageStatus = useSelector((state) => state.chat.sendMessageStatus);
  const messages = useSelector((state) => state.chat.messages);
  // const threads = useSelector((state) => state.chat.threads);
  const threadId = useSelector((state) => state.chat.threadId);
  // const getAllThreadError = useSelector((state) => state.chat.getAllThreadError);
  const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
  // const getThreadError = useSelector((state) => state.chat.getThreadError);
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userImageMapping = useSelector((state) => state.auth.userImageMapping);
  const inhouse_user = useSelector((state) => state.auth.user);
  // const userType = useSelector((state) => state.auth.userType);
  // const signinStatus = useSelector((state) => state.auth.signupStatus);
  // const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);
  const lawyerDetails = useSelector((state) => state.auth.lawyerInfo);
  const messagingDisabled = useSelector((state) => state.chat.messagingDisabled);
  const sendMessageError = useSelector((state) => state.chat.sendMessageError);
  // const notUpdatedDraftThreads = useSelector((state) => state.threads.notUpdatedDraftThreads);
  // const viewedChats = useSelector((state) => state.chat.viewedChats);

  // const infoMissing = useSelector((state) => state.auth.infoMissing);
  // const groupedthreads = useSelector((state) => state.chat.threadsNewStructure);
  const askThreads_status = useSelector((state) => state.threads.askThreads_status);
  // const draftThreads_status = useSelector((state) => state.threads.draftThreads_status);

  const legalReviewRequested = useSelector((state) => state.chat.legalReviewRequested);
  const requestLegalReviewStatus = useSelector((state) => state.chat.requestLegalReviewStatus);
  const feedbackStatus = useSelector((state) => state.chat.feedbackStatus);

  const askThreadsList = useSelector((state) => state.threads.askThreadsList);
  const draftThreadsList = useSelector((state) => state.threads.draftThreadsList);
  const newThreadCreated = useSelector((state) => state.chat.newThreadCreated);
  const unreadAskThreads = useSelector((state) => state.threads.unreadAskThreads);
  const unreadDraftThreads = useSelector((state) => state.threads.unreadDraftThreads);
  const shareThreadStatus = useSelector((state) => state.threads.shareThreadStatus);
  const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);

  const textExtractionStatus = useSelector((state) => state.chat.textExtractionStatus);
  const textExtractionTrimmed = useSelector((state) => state.chat.textExtractionTrimmed);
  const extractedText = useSelector((state) => state.chat.extractedText);
  const lastMessageId = useSelector((state) => state.chat.lastMessageId);


  const editTemplate = useSelector((state) => state.chat.editTemplate);
  // const failedDueTo418 = useSelector((state) => state.auth.failedDueTo418);
  const subscriptionType = useSelector((state) => state.auth.subscriptionType);
  const subscriptionStatusFetch = useSelector((state) => state.auth.subscriptionStatusFetch);
  const userMessageCount = useSelector((state) => state.chat.userMessageCount);
  const messageSentAt = useSelector((state) => state.chat.messageSentAt);
  const messageSentForThread = useSelector((state) => state.chat.messageSentForThread);
  const additionalMessagesFetchedStatus = useSelector((state) => state.chat.additionalMessagesFetchedStatus);
  const notUpdatedThreads = useSelector((state) => state.chat.notUpdatedThreads);

  const BASE_URL = process.env.REACT_APP_URL;

  const messageListRef = useRef(null);
  // const showForm = JSON.parse(localStorage.getItem("showForm"));

  // const showTutorial = localStorage.getItem("showTutorial");

  let { chatId } = useParams();
  // const sub_id = queryParams.get('sub_id');
  // const pagestate = queryParams.get('state');
  // const invoice_id = queryParams.get('invoice_id');

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const starterMessage = localStorage.getItem("starterMessage");
  const storedChatId = localStorage.getItem("storedChatId");
  // const messageType = localStorage.getItem("messageType");


  const { isLoaded, isSignedIn, user } = useUser();

  useEffect(() => {
    if (isSignedIn) {
      var isCountryCodeAmerica = ((Intl.DateTimeFormat().resolvedOptions().timeZone) && Intl.DateTimeFormat().resolvedOptions().timeZone.includes('America'));
      if (isCountryCodeAmerica) {
        window.analytics.track("start_hotjar_recording", {
          "user_email": inhouse_user?.user_email,
          "user_id": inhouse_user?.user_id,
          "event": "start tracking on hotjar"
        })
      }
    }
  }, [isSignedIn, inhouse_user?.user_email]);

  useEffect(() => {
    // debugger;
    if (chatId === "null") {
      window.location.href = "/ask"
    }
    if (chatId && threadList.length > 0) {
      const temp = threadList.find((thread) => thread.id === chatId);
      setSelectedThread(temp);
    }
  }, [threadList, chatId]);

  useEffect(() => {
    if (storedChatId) {
      navigate(`/ask/${storedChatId}`);
    }
  }, [storedChatId]);


  useEffect(() => {
    setThreadList([...askThreadsList]);
  }, [askThreadsList, askThreads_status]); // eslint-disable-line

  useEffect(() => {
    if (starterMessage) {
      setMessage(starterMessage);
    }
  }, []);


  useEffect(() => {
    if (message && starterMessage) {
      // handleSend({ messageInput: starterMessage });
      localStorage.removeItem("starterMessage");
      localStorage.removeItem("messageType");
    }
  }, [message]);



  // unread-threads management part 1
  useEffect(() => {
    setIsNavVisible(false);
    if (!starterCookieMessage) {
      var starter_message = getCookie("starter-message");
      if (starter_message) {
        localStorage.setItem("starterMessage", starter_message);
        // setMessage(starter_message);
      }
      else {
        setMessage("");
      }
    }

    // if (!starterCookieMessage) {
    //   setMessage("");
    // }
    if (chatId) {
      dispatch(clearEditTemplateLocal());
      if (!newThreadCreated) {
        setMessageList([]);
      }
      // dispatch(updateNewThreadCreated(false));
      if (location.pathname.indexOf("ask") !== -1) {
        if (unreadAskThreads.indexOf(chatId) !== -1) {
          dispatch(deleteUnreadAskThread(chatId));
          dispatch(deleteUnreadDraftThread(chatId));
        }
      }
      if (location.pathname.indexOf("draft") !== -1) {
        if (unreadDraftThreads.indexOf(chatId) !== -1) {
          dispatch(deleteUnreadAskThread(chatId));
          dispatch(deleteUnreadDraftThread(chatId));
        }
      }
    }
  }, [chatId, location.pathname]); // eslint-disable-line


  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(convertDocument(formData));

      setImageUploader(false);
    }
  }, [file]);

  useEffect(() => {
    if (textExtractionTrimmed) {
      toast.error(`The document you uploaded was very large. Only first ${textExtractionTrimmed} words of documents will be used.`)
    }
  }, [textExtractionTrimmed]);

  useEffect(() => {
    if (file && extractedText && textExtractionStatus === "success") {
      setExtractedMessage(extractedText);
      setFilePreview({
        name: file?.name,
        text: extractedText,
      });
    }
  }, [extractedText, textExtractionStatus, file]);


  useEffect(() => {
    // set mode based on the location.
    // get thread details if the user clicks on any particular thread
    // unread-thread management part 2
    dispatch(resetChatPage({ newThreadCreated: newThreadCreated }));
    setFile(null);
    setFilePreview(null);
    setExtractedMessage("");
    dispatch(resetThreadStates());
    if (location.pathname.indexOf("/ask") > -1) {
      setMode("Ask");
      setThreadList(askThreadsList);
    } else if (location.pathname.indexOf("/draft") > -1) {
      setMode("Draft");
      setThreadList(draftThreadsList);
    } else {
    }
    if (location.pathname !== "/ask" && location.pathname !== "/draft") {
      // if (!newThreadCreated) {
      dispatch(getThread(chatId));
      // }
      dispatch(updateViewedChats(chatId));
    }
  }, [location.pathname]); // eslint-disable-line

  useEffect(() => {
    var startDate = new Date('2024-07-24T23:59:00');
    var count = 0;
    threadList.map((thread) => {
      if (new Date(thread.created_at) > startDate) {
        count += 1
      }
    });
    setThreadsCreatedAfterDate(count);
  }, [threadList]);



  // useEffect(() => {
  //   if (newThreadCreated === true) {
  //     window.analytics.track('New Thread created', {
  //       title: 'New thread',
  //       url: window.location.href,
  //       path: window.location.pathname || window.location.path,
  //       thread_id: chatId
  //     });
  //     if (threadList.length === 0) {
  //       window.analytics.track('First Message Sent', {
  //         title: 'First Message Sent',
  //         url: `${window.location.origin}/${mode.toLocaleLowerCase()}/${threadId}`,
  //         path: window.location.pathname || window.location.path,
  //         thread_id: chatId
  //       });
  //     }

  //     dispatch(
  //       createNewMessage({
  //         user: user,
  //         message_type: "conversation",
  //         text: message,
  //         thread_id: threadId,
  //         created_at: new Date(),
  //         feedback_type: null,
  //         sequence_number: 1,
  //         attachment_names: filePreview?.name ? [filePreview?.name] : null,
  //       })
  //     );
  //     navigate(`/${mode.toLocaleLowerCase()}/${threadId}`);
  //     let title = message.substring(0, 50);
  //     if (!title) {
  //       title = "New Thread";
  //     }
  //     setMessage("");
  //     var data = {
  //       id: threadId,
  //       title: title,
  //       title_source: null,
  //       created_at: new Date(),
  //       user_id: user.user_id,
  //       legal_review_requested: null,
  //       legal_review_submitted: null,
  //       messaging_disabled: false,
  //       legal_review_viewed: null,
  //       shared_id: "",
  //       attachment_names: filePreview?.name ? [filePreview?.name] : null,
  //     };
  //     dispatch(addNewThreadLocal({ mode: mode.toLowerCase(), thread: threadId, data: data }));
  //   }
  // }, [newThreadCreated, threadId]); // eslint-disable-line

  function setActionName(actionName, message_id) {
    setAction(actionName);
    setMessageId(message_id);
    toggleOpen();
    setFeedback("");
    dispatch(sendFeedback({ feedback_type: actionName, message_id: message_id, feedback: "" }));
    dispatch(addFeedbackToMessage({ message_id: message_id, action: actionName }));
  }
  const updateFeedback = () => {
    dispatch(sendFeedbackWithMessage({ feedback_type: action, message_id: messageId, feedback: feedback }));
  };

  useEffect(() => {
    if (legalReviewRequested) {
      if (lawyerDetails?.user_id) {
        setMessageReceiverName(lawyerDetails?.first_name)
      }
      else {
        setMessageReceiverName("Lawyer")
      }
    }
    else {
      setMessageReceiverName("InHouse AI")
    }
  }, [legalReviewRequested]);

  useEffect(() => {
    if (requestLegalReviewStatus === "success" || requestLegalReviewStatus === "failed") {
      if (requestLegalReviewStatus === "success") {
        window.analytics.track("Legal Review Requested Successfully", { user_email: inhouse_user?.user_email });
        if (!lawyerDetails?.user_id) {
          setMessageReceiverName("Lawyer")
        }
        else {
          setMessageReceiverName(lawyerDetails?.first_name)
        }
      }
      // setEmbedLink("");
      dispatch(setRetainerModalOpen(false))
    }
  }, [requestLegalReviewStatus]);

  const updateMessage = (messageText) => {
    setMessage(messageText);
  };

  function setCopiedItemFunction(message_id) {
    setCopiedItem(message_id);
    setTimeout(function () {
      setCopiedItem("");
    }, 5000);
  }

  if (chatId === undefined) {
    chatId = null;
    dispatch(enableNewChat());
  } else {
    dispatch(disableNewChat());
    dispatch(setThreadId(chatId));
  }

  useEffect(() => {

    var count = 0;
    threadList.map((thread) => {
      if (new Date(thread.created_at) > startDate) {
        count += 1
      }
    });
    setThreadsCreatedAfterDate(count);
  }, [threadList]);

  const keepStatusUpdated = () => {
    const latest_messageSentAt = applicationStore.getState().chat.messageSentAt; // Get the latest Redux state
    const latest_messagingDisabled = applicationStore.getState().chat.messagingDisabled;
    const latest_additionalMessagesFetchedStatus = applicationStore.getState().chat.additionalMessagesFetchedStatus;
    const latest_messageSentForThread = applicationStore.getState().chat.messageSentForThread;

    if (latest_messageSentAt) {
      var messageSentAtDate = new Date(latest_messageSentAt);
      var currentDate = new Date();
      var diff = currentDate - messageSentAtDate;

      if (diff > 5000) {
        if (chatId === latest_messageSentForThread && `/ask/${latest_messageSentForThread}` === location.pathname && latest_messagingDisabled && latest_additionalMessagesFetchedStatus !== "loading") {
          dispatch(getAdditionalMessages({ chatId: chatId, messageId: lastMessageId }));
        }
      }
    }
  }

  useEffect(() => {
    if (messageSentAt && intervalId.length === 0) {
      var interval = (setInterval(() => {
        keepStatusUpdated();
      }, 3000));
      var intervals = intervalId;
      intervals.push(interval)
      setIntervalId(intervals)
    }
  }, [inhouse_user?.user_email, messageSentAt]); // eslint-disable-line

  useEffect(() => {
    if (intervalId.length > 0 && !messageSentAt) {
      intervalId.forEach(element => {
        clearInterval(element);
      });
      setIntervalId([]);
    }
  }, [inhouse_user?.user_email, messageSentAt]); // eslint-disable-line

  useEffect(() => {
    if (notUpdatedThreads.length > 0) {
      notUpdatedThreads.map((thread) => {
        dispatch(getTitle(thread));
      });
    }
  }, [notUpdatedThreads]);

  const handleSend = ({ messageInput }) => {
    // debugger;
    // check if subscription type is free-plan. if yes, then check if the user has one thread active.
    // if yes, then show the subscription modal
    if (!chatId) {
      // if the user is creating a new thread, then check if the user has any existing threads
      // only subscribed users can create more than one threads
      if (["free", null, "", "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired"].indexOf(subscriptionType) !== -1 && !chatId) {
        var threadsCount = threadsCreatedAfterDate;

        // if (threadsCount >= 1) {
        setUserAction("new-thread");
        dispatch(setShowSubscriptionModal(true))
        setCookie("starter-message", messageInput, 2);
        window.analytics.track("subscription popup triggered", { "action": "new thread", "chatId": "new thread", user_email: inhouse_user?.user_email });
        return;
        // }
      }
    }
    else {
      // if the user is sending message in an existing thread, then check if the user has any existing threads
      // only subscribed users can create more than one threads
      if (["free", null, "", "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired"].indexOf(subscriptionType) !== -1 && chatId) {
        // var threadsCount = threadsCreatedAfterDate;
        // if (threadsCount > 1 && new Date(selectedThread.created_at) > startDate) {
        setUserAction("new-thread");
        dispatch(setShowSubscriptionModal(true))
        window.analytics.track("subscription popup triggered", { "action": "new message in existing thread", "chatId": chatId, user_email: inhouse_user?.user_email });
        return;
        // }
      }
    }
    dispatch(resetChangesAccepted());
    const creation_time = new Date().toISOString();
    scrollContainerToBottom(messageListRef);
    if (chatId && (location.pathname !== "/ask" || location.pathname !== "/draft")) {
      dispatch(
        sendMessage({
          message_text: messageInput,
          thread_id: threadId,
          mode: mode.toLowerCase(),
          creation_time,
          message_type: "conversation",
          attachment: filePreview ? filePreview : null,
        })
      );
      // console.log(userMessageCount + 1)
      window.analytics.track("New message in a thread", {
        message_text: messageInput,
        thread_id: threadId,
        user_message_count: userMessageCount + 1,
        user_email: inhouse_user?.user_email
      });
    } else {
      if (editTemplate) {
        dispatch(
          sendMessage({
            message_text: messageInput,
            attachment: filePreview ? filePreview : null,
            template_id: editTemplate ? editTemplate.id : null,
          })
        );
      } else {
        dispatch(
          sendMessage({
            message_text: messageInput,
            thread_id: null,
            mode: mode.toLowerCase(),
            creation_time,
            message_type: "conversation",
            attachment: filePreview ? filePreview : null,
          })
        );
      }
    }
    deleteCookie("starter-message");
  };

  useValidatePageAccess();

  function setActionName(actionName, message_id) {
    setAction(actionName);
    setMessageId(message_id);
    toggleOpen();
    setFeedback("");
    dispatch(sendFeedback({ feedback_type: actionName, message_id: message_id, feedback: "" }));
    dispatch(addFeedbackToMessage({ message_id: message_id, action: actionName }));
  }
  const toggleOpen = () => setBasicModal(!basicModal);
  const toggleOpen2 = () => setBasicModal2(!basicModal2);


  useEffect(() => {
    if (unreadAskThreads.length > 0 || unreadDraftThreads.length > 0) {
      setNotificationCount(unreadAskThreads.length + unreadDraftThreads.length);
    } else {
      setNotificationCount(0);
    }
  }, [unreadAskThreads, unreadDraftThreads]);

  useEffect(() => {
    let intervalId;

    if (notificationCount > previousCount) {
      let flipTitle = true;
      intervalId = setInterval(() => {
        document.title = flipTitle ? `(${notificationCount}) ${title}` : "New Message";
        flipTitle = !flipTitle; // Toggle between the two titles
      }, 1000); // Change the title every second

      // Stop the interval after 10 changes (5 cycles of 2 seconds)
      setTimeout(() => {
        clearInterval(intervalId);
        // Reset to original title
        document.title = `(${notificationCount > 0 && notificationCount}) ${title}`;
      }, 1000 * 10); // 10 seconds
    } else {
      document.title = notificationCount > 0 ? `(${notificationCount}) ${title}` : title;
    }

    // Update the previous count after any change in notificationCount
    setPreviousCount(notificationCount);

    // Cleanup interval on component unmount or when count changes
    return () => {
      clearInterval(intervalId);
    };
  }, [notificationCount, title]);

  const handleCopy = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        // setShareLinkModalOpen(false);
        notify();
        dispatch(setRetainerModalOpen(false))
      })
      .catch((err) => console.error("Error copying text: ", err));
  };

  useEffect(() => {
    setSharedId(selectedThread?.shared_id);
  }, [selectedThread?.shared_id]);

  useEffect(() => {
    if (shareThreadStatus === "success") {
      setLoading(false);
      setShareLinkModalOpen(false);
      // handleCopy(`${BASE_URL}/shared/${shareThreadId}`);
      dispatch(resetThreadStates());
    } else if (shareThreadStatus === "failed") {
      setLoading(false);
    }
  }, [shareThreadStatus]);

  const getSharedId = (chatId) => {
    dispatch(generateSharedId({ threadId: chatId, mode: mode.toLowerCase() }));
  };

  const scrollContainerToBottom = (messageListRef) => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };
  SendMessagesService({ toggleOpen: toggleOpen, image: user?.imageUrl })

  useEffect(() => {
    // Scroll to the bottom when messageList or sendMessageStatus changes
    scrollContainerToBottom(messageListRef);
  }, [messages, sendMessageStatus]);

  useEffect(() => {
    if (sendMessageStatus === "success") {
      setMessage("");
      setExtractedMessage("");
      setFile(null);
      setFilePreview(null);
    }
  }, [sendMessageStatus]);

  return (
    <>
      {isLoaded && getUserDetailsStatus === "success" ? (
        <ChatLayout>
          <Toaster />



          <section className="chat-body h-full w-full flex ">
            <div className="relative flex fex-row  w-full h-full">
              <button
                className="md:hidden block hamburger-button absolute left-0 top-4 z-40"
                onClick={() => setIsNavVisible(!isNavVisible)}
              >
                <List size={32} onClick={() => setIsNavVisible(!isNavVisible)} />
              </button>
              {isNavVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-30" onClick={() => setIsNavVisible(false)}></div>
              )}
              <SideNav mode={mode} chatId={chatId}
                newThreadCreated={newThreadCreated}
                subscriptionType={subscriptionType}
              />

              <div className="relative h-full overflow-y-scroll  w-full flex flex-col justify-between items-center md:px-0 px-2">
                {/* 4 cases:
              1. chat id is none
                dynamic messages
              2. No messages in theead and resources are being fetched: messages.length == 0 and status = "loading"
                loading
              3. No messages in the thread event after the resources are fetched: messages.length == 0 and status = "success"
                no messages to show
              4. Messages are present in the thread: messages.length > 0 and status = "success"
                show messages
            */}

                {/* Share Popup Code Start */}
                {chatId && threadList.length > 0 && (
                  <div
                    className="absolute border rounded-lg border-gray-300 top-4 right-4 p-2 hover:cursor-pointer bg-white"
                    onClick={() => {
                      setShareLinkModalOpen(true);
                    }}
                  >
                    <Export size={20} />
                  </div>
                )}

                <ShareLinkModal
                  open={shareLinkModalOpen}
                  sharedId={sharedId}
                  setShareLinkModalOpen={setShareLinkModalOpen}
                  handleCopy={handleCopy}
                  chatId={chatId}
                  loading={loading}
                  setLoading={setLoading}
                  getSharedId={getSharedId}
                  copyText={"Copy"}
                  selectedThread={selectedThread}
                />

                {/* Share Popup Code End */}

                <>


                  {getThreadStatus === "loading" && !editTemplate ? (
                    // case 2
                    <div className="h-full w-full max-w-[913px] m-auto pt-[50px] overflow-y-scroll">
                      {/* {messageList.length === 0 ? ( */}
                      <div className="w-full h-full flex justify-center items-center">
                        {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                        <div class="rotating-cursor"></div>
                      </div>
                      {/* ) : null} */}
                    </div>
                  ) : (
                    <div
                      ref={messageListRef}
                      className="h-full w-full max-w-[913px] m-auto pt-[50px] overflow-y-scroll"
                    >
                      {/* case 3 */}
                      {messages.length === 0 &&
                        !editTemplate &&
                        ((sendMessageStatus === "success" || sendMessageStatus === "idle") && getThreadStatus !== "loading") ? (
                        <p>No messages to show</p>
                      ) : null}

                      {messages.length === 0 && sendMessageStatus === "loading" ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <div class="rotating-cursor"></div>
                        </div>
                      ) : null}

                      {selectedThread?.reference_template && (
                        <div className="flex items-center justify-end pr-[40px]">
                          <TemplateReferencePreview file={selectedThread?.reference_template} />
                        </div>
                      )}

                      {/* case 4 */}
                      <>
                        {messages.length > 0 &&
                          !editTemplate &&
                          messages.map((message, index) => (
                            <Chat
                              isLastReply={index === messages.length - 1}
                              isLastAiReply={
                                index === messages.length - 1 ||
                                (index === messages.length - 2 && message.user === null)
                              }
                              // showHyperLinkButton={viewChatLinks[viewChatLinks.length - 1] === message.id}
                              // showTemplateButton={saveAsTemplateChats[saveAsTemplateChats.length - 1] === message.id}
                              // showLegalReviewRequestButton={legalReviewRequestedChats[legalReviewRequestedChats.length - 1] === message.id}
                              key={message.id}
                              isAIReply={message.user == null ? true : false}
                              imageUrl={
                                message.user == null
                                  ? userImageMapping["lexxa"]
                                  : userImageMapping[message.user?.user_id]
                              }
                              chatInfo={message}
                              setLike={() => {
                                setActionName("like", message.id);
                              }}
                              setDisLike={() => {
                                setActionName("dislike", message.id);
                              }}
                              clickCopy={() => {
                                navigator.clipboard.writeText(message.text);
                                setCopiedItemFunction(message.id);
                              }}
                              viewerMode="client"
                              chatId={chatId}
                              handleSend={handleSend}
                              selectedThread={selectedThread}
                              legalReviewRequested={legalReviewRequested}
                              subscriptionType={subscriptionType}
                              userAction={userAction}
                              setUserAction={setUserAction}
                            />
                          ))}
                        {messagingDisabled && !legalReviewRequested && (
                          <ChatLoading imageUrl={userImageMapping["lexxa"]} mode={mode} />
                        )}
                      </>

                    </div>
                  )}
                  {editTemplate && (
                    <div className="p-[15px] w-full max-w-[913px] rounded-[5px] bg-[#EEF] border-[1px] border-[#5E59FF] text-black text-[16px] font-[700] mb-[12px]">
                      By editing this document, you are creating a new version. It will not reflect changes in the
                      template.
                    </div>
                  )}
                  <div className=" w-full flex flex-col items-center justify-center">
                    {sendMessageStatus === "failed" ? <p class="text-danger">{sendMessageError}</p> : null}
                    {((!chatId && sendMessageStatus === "loading") || chatId || editTemplate) && (
                      <div className="relative chat-inputs-container mt-2 flex flex-col w-full max-w-[913px] mb-4 overflow-hidden gap-[10px] items-end">
                        {file && textExtractionStatus === "loading" ? (
                          <div className="loading-response w-full h-full flex justify-center items-center">
                            <div class="rotating-cursor mt-6"></div>
                          </div>
                        ) : filePreview ? (
                          <div className="w-full">
                            <FilePreview
                              file={filePreview}
                              onDelete={() => {
                                setFile(null);
                                setFilePreview(null);
                                setExtractedMessage("");
                              }}
                            />
                          </div>
                        ) : null}
                        {editTemplate && (
                          <div className="w-full">
                            <TemplatePreview
                              file={editTemplate}
                              onDelete={() => {
                                dispatch(clearEditTemplateLocal());
                              }}
                            />
                          </div>
                        )}
                        {/* <p>
                          messagingDisabled = {messagingDisabled ? "true" : "false"} <br />
                          .... messageSentAt = {messageSentAt?.toUTCString()} <br />
                          ... newThreadCreated = {newThreadCreated ? "true" : "false"} <br />
                          --- date = {new Date().toUTCString()} <br />
                        </p> */}

                        <TextArea
                          variant="borderless"
                          className="chat-input-text-area flex w-full flex-grow items-center"
                          placeholder={
                            !messagingDisabled ? `Message ${messageReceiverName}` : "This chat is closed"
                          }
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                            setErrorMessage("");
                          }}
                          autoSize={{ minRows: chatId ? 1 : 2, maxRows: chatId ? 8 : 4 }}
                          disabled={
                            sendMessageStatus === "loading" ||
                            textExtractionStatus === "loading" ||
                            messagingDisabled ||
                            getThreadStatus === "loading"
                          }
                          onKeyDown={(e) => {
                            if (
                              e.key === "Enter" &&
                              (e.ctrlKey || e.metaKey) &&
                              !e.shiftKey &&
                              (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)
                            ) {
                              handleSend({ messageInput: message });
                            }
                          }}
                        />
                        {/* )} */}

                        <div
                          className={`flex items-center  ${(!editTemplate && !legalReviewRequested) ? "justify-between" : "justify-end"} w-full`}
                        >
                          {!editTemplate && !legalReviewRequested && (
                            <button
                              className="btn  btn-sm outline-[0px] border-[0px] group"
                              onClick={() => {
                                setImageUploader(true);
                              }}
                              disabled={
                                sendMessageStatus === "loading" ||
                                textExtractionStatus === "loading" ||
                                messagingDisabled
                              }
                              id="send-message-button"
                            >
                              {/* existing  chat  screen */}
                              <Paperclip size={24} className="group-hover:text-[#5E59FF]" />
                            </button>
                          )}
                          <button
                            className="btn  btn-sm outline-[0px] border-[0px] group"
                            onClick={() => {
                              handleSend({ messageInput: message });
                            }}
                            disabled={
                              sendMessageStatus === "loading" ||
                              textExtractionStatus === "loading" ||
                              messagingDisabled ||
                              message === ""
                            }
                            id="send-message-button"
                          >
                            {/* <ArrowCircleUp size={24} className="group-hover:text-[#5E59FF]" /> */}
                            <img height={24} width={24} src={ArrowCircleUpColored} alt="send message" />

                          </button>
                        </div>

                      </div>
                    )}
                    {/* <p className="right-1 text-[12px]">Press ctrl/cmd + enter</p> */}
                    {/* {!newThreadCreated && !chatId && (
                  <div className="w-full flex flex-col justify-center items-center gap-[70px] h-fit">
                    <ChatHome updateMessage={updateMessage} handleSend={handleSend} />
                  </div>
                )} */}
                    <div className="pt-3 w-full">

                      <div className="w-full py-2 justify-center items-center bg-[#F7F7F7]">
                        <p className="text-center text-[#757575] text-[12px] font-[500]">
                          Always seek your attorney’s review, as AI can make mistakes. Only chats with the attorney are privileged. For details, see our
                          <a href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Privacy%20Policy.pdf" target="blank" className="underline"> Privacy Policy.</a>
                        </p>
                      </div >
                    </div >
                  </div >

                </>
              </div >
            </div >
          </section >

          <ImageUploader
            setImageUploader={setImageUploader}
            imageUploader={imageUploader}
            setFile={setFile}
            file={filePreview}
            textExtractionStatus={textExtractionStatus}
          />

          <SubscriptionModal
            showSubscriptionModal={showSubscriptionModal}
            user={user}
            userAction={userAction}
          />

          <RetainerModal
            retainermodalOpen={retainermodalOpen}
            chatId={chatId}
            subscriptionType={subscriptionType}
            getSharedId={getSharedId}
            handleCopy={handleCopy}
          />

          <SubscriptionStatusRefreshModal open={showSubscriptionStatusRefreshModal}
            user={user}
          />

        </ChatLayout >
      ) : <>
        {
          (isLoaded && getUserDetailsStatus === "failed") ?
            <div className="w-full h-full flex justify-center items-center">
              <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
            </div> :
            <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]">
              {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
              <div class="rotating-cursor !h-[50px]"></div>
            </div>
        }
      </>}
    </>
  );
});

export default ChatComponentDraft;