import React from 'react';
import { Modal } from "antd";
import {
    Spinner,
} from "@phosphor-icons/react";

const ShareLinkModal = (props) => {
    const {
        open,
        sharedId,
        setShareLinkModalOpen,
        handleCopy,
        chatId,
        loading,
        setLoading,
        getSharedId,
        copyText,
        selectedThread
    } = props;

    const BASE_URL = process.env.REACT_APP_URL;

    return (
        <Modal
            centered
            open={open}
            onOk={() => setShareLinkModalOpen(false)}
            onCancel={() => setShareLinkModalOpen(false)}
            className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
            footer={null}
        >
            <div className="w-full md:w-[600px] max-w-[800px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
                <span className="text-black text-[30px] font-[800] leading-[1]">Share Chat</span>

                <span className="text-black text-[16px] font-[500] leading-normal">
                    Are you sure you want to share the thread <span className="font-extrabold">{selectedThread?.title}</span>?
                </span>

                {sharedId ? (
                    <div className="flex items-center justify-start w-full gap-4 h-full ">
                        <button
                            className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-[12px] px-[20px] rounded-[10px] w-[200px] flex items-center justify-center"
                            onClick={() => {
                                setShareLinkModalOpen(false);
                                handleCopy(`${BASE_URL}/shared/${chatId}`);
                            }}
                            disabled={loading}
                            id="copy-url-button"
                        >
                            {copyText}
                        </button>
                    </div>
                ) : (
                    <div className="flex flex-col md:flex-row items-center justify-start w-full gap-4 h-full ">
                        <button
                            className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-[12px] px-[20px] rounded-[10px] w-full md:w-[230px] flex items-center justify-center "
                            onClick={() => {
                                setLoading(true);
                                getSharedId(chatId);
                                handleCopy(`${BASE_URL}/shared/${chatId}`);
                            }}
                            disabled={loading}
                            id="share-chat-button"
                        >
                            {loading ? <Spinner size={16} className="animate-spin" /> : "Generate and Copy Link"}
                        </button>
                        <button
                            className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                            onClick={() => setShareLinkModalOpen(false)}
                            disabled={loading}
                            id="cancel-share-chat-button"
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default ShareLinkModal