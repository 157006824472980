import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TypeAnimation } from "react-type-animation";

const ChatLoading = ({ imageUrl, intent }) => {
  const user = JSON.parse(window.localStorage.getItem("user"));

  const askLoadingText = [
    `considering the laws applicable to a ${user?.state} ${user?.entity_type || ""}`,
    3000,
    "Conducting legal research",
    3000,
    `Personalizing for a business engaged in ${user?.business_purpose}`,
    9000,
  ];

  const preparingQuestions = [
    "Analyzing your request", 3000,
    "Reviewing account info on " + user?.organization_name, 3000,
    "Preparing response", 50000];

  const identifyingTemplate = ["searching through our knowledge base for relevant templates", 3000, ""];

  const askingQuestions = ["Analyzing your response", 3000,
    "Conducting legal research", 50000];

  const makingDraft = [
    "Analyzing your response",
    3000,
    "Conducting legal research",
    3000,
    "Drafting outline",
    3000,
    "Drafting your document",
    50000,
  ];

  const draftComplete = [
    "Analyzing your response", 3000,
    "Conducting legal research", 3000,
    "Updating your document", 30000,
  ]
  const [loadingTexts, setLoadingTexts] = useState(null);
  // const loadingtexts = mode.toLocaleLowerCase() === "ask" ? askLoadingText : draftLoadingText;
  const intent_state = useSelector((state) => state.chat.intent_state);

  useEffect(() => {
    switch ("preparing_questions") {
      case "ask":
        setLoadingTexts(askLoadingText);
        break;
      case "identifying_template":
        setLoadingTexts(identifyingTemplate);
        break;
      case "preparing_questions":
        setLoadingTexts(preparingQuestions);
        break;
      case "asking_questions":
        setLoadingTexts(askingQuestions);
        break;
      case "making_draft":
        setLoadingTexts(makingDraft);
        break;
      case "draft_complete":
        setLoadingTexts(draftComplete);
        break;
      default:
        setLoadingTexts(askLoadingText);
        break;
    }
  }, [intent_state]);

  return (
    <div className={`flex items-center transition duration-300 group hover:cursor-pointer mb-5`}>
      <div className="mr-3 flex-shrink-0">
        <img src={imageUrl} alt="User" width={45} height={45} className="rounded-full" />
      </div>
      {loadingTexts && (
        <div className="h-full flex items-center w-full text-center">
          <p
            className="text-[#626262] text-[14px] font-[700] text-center leading-[1]"
            style={{ whiteSpace: "pre-line" }}
          >
            <TypeAnimation
              //   preRenderFirstString={true}
              sequence={[...loadingTexts]}
              speed={50}
              cursor={false}
              className="cursor"
              repeat={Infinity}
            //   omitDeletionAnimation={true}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default ChatLoading;
