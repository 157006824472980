import React from "react";
import { X } from "@phosphor-icons/react";


const TemplatePreview = ({ file, onDelete }) => {
    if (file === null) return;
    return (
        <div className="w-fit relative group">
            <a
                className="file-preview flex items-center justify-between pl-[10px] pr-[20px] py-[15px] rounded-[4px] mb-2 bg-[#ffff]  w-fit min-w-[350px] border-[#ACACAC] border"
                href={file.google_doc_url}
                target="_blank"
            >
                <div className="flex items-center gap-[12px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="32" viewBox="0 0 31 32" fill="none">
                        <rect x="0.5" y="0.5" width="30" height="31" rx="2.5" stroke="black" />
                        <path
                            d="M21 7.5C21.2761 7.5 21.5 7.27614 21.5 7C21.5 6.72386 21.2761 6.5 21 6.5V7.5ZM6 7.5H21V6.5H6V7.5Z"
                            fill="white"
                        />
                        <path
                            d="M19 11.5C19.2761 11.5 19.5 11.2761 19.5 11C19.5 10.7239 19.2761 10.5 19 10.5V11.5ZM6 11.5H19V10.5H6V11.5Z"
                            fill="black"
                        />
                        <path
                            d="M12 15.5C12.2761 15.5 12.5 15.2761 12.5 15C12.5 14.7239 12.2761 14.5 12 14.5V15.5ZM6 15.5H12V14.5H6V15.5Z"
                            fill="black"
                        />
                    </svg>
                    <span className="file-name mr-2">{file?.name}</span>
                    {/* <span className="file-type text-gray-600">({file.type})</span> */}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path
                        d="M5.5 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V11.5C1 11.8978 1.15804 12.2794 1.43934 12.5607C1.72064 12.842 2.10218 13 2.5 13H11.5C11.8978 13 12.2794 12.842 12.5607 12.5607C12.842 12.2794 13 11.8978 13 11.5V8.5M7 7L13 1M13 1V4.75M13 1H9.25"
                        stroke="#CACACA"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </a>
            <X
                size={20}
                className="cursor-pointer hidden group-hover:flex text-gray-600 group-hover:text-red-600 absolute -top-2 -right-2 bg-white rounded-full border border-black border-solid z-10"
                onClick={onDelete}
            />
        </div>
    );
};


export const TemplateReferencePreview = ({ file, onDelete }) => {
    if (file === null) return;
    return (
        <a
            className="file-preview flex items-center justify-between pl-[10px] pr-[20px] py-[15px] rounded-[4px] mb-2 group bg-[#ffff] relative w-fit min-w-[350px] border-[#ACACAC] border"
            href={file.google_doc_url}
            target="_blank"
        >
            <div className="flex items-center gap-[12px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="32" viewBox="0 0 31 32" fill="none">
                    <rect x="0.5" y="0.5" width="30" height="31" rx="2.5" stroke="black" />
                    <path
                        d="M21 7.5C21.2761 7.5 21.5 7.27614 21.5 7C21.5 6.72386 21.2761 6.5 21 6.5V7.5ZM6 7.5H21V6.5H6V7.5Z"
                        fill="white"
                    />
                    <path
                        d="M19 11.5C19.2761 11.5 19.5 11.2761 19.5 11C19.5 10.7239 19.2761 10.5 19 10.5V11.5ZM6 11.5H19V10.5H6V11.5Z"
                        fill="black"
                    />
                    <path
                        d="M12 15.5C12.2761 15.5 12.5 15.2761 12.5 15C12.5 14.7239 12.2761 14.5 12 14.5V15.5ZM6 15.5H12V14.5H6V15.5Z"
                        fill="black"
                    />
                </svg>
                <span className="file-name mr-2">{file?.name}</span>
                {/* <span className="file-type text-gray-600">({file.type})</span> */}
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path
                    d="M5.5 1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V11.5C1 11.8978 1.15804 12.2794 1.43934 12.5607C1.72064 12.842 2.10218 13 2.5 13H11.5C11.8978 13 12.2794 12.842 12.5607 12.5607C12.842 12.2794 13 11.8978 13 11.5V8.5M7 7L13 1M13 1V4.75M13 1H9.25"
                    stroke="#CACACA"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </a>
    );
};


export default TemplatePreview;
