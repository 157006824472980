import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { auth } from "../../firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { confirmThePasswordReset, getUserDetails } from "./AuthenticationSlice";
import SignUpLayout from "../../components/SignUpLayout/SignUpLayout";
import "./Signin.css";
import { Spinner } from "@phosphor-icons/react";

const SetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notice, setNotice] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const accessToken = useSelector((state) => state.auth.accessToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const authError = useSelector((state) => state.auth.error);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const disabled = useSelector((state) => state.auth.status === "loading");
  const confirmThePasswordResetError = useSelector((state) => state.auth.confirmThePasswordResetError);
  const confirmThePasswordResetStatus = useSelector((state) => state.auth.confirmThePasswordResetStatus);

  useEffect(() => {
    window.analytics.page("Set password page viewed");
  }, []);

  window.location.href = "https://inhouse.so/auth";
  useEffect(() => {
    if (confirmThePasswordResetError) {
      setNotice(confirmThePasswordResetError);
      setShowLoading(false);
    } else {
      setNotice("");
    }
  }, [confirmThePasswordResetError]);

  useEffect(() => {
    if (isAuthenticated) {
      // localStorage.setItem("accessToken", accessToken);
      // localStorage.setItem("refreshToken", refreshToken);
      dispatch(getUserDetails());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      var userType = localStorage.getItem("userType");
      if (userType === "lawyer") {
        navigate("/lawyer");
      } else if (userType === "client") {
        // if (user.first_name) {
        navigate("/");
        // } else {
        //   navigate("/update-profile#/update-profile");
        // }
      }
    }
  }, [user]);

  useEffect(() => {
    if (validatePassword(password)) {
      setNotice("");
    }
  }, [password]);

  const showAlert = () => {
    if (password && confirmPassword && password !== confirmPassword) {
      return "Passwords do not match";
    } else {
      return "";
    }
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const resetPassword = async (e) => {
    // e.preventDefault();
    // dispatch(signIn({ email: email, password: password }));
    setShowLoading(true);

    if (!validatePassword(password)) {
      setNotice("Password must be at least 6 characters long.");
      setShowLoading(false);
      return;
    }
    dispatch(
      confirmThePasswordReset({
        oobCode: new URLSearchParams(window.location.search).get("oobCode"),
        newPassword: password,
        confirmPassword: confirmPassword,
        mode: "resetPassword",
      })
    );
  };

  useEffect(() => {
    if (confirmThePasswordResetStatus === "success") {
      setShowLoading(false);
    }
  }, [confirmThePasswordResetStatus]);

  return (
    <SignUpLayout>
      <h2 className="sign-up-layout-title">Set Password</h2>
      <div className="container">
        <div className="row justify-content-center">
          <div className="sign-up-form-container">
            <form className="sign-up-form">
              <div className="form-component">
                <div className="form-label-container">
                  <label htmlFor="password" className="form-label">
                    Please create a new password
                  </label>
                </div>

                <input
                  type="password"
                  className="form-input"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </div>
              <div className="form-component">
                <div className="form-label-container">
                  <label htmlFor="confirmPassword" className="form-label">
                    Re-enter password
                  </label>
                  {/* {notice !== "" && (
                    <div className="form-error" role="alert">
                      {notice}
                    </div>
                  )} */}
                </div>

                <input
                  type="password"
                  className="form-input"
                  id="confirmPassword"
                  placeholder="Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></input>
              </div>
              {notice !== "" && (
                <div className="form-error" role="alert">
                  {notice}
                </div>
              )}
              {confirmThePasswordResetStatus === "success" && (
                <div className="text-primary" role="alert">
                  Password reset successfully. Please login with your new password.
                </div>
              )}
              {showAlert() && (
                <div className="form-error" role="alert">
                  {showAlert()}
                </div>
              )}
              <button
                type="button"
                className="form-submit disabled:opacity-50"
                disabled={
                  confirmThePasswordResetStatus === "loading" ||
                  password === "" ||
                  confirmPassword === "" ||
                  password !== confirmPassword ||
                  showLoading
                }
                onClick={resetPassword}
                id="resetPasswordButton"
              >
                {showLoading ? <Spinner size={16} className="animate-spin text-black" /> : "SAVE PASSWORD"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default SetPassword;
