import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  getAuth,
  signInWithEmailLink,
  applyActionCode,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "../../firebase";
import axios from "axios";

const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const BASE_ENDPOINT = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;

const initialState = {
  isAuthenticated: false,
  user: null,
  userType: null,
  error: null,
  passwordResetStatus: null,
  passwordResetError: null,
  signuperror: null,
  signupStatus: null,
  refreshToken: null,
  accessToken: null,
  infoMissing: false,
  status: "idle",
  firebase_user: null,
  userStatus: "",
  statusGetStatus: "idle",
  signInWithGoogleStatus: "idle",
  requestPilotStatus: "idle",
  requestPilotError: "",
  profileUpdated: "false",
  confirmThePasswordResetStatus: "idle",
  confirmThePasswordResetError: "",
  passwordUpdateStatus: "idle",
  passwordUpdateError: "",
  getUserDetailsStatus: "idle",
  getLawyerInfoStatus: "idle",
  is_lawyer_prospective: true,
  firebase_id: null,
  failedDueTo418: false,
  currentUser: null,
  subscriptionType: "free",
  userImageMapping: {},
  checkoutUrl: null,
  checkoutUrlStatus: "idle",
  portalSessionUrl: null,
  portalSessionUrlStatus: "idle",
  showSubscriptionStatusRefreshModal: false,
  showSubscriptionModal: false,
  showRetainerModal: false,
  image: "",
  imageLawyer: ""
};

const triggerLogout = () => {
  try {
    signOut(auth);
  } catch (error) { }
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
  localStorage.removeItem("userType");
  localStorage.removeItem("client_details");
  localStorage.removeItem("lawyer_info");
  localStorage.removeItem("showForm");
  // window.location.href = "https://www.inhouse.so/";
};
export const attemptAuthenticationRefresh = () => {
  // auth.onAuthStateChanged((user) => {
  //   if (user) {
  //     try {
  //       user
  //         .getIdToken()
  //         .then((token) => {
  //           localStorage.setItem("accessToken", token);
  //           window.location.reload();
  //         })
  //         .catch((firebaseError) => {
  //           triggerLogout();
  //         });
  //     } catch (UserError) {
  //       triggerLogout();
  //     }
  //   } else {
  //     triggerLogout();
  //   }
  // });
};

const actionCodeSettings = {
  url: process.env.REACT_APP_FRONTEND_BASE_ENDPOINT + "/signin?",
  handleCodeInApp: true,
};

console.log(process.env.REACT_APP_FRONTEND_BASE_ENDPOINT);

export const loadDataFromLocalStorage = createAsyncThunk(
  "authentication/loadDataFromLocalStorage",
  async (thunkAPI) => {
    return thunkAPI.rejectWithValue();
  }
);

export const getUserDetails = createAsyncThunk("authentication/getUserDetails", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const url =
    data && data.referredCode
      ? `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/get-info?referral-code=${data.referredCode}`
      : `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/get-info`;
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.get(url, { headers: headers });
    return {
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});



export const getPortalSession = createAsyncThunk("authentication/getPortalSession", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/get-portal-url`;
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const response = await axios.post(url, {}, { headers: headers });
    return {
      response: response.data,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getLawyerInfo = createAsyncThunk("authentication/getLawyerInfo", async (thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/lawyer/get-info`;
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const response = await axios.get(url, { headers: headers });
    return {
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getUserStatus = createAsyncThunk("authentication/getUserStatus", async (data, thunkAPI) => {
  // const accessToken = localStorage.getItem("accessToken2");
  const url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/${data.email}/get-status`;
  // const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const response = await axios.get(url);
    return {
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const signUp = createAsyncThunk("authentication/signUp", async (data, thunkAPI) => {
  // const url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/sign-up`;

  try {
    const response = await createUserWithEmailAndPassword(auth, data.email, data.password);
    // const response = await axios.post(url, data);
    return {
      response: response,
      data: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const signIn = createAsyncThunk("authentication/signIn", async (data, thunkAPI) => {
  try {
    const response = await signInWithEmailAndPassword(auth, data.email, data.password);
    return {
      response: response,
      data: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const signInWithGoogle = createAsyncThunk("authentication/signInWithGoogle", async (data, thunkAPI) => {
  // debugger;
  if (data === "signup") {
    window.analytics.track("new signup with Google initiated", {});
  }
  try {
    const provider = new GoogleAuthProvider();
    const response = await signInWithPopup(auth, provider);
    return {
      "response": response,
      "data": data
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const requestPilotAccessAPI = createAsyncThunk(
  "authentication/requestPilotAccessAPI",
  async (data, thunkAPI) => {
    const url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/${data.email}/submit-pilot-access-request`;
    try {
      const response = await axios.post(url, data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error });
    }
  }
);

export const passwordReset = createAsyncThunk("authentication/passwordReset", async (data, thunkAPI) => {
  // try {
  //   const response = await sendPasswordResetEmail(auth, data.email);
  //   return response;
  // } catch (error) {
  //   return thunkAPI.rejectWithValue({ error: error });
  // }
  const url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/forgot-password`;
  try {
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const confirmThePasswordReset = createAsyncThunk(
  "authentication/confirmThePasswordReset",
  async (data, thunkAPI) => {
    if (!data.oobCode && !data.newPassword) return;
    try {
      const response = await confirmPasswordReset(auth, data.oobCode, data.newPassword);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error });
    }
  }
);

export const confirmThePasswordUpdate = createAsyncThunk(
  "authentication/confirmThePasswordUpdate",
  async (data, thunkAPI) => {
    if (!data.newPassword) return;
    try {
      const response = await updatePassword(auth, data.newPassword);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error });
    }
  }
);

export const handleLogout = createAsyncThunk("authentication/handleLogout", async (data, thunkAPI) => {
  try {
    const response = await signOut(auth);
    console.log(data);
    return {
      "response": response,
      "data": data
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const refreshAuth = createAsyncThunk("authentication/signUp", async (thunkAPI) => {
  // implement refresh token
});

export const forgotPassword = createAsyncThunk("authentication/forgotPassword", async (email, thunkAPI) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { email };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const resetPassword = createAsyncThunk("authentication/resetPassword", async (data, thunkAPI) => {
  const { email, newPassword } = data;
  try {
    await confirmPasswordReset(auth, newPassword);
    return { email };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});
export const refreshSubscriptionStatus = createAsyncThunk("authentication/refreshSubscriptionStatus", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/user/refresh-subscription-plan`;
    const response = await axios.get(url, { headers: headers });
    return {
      response: response.data,
      request: data,
    };
  }
  catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});
export const verifyEmail = createAsyncThunk("authentication/verifyEmail", async (data, thunkAPI) => {
  try {
    await applyActionCode(auth, data.oobCode)
      .then((resp) => {
        signInWithEmailLink(auth, data.email, data.continue_url)
          .then((result) => {
            window.location.href = "/signin?email-verified=true";
            return {
              data: data
            };
          })
          .catch((error) => {
            window.location.href = "/signin?email-verified=true";
          });
        return {
          data: data,
        };
      })
      .catch((error) => {
        console.log("error", error);
        return thunkAPI.rejectWithValue({ error: error });
      });
  } catch (error) {
    console.error("Error verifying email: ", error);
    return thunkAPI.rejectWithValue({ error: error });
  }
});



export const getCheckoutUrl = createAsyncThunk("authentication/getCheckoutUrl", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/get-checkout-url`;
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const response = await axios.post(url, {}, { headers: headers });
    return {
      response: response.data,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    resetAuthError: (state) => {
      state.error = {};
      state.status = "idle";
      state.signuperror = {};
    },
    updateSubscriptionStatus: (state, action) => {
      state.subscriptionType = action.payload;
      state.subscriptionStatusFetch = "success";
    },
    setUserImageMapping: (state, action) => {
      state.userImageMapping = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload
    },
    setImageLawyer: (state, action) => {
      state.imageLawyer = action.payload
    },
    resetProfileUpdatedStatus: (state) => {
      state.profileUpdated = "false";
    },
    updateUserState: (state, action) => {
      var user_data = JSON.parse(localStorage.getItem("user"));
      state.user = user_data
      state.profileUpdated = "true";
      state.subscriptionType = user_data?.subscription_type || "free";
    },
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    },
    resetPasswordResetStatus: (state) => {
      state.passwordResetStatus = "idle";
      state.passwordResetError = "";
    },
    setShowSubscriptionModal: (state, action) => {
      state.showSubscriptionModal = action.payload;
    },
    setRetainerModalOpen: (state, action) => {
      state.showRetainerModal = action.payload;
    },
    setShowSubscriptionStatusRefreshModal: (state, action) => {
      state.showSubscriptionStatusRefreshModal = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.fulfilled, (state, action) => {
        state.signupStatus = "success";
        if (!action.payload.response.user.emailVerified) {
          var data = { email: action.payload.data.email };
          var url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/email-verification`;
          axios
            .post(url, data)
            .then((response) => {
              console.log(response);
              signOut(auth);
            })
            .catch((error) => {
              console.log(error);
              sendEmailVerification(action.payload.response.user, action.payload.data.actionCodeSettings);
              signOut(auth);
            });
          state.signupStatus = "failed";
          state.signuperror = { message: "", code: "auth/verify-email" };
          return;
        }
        state.signupStatus = "success";
        state.isAuthenticated = true;
        // state.user = action.payload;
        // state.accessToken = action.payload.user.accessToken;
        // state.refreshToken = action.payload.user.stsTokenManager.refreshToken;
        state.signuperror = null;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.signupStatus = "failed";
        state.user = null;
        if (action.payload?.error?.code === "auth/invalid-credential") {
          state.signuperror = { message: "Invalid email or password", code: "auth/invalid-credential" };
        } else if (action.payload?.error?.code === "auth/email-already-in-use") {
          state.signuperror = { message: "This Email is already in use.", code: "auth/email-already-in-use" };
        } else {
          if (action.payload?.error?.code !== "auth/invalid-email") {
            state.signuperror = { message: "Something went wrong. Please try again later.", code: "others" };
          }
        }
        // state.error = action.payload;
        state.accessToken = null;
        state.refreshToken = null;
      })
      .addCase(signUp.pending, (state, action) => {
        state.signupStatus = "loading";
        state.isAuthenticated = false;
        state.user = null;
        state.signuperror = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        if (!action.payload.response.user.emailVerified) {
          var data = { email: action.payload.data.email };
          var url = `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}/api/v0/user/email-verification`;
          axios
            .post(url, data)
            .then((response) => {
              console.log(response);
              signOut(auth);
            })
            .catch((error) => {
              console.log(error);
              sendEmailVerification(action.payload.response.user, action.payload.data.actionCodeSettings);
              signOut(auth);
            });
          state.signupStatus = "failed";
          state.signuperror = { message: "", code: "auth/verify-email" };
          return;
        }
        state.signupStatus = "success";
        state.firebase_id = action.payload.response.user.uid;
        state.firebase_user = auth.currentUser;
        if (state.firebase_user && state.firebase_user.reloadUserInfo) {
          var customAttributes = state.firebase_user.reloadUserInfo["customAttributes"];
          if (customAttributes && JSON.parse(customAttributes)["user_type"]) {
            var userType = JSON.parse(customAttributes)["user_type"];
            if (userType === "undefined") {
              userType = "client";
            }
          }
        }
        if (!userType || userType === undefined || userType === "undefined") {
          userType = "client";
        }
        state.userType = userType;
        localStorage.setItem("userType", userType);
        state.isAuthenticated = true;
        // state.user = action.payload.response;
        state.accessToken = action.payload.response.user.accessToken;
        state.refreshToken = action.payload.response.user.stsTokenManager.refreshToken;
        state.signuperror = null;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.signupStatus = "failed";
        state.user = null;
        if (
          action.payload?.error?.code === "auth/invalid-credential") {
          state.signuperror = { message: "Invalid email or password", code: "auth/invalid-credential" };
        }
        else if (
          action.payload?.error?.code === "auth/email-not-found" || action.payload?.error?.code === "auth/user-not-found"
        ) {
          state.signuperror = { message: "No account found with that email. Please try again or sign up.", code: "auth/invalid-credential" };
        } else if (
          action.payload?.error?.code === "auth/invalid-password" ||
          action.payload?.error?.code === "auth/wrong-password"
        ) {
          state.signuperror = { message: "Invalid Password. Please try again.", code: "auth/invalid-password" };
        } else if (action.payload?.error?.code === "auth/email-already-in-use") {
          state.signuperror = { message: "This Email is already in use.", code: "auth/email-already-in-use" };
        } else {
          if (action.payload?.error?.code !== "auth/invalid-email") {
            state.signuperror = { message: "Something went wrong. Please try again later.", code: "others" };
          }
        }
        // state.error = action.payload;
        state.accessToken = null;
        state.refreshToken = null;
      })
      .addCase(signIn.pending, (state, action) => {
        state.signupStatus = "loading";
        state.isAuthenticated = false;
        state.user = null;
        state.signuperror = null;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        debugger;
        var data = action.payload.data;
        action = action.payload.response;
        state.firebase_id = action.user.uid;
        state.firebase_user = auth.currentUser;
        if (state.firebase_user && state.firebase_user.reloadUserInfo) {
          var customAttributes = state.firebase_user.reloadUserInfo["customAttributes"];
          if (customAttributes && JSON.parse(customAttributes)["user_type"]) {
            var userType = JSON.parse(customAttributes)["user_type"];
            if (userType === "undefined") {
              userType = "client";
            }
          }
        }
        if (!userType || userType === undefined || userType === "undefined") {
          userType = "client";
        }
        if (data === "signup") {
          debugger
          window.analytics.track("new signup with Google completed", { "user_type": userType, "user_email": state.firebase_user.email });
        }
        state.userType = userType;
        localStorage.setItem("userType", userType);
        state.isAuthenticated = true;
        state.signInWithGoogleStatus = "success";
        state.signupStatus = "success";
        state.signinStatus = "success";
        // state.user = action.payload;
        state.accessToken = action.user.accessToken;
        state.refreshToken = action.user.stsTokenManager.refreshToken;
        state.signuperror = null;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.signupStatus = "failed";
        state.signInWithGoogleStatus = "failed";
        state.signinStatus = "failed";
        state.user = null;
        if (action.payload?.error?.code === "auth/invalid-credential") {
          state.signuperror = { message: "Invalid email or password", code: "auth/invalid-credential" };
        } else if (action.payload?.error?.code === "auth/email-already-in-use") {
          state.signuperror = { message: "This Email is already in use.", code: "auth/email-already-in-use" };
        } else {
          if (action.payload?.error?.code !== "auth/invalid-email") {
            state.signuperror = { message: "Something went wrong. Please try again later.", code: "others" };
          }
        }
        // state.error = action.payload;
        state.accessToken = null;
        state.refreshToken = null;
        state.signInWithGoogleStatus = "failed";
      })
      .addCase(signInWithGoogle.pending, (state, action) => {
        state.signInWithGoogleStatus = "loading";
        debugger;
        state.isAuthenticated = false;
        state.user = null;
        state.signInWithGoogleStatus = "loading";
        state.signuperror = null;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        var user_type = action.payload.response.user_type;
        var client_details = action.payload.response.client_info;
        var lawyer_info = action.payload.response.lawyer_info;
        const showPopUp = localStorage.getItem("showForm");

        var user_details = {};
        if (user_type === "lawyer") {
          user_details = action.payload.response.lawyer_info;
          if (user_details == null) {
            user_details = {
              first_name: "",
              last_name: "",
              user_id: action.payload.response.user_id,
            };
          }
        } else {
          user_details = action.payload.response.client_info;
          if (user_details == null) {
            user_details = {
              first_name: "",
              last_name: "",
              organization_name: "",
              email: "",
              state: "",
              phone_number: "",
              age_of_business: "",
              num_employees: "",
              current_legal_source: "",
              legal_work_frequency: "",
              legal_work_type: "",
              annual_legal_spend: "",
              user_id: action.payload.response.user_id,
              is_default_input: true
            };
            state.subscriptionType = action.payload?.response?.subscription_type || "free";
          }
          state.subscriptionType = action.payload.response.subscription_type;
        }
        user_details["user_id"] = action.payload.response.user_id;
        user_details["profileImage"] = action.payload.response.profile_picture_url;
        user_details["is_retainer_signed"] = action.payload.response.is_retainer_signed;
        user_details["user_email"] = action.payload.response.user_email;
        user_details["referral_code"] = action.payload.response.referral_code;
        user_details["referral_discount_available"] = action.payload.response.referral_discount_available;
        state.user = {
          ...user_details,
          user_email: action.payload.response.user_email,
          profileImage: action.payload.response.profile_picture_url,
          is_retainer_signed: action.payload.response.is_retainer_signed,
        };
        localStorage.setItem("user", JSON.stringify(user_details));
        localStorage.setItem("client_details", JSON.stringify(client_details));
        localStorage.setItem("lawyer_info", JSON.stringify(lawyer_info));
        localStorage.setItem("user_email", action.payload.response.user_email);
        state.client_details = client_details;
        state.lawyerInfo = lawyer_info;
        // state.email = action.payload.response.user_email;
        var is_lawyer_prospective = action.payload.response.is_prospective_lawyer;
        localStorage.setItem("is_lawyer_prospective", is_lawyer_prospective);
        state.is_lawyer_prospective = is_lawyer_prospective;
        state.clientDetails = client_details;
        state.client_info = action.payload.response.client_info;
        if (!action.payload.response.client_info.organization_name && (showPopUp === null || showPopUp === undefined)) {
          localStorage.setItem("showForm", true);
        }
        state.userType = "client";
        // state.is_retainer_signed = action.payload.response.is_retainer_signed;
        state.getUserDetailsStatus = "success";
        state.failedDueTo418 = false;
      })
      .addCase(getUserDetails.rejected, (state, action, error) => {
        if (action?.payload?.error?.request?.status === 418) {
          state.failedDueTo418 = true;
          state.getUserDetailsStatus = "failed";
          return;
        }
        state.failedDueTo418 = false;
        let user_details = {
          first_name: "",
          last_name: "",
          organization_name: "",
          email: "",
          state: "",
          phone_number: "",
          age_of_business: "",
          num_employees: "",
          current_legal_source: "",
          legal_work_frequency: "",
          legal_work_type: "",
          annual_legal_spend: "",
          user_id: "",
          is_default_input: true
        };
        state.firebase_user = null;
        state.clientDetails = null;
        state.user = null;
        state.userType = "client";
        state.getUserDetailsStatus = "failed";
        state.user = user_details;
        console.log("getUserDetails.rejected");
      })
      .addCase(getUserDetails.pending, (state, action) => {
        state.firebase_user = null;
        state.getUserDetailsStatus = "loading";
      })

      .addCase(getLawyerInfo.fulfilled, (state, action) => {
        var user_type = "lawyer";
        var client_details = action.payload.response.client_info;
        var lawyer_info = action.payload.response.lawyer_info;
        var clients = action.payload.response.clients;
        var prospective_client_ids = action.payload.response.prospective_client_ids;

        var user_details = {};
        user_details = action.payload.response.lawyer_info;
        if (user_details == null) {
          user_details = {
            first_name: "",
            last_name: "",
            user_id: action.payload.response.user_id,
          };
        }
        user_details["user_id"] = action.payload.response.user_id;
        state.user = user_details;
        localStorage.setItem("user", JSON.stringify(user_details));
        localStorage.setItem("client_details", JSON.stringify(client_details));
        localStorage.setItem("lawyer_info", JSON.stringify(lawyer_info));
        state.clients = clients;
        state.prospective_client_ids = prospective_client_ids;
        state.lawyerInfo = { ...lawyer_info, profileImage: action.payload.response.profile_picture_url };

        var is_lawyer_prospective = action.payload.response.is_lawyer_prospective;
        localStorage.setItem("is_lawyer_prospective", is_lawyer_prospective);
        state.is_lawyer_prospective = is_lawyer_prospective;
        state.userType = "lawyer";
        state.getLawyerInfoStatus = "success";
      })
      .addCase(getLawyerInfo.rejected, (state, action) => {
        state.firebase_user = null;
        state.getLawyerInfoStatus = "failed";
        state.failedDueTo418 = false;
      })
      .addCase(getLawyerInfo.pending, (state, action) => {
        state.firebase_user = null;
        state.getLawyerInfoStatus = "loading";
      })
      .addCase(loadDataFromLocalStorage.rejected, (state, action) => {
        state.userType = localStorage.getItem("userType");
        state.user = JSON.parse(localStorage.getItem("user"));
        try {
          state.lawyerInfo = JSON.parse(localStorage.getItem("lawyer_info"));
        } catch (e) {
          state.lawyerInfo = null;
        }
        try {
          state.clientDetails = JSON.parse(localStorage.getItem("client_details"));
        } catch (e) {
          state.clientDetails = null;
        }
        try {
          state.is_lawyer_prospective = JSON.parse(localStorage.getItem("is_lawyer_prospective"));
        } catch (e) {
          state.is_lawyer_prospective = true;
        }
        if (
          state.lawyerInfo === undefined ||
          state.lawyerInfo === null ||
          state.clientDetails === undefined ||
          state.clientDetails === null
        ) {
          state.infoMissing = true;
        } else {
          state.infoMissing = false;
        }
        state.firebase_user = null;
      })
      .addCase(handleLogout.fulfilled, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.error = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.status = "idle";
        localStorage.removeItem("accessToken2");
        localStorage.removeItem("refreshToken2");
        localStorage.removeItem("user");
        localStorage.removeItem("userType");
        localStorage.removeItem("client_details");
        localStorage.removeItem("lawyer_info");
        localStorage.removeItem("selectedClientId");
        localStorage.removeItem("selectedClientDetails");
        localStorage.removeItem("showForm");
        localStorage.removeItem("showTutorial");
        // debugger;
        var redirectUrl = action.payload.data;
        if (!redirectUrl) {
          redirectUrl = "/ask"
        }
        window.location = `/signin?redirect=${redirectUrl}`;
      })
      .addCase(getUserStatus.fulfilled, (state, action) => {
        state.userStatus = action.payload.response;
        state.statusGetStatus = "success";
      })
      .addCase(getUserStatus.rejected, (state, action) => {
        state.userStatus = action.payload.error.response.data.detail;
        state.statusGetStatus = "failed";
        // state.error = action.payload?.error;
      })
      .addCase(getUserStatus.pending, (state) => {
        state.statusGetStatus = "loading";
        state.requestPilotStatus = "idle";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        // Handle successful forgot password request
        // store the email for reference
        console.log(action);
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        // Handle forgot password failure
        console.log(action);
      })
      .addCase(confirmThePasswordUpdate.fulfilled, (state, action) => {
        state.passwordUpdateError = "";
        state.passwordUpdateStatus = "success";
      })
      .addCase(confirmThePasswordUpdate.rejected, (state, action) => {
        state.passwordUpdateError = "";
        state.passwordUpdateStatus = "failed";
      })

      .addCase(passwordReset.fulfilled, (state, action) => {
        state.passwordResetError = "";
        state.passwordResetStatus = "success";
      })
      .addCase(passwordReset.rejected, (state, action) => {
        // alert("password reset email rejected")
        if (action?.payload?.error?.response?.data?.detail) {
          state.passwordResetError = action.payload.error.response.data.detail;
        }
        else {
          state.passwordResetError = "Something went wrong. Please try again.";
        }
        state.passwordResetStatus = "failed";
      })
      .addCase(passwordReset.pending, (state, action) => {
        state.passwordResetError = "";
        state.passwordResetStatus = "loading";
      })

      .addCase(confirmThePasswordReset.fulfilled, (state, action) => {
        state.confirmThePasswordResetStatus = "success";
        state.confirmThePasswordResetError = "";
      })
      .addCase(confirmThePasswordReset.rejected, (state, action) => {
        state.confirmThePasswordResetStatus = "failed";
        if (action?.payload?.error?.code === "auth/expired-action-code") {
          state.confirmThePasswordResetError = "The password reset link has expired. Please try again.";
        } else if (action?.payload?.error?.code === "auth/invalid-action-code") {
          state.confirmThePasswordResetError = "The password reset link is invalid. Please try again.";
        } else if (action?.payload?.error?.code === "auth/weak-password") {
          state.confirmThePasswordResetError =
            "Password should be at least 6 characters and a combination of letters and numbers.";
        } else {
          state.confirmThePasswordResetError = "Something went wrong. Please try again later.";
        }
      })
      .addCase(confirmThePasswordReset.pending, (state, action) => {
        state.confirmThePasswordResetStatus = "loading";
        state.confirmThePasswordResetError = "";
      })

      .addCase(requestPilotAccessAPI.fulfilled, (state, action) => {
        state.requestPilotStatus = "success";
        state.requestPilotError = "";
      })
      .addCase(requestPilotAccessAPI.rejected, (state, action) => {
        state.requestPilotStatus = "failed";
        if (action?.payload?.error?.response?.data?.detail) {
          state.requestPilotError = action.payload.error.response.data.detail;
        } else {
          state.requestPilotError = "Something went wrong. Please try again later.";
        }
      })
      .addCase(requestPilotAccessAPI.pending, (state, action) => {
        state.requestPilotStatus = "loading";
        state.requestPilotError = "";
      })

      .addCase(verifyEmail.fulfilled, (state, action) => {
        console.log("email verified");
        state.emailVerified = true;
        state.emailVerificationError = "";
        state.emailVerificationStatus = "success";
        window.location.href = "/signin?email-verified=true";
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.emailVerified = false;
        state.emailVerificationError = action.payload.error;
        state.emailVerificationStatus = "failed";
      })
      .addCase(verifyEmail.pending, (state, action) => {
        state.emailVerified = false;
        state.emailVerificationError = "";
        state.emailVerificationStatus = "loading";
      })
      .addCase(refreshSubscriptionStatus.rejected, (state, action) => {
        state.subscriptionStatusFetch = "failed";
      })
      .addCase(refreshSubscriptionStatus.pending, (state, action) => {
        state.subscriptionStatusFetch = "loading";
        // state.subscriptionType = "";
      })
      .addCase(refreshSubscriptionStatus.fulfilled, (state, action) => {
        state.subscriptionStatusFetch = "success";
        state.subscriptionType = action.payload.response.plan;
      })
      .addCase(getCheckoutUrl.fulfilled, (state, action) => {
        state.checkoutUrl = action.payload.response.url;
        state.checkoutUrlStatus = "success";
      })
      .addCase(getPortalSession.fulfilled, (state, action) => {
        window.location.href = action.payload.response.access_url;
        state.portalSessionUrlStatus = "success";
      })
  },
});

export const { loginSuccess, loginFailure, logout, updateUserState, resetProfileUpdatedStatus, resetAuthError, resetPasswordResetStatus, updateSubscriptionStatus, setUserImageMapping,
  setShowSubscriptionModal,
  setRetainerModalOpen,
  setShowSubscriptionStatusRefreshModal,
  setImage,
  setImageLawyer
} =
  AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
