import { Navigate, useParams } from "react-router-dom";

export function RequireAuth({ children }) {
  let { chatId } = useParams();

  let userToken = localStorage.getItem("accessToken");
  // if (userToken == null) {
  //   if (chatId) localStorage.setItem("storedChatId", chatId);
  //   return <Navigate to={"/signin"} />;
  // }
  // return userToken != null ? children : <Navigate to={"/signin"} />;
  return children;
}
