import React, { useEffect } from "react";
import google_open from "../../images/google-open.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "@phosphor-icons/react";
import VectorUser from "../../images/VectorUser.svg";
import FileGroup from "../../images/FileGroup.svg";

const ActionButton = ({ info, index, onClick, selectedThread }) => {
  const templateSaveStatus = useSelector((state) => state.chat.templateSaveStatus);
  const changesAccepted = useSelector((state) => state.chat.changesAccepted);
  const acceptingEditSuggestionsStatus = useSelector((state) => state.chat.acceptingEditSuggestionsStatus);

  return (
    <>
      <button
        onClick={onClick}
        index={index}
        disabled={(info.type === "save_as_template" && (selectedThread?.template || templateSaveStatus === "success")) ||
          (info.type === "accept_edit_suggestions" && (changesAccepted || acceptingEditSuggestionsStatus === "loading"))
        }
        className={`mb-2 px-[24px] py-[12px] min-w-[215px] h-[42px] rounded-[10px] border hover:cursor-pointer disabled:opacity-100 disabled:cursor-not-allowed ${info.type === "hyperlink"
          ? "bg-[#4484EB] text-white"
          : (info.type === "legal_review_request" || info.type === "accept_edit_suggestions") && "bg-[#5E59FF] text-white"
          } flex items-center ${info.type === "hyperlink" ? "justify-between" : "justify-center"
          } text-[16px] text-[#626262] font-[500] ${(info.type === "legal_review_request" || info.type === "accept_edit_suggestions") && "gap-[8px]"}
        ${info.type === 'save_as_template' ? 'black-border' : ''}`}
      >
        {info.type === "hyperlink" && (
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
            <path
              d="M0.798001 4.6325C1.42201 3.38993 2.37928 2.34538 3.56282 1.6156C4.74637 0.88582 6.10955 0.499562 7.5 0.5C9.52125 0.5 11.2193 1.24325 12.5175 2.45375L10.3673 4.60475C9.5895 3.8615 8.601 3.48275 7.5 3.48275C5.54625 3.48275 3.8925 4.80275 3.30375 6.575C3.15375 7.025 3.06825 7.505 3.06825 8C3.06825 8.495 3.15375 8.975 3.30375 9.425C3.89325 11.198 5.54625 12.5173 7.5 12.5173C8.50875 12.5173 9.3675 12.251 10.0395 11.801C10.4291 11.5445 10.7626 11.2117 11.0199 10.8226C11.2772 10.4336 11.4529 9.99639 11.5365 9.5375H7.5V6.6365H14.5635C14.652 7.127 14.7 7.6385 14.7 8.17025C14.7 10.4548 13.8825 12.3777 12.4635 13.6827C11.223 14.8287 9.525 15.5 7.5 15.5C6.51498 15.5004 5.53953 15.3067 4.62941 14.9299C3.71929 14.5531 2.89234 14.0007 2.19582 13.3042C1.4993 12.6077 0.946872 11.7807 0.570101 10.8706C0.19333 9.96047 -0.000393646 8.98503 6.00536e-07 8C6.00536e-07 6.7895 0.289501 5.645 0.798001 4.6325Z"
              fill="white"
            />
          </svg>
        )}
        {info.type === "save_as_template" && (
          // <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
          //   <path
          //     d="M7.3252 1.25H2.8252C2.2042 1.25 1.7002 1.754 1.7002 2.375V19.625C1.7002 20.246 2.2042 20.75 2.8252 20.75H15.5752C16.1962 20.75 16.7002 20.246 16.7002 19.625V10.625M7.3252 1.25H7.7002C10.0871 1.25 12.3763 2.19821 14.0642 3.88604C15.752 5.57387 16.7002 7.86305 16.7002 10.25V10.625M7.3252 1.25C8.2203 1.25 9.07875 1.60558 9.71168 2.23851C10.3446 2.87145 10.7002 3.72989 10.7002 4.625V6.125C10.7002 6.746 11.2042 7.25 11.8252 7.25H13.3252C14.2203 7.25 15.0787 7.60558 15.7117 8.23851C16.3446 8.87145 16.7002 9.72989 16.7002 10.625M6.2002 14L8.4502 16.25L12.2002 11"
          //     stroke="white"
          //     stroke-width="1.5"
          //     stroke-linecap="round"
          //     stroke-linejoin="round"
          //   />
          // </svg>
          <img src={FileGroup} height={15} width={15} alt="file" className="mr-[5px]" />
        )}
        {info.type === "accept_edit_suggestions" ?
          <> {
            (acceptingEditSuggestionsStatus === "loading") ? (
              <Spinner size={16} className="animate-spin" />
            ) :
              <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.6937 1.75301L6.62003 12.6982C6.52358 12.7939 6.40898 12.8698 6.28279 12.9215C6.1566 12.9733 6.02131 13 5.88467 13C5.74804 13 5.61275 12.9733 5.48656 12.9215C5.36037 12.8698 5.24576 12.7939 5.14931 12.6982L0.304596 7.90968C0.208027 7.81423 0.131424 7.70092 0.0791618 7.57621C0.0268992 7.4515 1.43899e-09 7.31783 0 7.18285C-1.43899e-09 7.04787 0.0268992 6.9142 0.0791618 6.78949C0.131424 6.66478 0.208027 6.55147 0.304596 6.45602C0.401164 6.36057 0.515808 6.28486 0.641981 6.2332C0.768154 6.18155 0.903386 6.15496 1.03995 6.15496C1.17652 6.15496 1.31175 6.18155 1.43793 6.2332C1.5641 6.28486 1.67875 6.36057 1.77531 6.45602L5.88554 10.5186L16.2247 0.301062C16.4197 0.108295 16.6842 0 16.96 0C17.2359 0 17.5004 0.108295 17.6954 0.301062C17.8904 0.493829 18 0.755278 18 1.02789C18 1.30051 17.8904 1.56195 17.6954 1.75472L17.6937 1.75301Z" fill="white" />
              </svg>}</>
          : ""}

        {info.type === "accept_edit_suggestions" && changesAccepted ? ("Changes Accepted") : <>

          {(info.type === "save_as_template" && info.type !== "accept_edit_suggestions") && (templateSaveStatus === "success" || selectedThread?.template) ? (
            "Template Saved"
          ) : info.type === "save_as_template" && templateSaveStatus === "loading" ? (
            <Spinner size={16} className="animate-spin" />
          ) : (
            <>{info.type === "legal_review_request" && (<img src={VectorUser} height={12} width={14} />)} {info.text}  </>
          )}
          {info.type === "hyperlink" && <img src={google_open} height={15} width={15} alt="google" />}
        </>}
      </button>
    </>
  );
};

export default ActionButton;
