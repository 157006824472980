import React, { useState, useEffect } from "react";

export const useFavicon = (initialFaviconUrl) => {
  const [notificationCount, setNotificationCount] = useState(0);

  // useEffect(() => {
  //   const updateFavicon = (count) => {
  //     const faviconElement = document.getElementById("favicon") || document.createElement("link");
  //     faviconElement.id = "favicon";
  //     faviconElement.rel = "icon";
  //     document.head.removeChild(faviconElement);

  //     if (count > 0) {
  //       const img = new Image();
  //       img.src = initialFaviconUrl;
  //       img.onload = () => {
  //         const canvas = document.createElement("canvas");
  //         canvas.width = img.width;
  //         canvas.height = img.height;
  //         const context = canvas.getContext("2d");
  //         context.drawImage(img, 0, 0, img.width, img.height);
  //         context.beginPath();
  //         context.arc(img.width - img.width / 5, img.height / 5, img.width / 5, 0, 2 * Math.PI);
  //         context.fillStyle = "#f00000";
  //         context.fill();
  //         faviconElement.href = canvas.toDataURL("image/png");
  //       };
  //     } else {
  //       faviconElement.href = initialFaviconUrl;
  //     }

  //     document.head.appendChild(faviconElement);
  //   };

  //   // Call updateFavicon function whenever notificationCount changes
  //   updateFavicon(notificationCount);

  // }, [notificationCount, initialFaviconUrl]);

  return [notificationCount, setNotificationCount];
};

export const Notifications = () => {
  const [notificationCount, setNotificationCount] = useFavicon("/images/ih-logo.png");
  const [title] = useState("InHouse");

  useEffect(() => {
    document.title = notificationCount > 0 ? `(${notificationCount}) ${title}` : title;
  }, [notificationCount, title]);

  const addNotification = () => {
    setNotificationCount(notificationCount + 1);
  };

  const clearNotification = () => {
    setNotificationCount(0);
  };

  return (
    <div>
      <button
        style={{
          marginRight: "1em",
          marginBottom: "1em",
        }}
        onClick={addNotification}
      >
        Add New Notification
      </button>
      <button onClick={clearNotification}>Clear Notifications</button>
    </div>
  );
};
