import React from 'react';
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // This enables automatic instrumentation (highly recommended)
    // If you only want to use custom instrumentation:
    // * Remove the BrowserTracing integration
    // * add Sentry.addTracingExtensions() above your Sentry.init() call
    integrations: [
      Sentry.browserTracingIntegration(),
      // Or, if you are using react router, use the appropriate integration
      // See docs for support for different versions of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation: useLocation,
        useNavigationType: useNavigationType,
        createRoutesFromChildren: createRoutesFromChildren,
        matchRoutes: matchRoutes,
      }),
    ],

    // For finer control of sent transactions you can adjust this value, or
    // use tracesSampler
    tracesSampleRate: 1.0,

    // Set tracePropagationTargets to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [
    // 'localhost',
    // /^https://yourserver.io/api/
    // ],
  });
}

export default initSentry;