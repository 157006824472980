import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { auth } from "../../firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { signIn, getUserDetails, passwordReset, getUserStatus, resetPasswordResetStatus } from "./AuthenticationSlice";
import SignUpLayout from "../../components/SignUpLayout/SignUpLayout";
import "./Signin.css";
import { Spinner } from "@phosphor-icons/react";
import e from "cors";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notice, setNotice] = useState("");
  const [success, setSuccess] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const accessToken = useSelector((state) => state.auth.accessToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const authError = useSelector((state) => state.auth.error);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // const userType = useSelector(state => state.auth.userType);
  const user = useSelector((state) => state.auth.user);
  const disabled = useSelector((state) => state.auth.status === "loading");

  const passwordResetStatus = useSelector((state) => state.auth.passwordResetStatus);
  const passwordResetError = useSelector((state) => state.auth.passwordResetError);
  // const userStatus = useSelector((state) => state.auth.userStatus);
  // const statusGetStatus = useSelector((state) => state.auth.statusGetStatus);

  useEffect(() => {
    window.analytics.page("Forgot password page viewed");
    dispatch(resetPasswordResetStatus())
  }, []);

  window.location.href = "https://inhouse.so/auth"

  useEffect(() => {
    if (authError) {
      setNotice(authError.message);
      setShowLoading(false);
    } else {
      setNotice("");
    }
  }, [authError]);

  useEffect(() => {
    if (isAuthenticated) {
      // localStorage.setItem("accessToken", accessToken);
      // localStorage.setItem("refreshToken", refreshToken);
      dispatch(getUserDetails());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      var userType = localStorage.getItem("userType");
      if (userType === "lawyer") {
        navigate("/lawyer");
      } else if (userType === "client") {
        // if (user.first_name) {
        navigate("/");
        // } else {
        //   navigate("/update-profile#/update-profile");
        // }
      }
    }
  }, [user]);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    console.log("validation check", re.test(String(email).toLowerCase()));

    return re.test(String(email).toLowerCase());
  };

  const sendPasswordResetEmail = async (e) => {
    e.preventDefault();
    setShowLoading(true);
    setNotice("");

    if (!validateEmail(email)) {
      setNotice("Please enter a valid email address.");
      setShowLoading(false);
      return;
    }

    dispatch(passwordReset({ email: email }));
    // fecthUserStatus();
  };

  useEffect(() => {
    if (passwordResetStatus === "success") {
      setSuccess(true);
      setShowLoading(false);
    }
    if (passwordResetStatus === "failed") {
      setShowLoading(false);
    }
  }, [passwordResetStatus]);

  // const fecthUserStatus = () => {
  //   dispatch(getUserStatus({ email: email }));
  // };

  // useEffect(() => {
  //   if (statusGetStatus === "success") {
  //     if (userStatus.status !== "not_found" && userStatus.user_id !== null) {
  //       dispatch(passwordReset({ email: email }));
  //     } else if (userStatus.user_id === null && userStatus.status === "not_found") {
  //       setShowLoading(false);
  //       setNotice("Please enter registered email");
  //     }
  //   } else if (statusGetStatus === "failed") {
  //     setShowLoading(false);
  //     setNotice("Something went wrong. Please try again");
  //   }
  // }, [userStatus, statusGetStatus]);

  return (
    <SignUpLayout>
      <h2 className="sign-up-layout-title">Forgot Password</h2>

      <div className="container">
        <div className="row justify-content-center">
          <div className="sign-up-form-container">
            <form className="sign-up-form">
              <div className="form-component">
                <div className="form-label-container">
                  <label htmlFor="inputEmail" className="form-label">
                    Enter your email and we'll send you a sign-in link
                  </label>
                  {notice !== "" && (
                    <div className="form-error" role="alert">
                      {notice}
                    </div>
                  )}
                </div>
                <input
                  type="email"
                  className="form-input"
                  id="inputEmail"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                {passwordResetError && <div className="form-error">{passwordResetError}</div>}

              </div>

              <button
                type="submit"
                className="form-submit disabled:opacity-50"
                disabled={disabled || showLoading || email === ""}
                onClick={(e) => sendPasswordResetEmail(e)}
                id="send-verification-button"
              >
                {showLoading ? <Spinner size={16} className="animate-spin text-black" /> : "SEND VERIFICATION"}
              </button>
              {passwordResetStatus === "success" && (
                <div className="form-success text-primary">
                  <p>We have sent you an email with a link to reset your password. Please check your inbox.</p>
                </div>
              )}
            </form>
            <div className="form-footer">
              <Link to="/signup" id="navigation-onboarding">
                Don’t have an account? Sign up.
              </Link>
              <a href="mailto:support@inhouse.so" id="contact-support">
                Didn’t receive it? Contact Support
              </a>
            </div>
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default ForgotPassword;
