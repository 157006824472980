import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from "@phosphor-icons/react";
import { deleteThread } from '../../../../pages/chatpage/chatSlice';


const DeleteThreadModal = (props) => {

    const {
        open,
        setActionOnThread,
        chatId,
        thread,
        loading,
        setLoading
    } = props;

    const dispatch = useDispatch();

    const deleteThreadStatus = useSelector((state) => state.chat.deleteThreadStatus);

    const legalReviewRequestedChat = useSelector((state) => state.chat.legalReviewRequested);
    const legalReviewSubmittedChat = useSelector((state) => state.chat.legalReviewSubmitted);
    const legalReviewRequested = chatId === thread.id ? legalReviewRequestedChat : thread.legal_review_requested;
    const legalReviewSubmitted = chatId === thread.id ? legalReviewSubmittedChat : thread.legal_review_submitted;

    useEffect(() => {
        if (deleteThreadStatus === "success" || deleteThreadStatus === "failed") {
            setActionOnThread("");
            setLoading(false);
        }
    }, [deleteThreadStatus]);

    return (
        <Modal
            centered
            open={open}
            onOk={() => setActionOnThread("")}
            onCancel={() => setActionOnThread("")}
            className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
            footer={null}
        >
            <div className="w-full md:w-[600px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
                <span className="text-black text-[30px] font-[800] leading-[1]">Delete chat?</span>
                {legalReviewRequested && !legalReviewSubmitted ? (
                    <span className="text-black text-[16px] font-[500] leading-[1]">
                        This threads cannot be deleted as it has a legal review pending.
                    </span>
                ) : (
                    <span className="text-black text-[16px] font-[500] leading-[1]">
                        This will delete <span className="font-extrabold">{thread.title}</span>
                    </span>
                )}

                {legalReviewRequested && !legalReviewSubmitted ? (
                    ""
                ) : (
                    <div className="flex items-center justify-start w-full gap-4 h-full ">
                        <button
                            className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-[12px] px-[50px] rounded-[10px] w-[150px] flex items-center justify-center "
                            onClick={() => {
                                setLoading(true);
                                // deleteGivenThread(thread.id);
                                dispatch(deleteThread({ threadId: thread.id }));
                            }}
                            disabled={loading}
                            id="delete-chat-button"
                        >
                            {loading ? <Spinner size={16} className="animate-spin" /> : "Delete"}
                        </button>
                        <button
                            className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                            onClick={() => setActionOnThread("")}
                            disabled={loading}
                            id="cancel-delete-chat-button"
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default DeleteThreadModal