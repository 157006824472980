import React, { useEffect, useState } from "react";
// import { auth } from "../../firebase";
// import { createUserWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { signUp, getUserStatus, getUserDetails, signIn, signInWithGoogle } from "./AuthenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import SignUpLayout from "../../components/SignUpLayout/SignUpLayout";
import { Spinner } from "@phosphor-icons/react";
import { Checkbox } from "antd";
import google from "../../images/google.svg";
import { HorizontalDevider } from "../../components/HorizontalDevider";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notice, setNotice] = useState("");
  const [emailVerificationRequired, setEmailVerificationRequired] = useState(false);
  // const [profilePendingApproval, setProfilePendingApproval] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);

  const authError = useSelector((state) => state.auth.signuperror);
  const authStatus = useSelector((state) => state.auth.signupStatus);
  // const disabled = useSelector((state) => state.auth.status === "loading");
  // const userStatus = useSelector((state) => state.auth.userStatus);

  useEffect(() => {
    window.analytics.page("Signup page viewed");
  }, []);

  const accessToken = useSelector((state) => state.auth.accessToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);
  const urlParams = new URLSearchParams(window.location.search);

  const referralCode = urlParams.get("referral_code");
  let referredName;
  let code;
  window.location.href = "https://www.inhouse.so/auth";

  useEffect(() => {
    dispatch(getUserDetails())
  }, [window.location])

  useEffect(() => {
    // debugger;
    if (getUserDetailsStatus === "success") {
      if (user?.user_email) {
        window.location = ("/ask");
      }
    }
  }, [getUserDetailsStatus])


  if (referralCode) {
    // Split the referral_code value by '-'
    const parts = referralCode.split("-");

    // Extract the referred_name and code
    referredName = parts[0];
    if (parts.length > 1) {
      code = parts[1];
    } else {
      code = referralCode;
    }
  }
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    console.log("validation check", re.test(String(email).toLowerCase()));

    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = document.getElementById("sign-up-form");
    setShowLoading(true);
    setSignupError("");
    setNotice("");

    if (!validateEmail(email)) {
      setNotice("Please enter a valid email address.");
      setShowLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setNotice("Password must be at least 6 characters long.");
      setShowLoading(false);
      return;
    }

    // window.analytics.identify(email, {
    //   email: email,
    // });
    // window.analytics.trackForm(form, "Signed Up");
    window.analytics.track("Signed Up", { user_email: user?.user_email });
    window.localStorage.setItem("showTutorial", true);
    var url = process.env.REACT_APP_FRONTEND_BASE_ENDPOINT + "/signin";
    if (code) {
      url = process.env.REACT_APP_FRONTEND_BASE_ENDPOINT + "/signin?referral_code=" + referralCode;
    }
    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };

    dispatch(signUp({ email: email, password: password, actionCodeSettings: actionCodeSettings }));
    window.analytics.track("new signup with email initiated", { "user_type": "client", "user_email": email });
  };

  const handleGoogleSubmit = () => {
    let form = document.getElementById("sign-up-form");
    window.analytics.trackForm(form, "Signed Up with Google");
    window.analytics.track("Signed Up with Google", { user_email: user?.user_email });
    window.localStorage.setItem("showTutorial", true);
    dispatch(signInWithGoogle("signup"));
  };
  const showAlert = () => {
    if (password && confirmPassword && password !== confirmPassword) {
      return "Passwords do not match";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (authStatus === "success") {
      dispatch(signIn({ email: email, password: password }));
    } else if (authStatus === "failed") {
      // if (authError.code === "ERR_BAD_REQUEST") {
      //   window.location.href = "/signin";
      // } else if (authError.code === "auth/invalid-credential") {
      //   setSignupError(authError.message);
      // } else if (authError.code === "others") {
      if (authError?.code === "auth/verify-email") {
        setEmailVerificationRequired(true);
      }
      if (authError?.message) {
        if (authError?.code === "auth/verify-email") {
          setEmailVerificationRequired(true);
        } else {
          setSignupError(authError.message);
        }
      } else {
        setSignupError("");
      }
      // } else {
      //   navigate("/request-access");
      // }
    }
  }, [authStatus, authError]);

  useEffect(() => {
    if (authError) {
      setNotice(authError.message);
      setShowLoading(false);
    } else {
      setNotice("");
    }
  }, [authError]);

  const signupWithUsernameAndPassword = async (e) => {
    // e.preventDefault();
    // dispatch(getUserStatus({ email: email }));
    setSignupError("");
    if (password !== confirmPassword) {
      setSignupError("Passwords do not match");
      return;
    }
    if (!email) {
      return;
    }
    dispatch(signUp({ email: email, password: password }));
  };

  useEffect(() => {
    if (isAuthenticated) {
      // localStorage.setItem("accessToken", accessToken);
      // localStorage.setItem("refreshToken", refreshToken);
      dispatch(getUserDetails({ referredCode: code }));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      var userType = localStorage.getItem("userType");
      if (userType === "lawyer") {
        navigate("/lawyer");
      } else if (userType === "client") {
        if (getUserDetailsStatus === "success") {
          if (user) {
            window.analytics.identify(user.user_id, {
              email: user?.user_email,
              firstName: user?.first_name,
              lastName: user?.last_name,
            });
            if (!user.first_name || !user.last_name || !user.organization_name || !user.state) {
              navigate("/update-profile#/update-profile");
            } else {
              navigate("/ask");
            }
          }
        }
      }
    }
  }, [user]); // eslint-disable-line
  console.log("emailVerificationRequired", emailVerificationRequired);
  return (
    <SignUpLayout>
      <h2 className="sign-up-layout-title">
        {!emailVerificationRequired ? <>Start your 7-day free trial</> : <>Email verification required</>}
      </h2>
      <p className="text-[24px] mb-2 text-center font-[FoundersGrotesk-semibold]">It only takes 2 minutes and requires no credit card</p>
      <div className="container">
        <div className="row justify-content-center">
          <div className="sign-up-form-container">
            {referredName && (
              <div className="w-full flex items-center justify-center">
                <p className="mb-4 text-[18px] text-[#1E86FF] font-[700]">
                  {referredName} invited you to join InHouse. $100 credit has been unlocked
                </p>
              </div>
            )}

            {!emailVerificationRequired ? (
              <>
                <form className="sign-up-form">
                  <div className="flex items-center justify-start gap-6">
                    <button
                      type="button"
                      className="form-submit-google  disabled:opacity-50 w-[248px] h-[58px] "
                      onClick={(e) => handleGoogleSubmit()}
                      id="signUpButton"
                    >
                      <img src={google} alt="google" height="26" width="26" /> Sign Up With Google
                    </button>
                    <p className="text-[#000] text-[16px] font-[500]">Or</p>
                  </div>

                  <div className="form-component">
                    <label htmlFor="inputEmail" className="form-label">
                      Work Email
                    </label>
                    <input
                      type="email"
                      className="form-input"
                      id="inputEmail"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        // setProfilePendingApproval(false);
                      }}
                    ></input>
                  </div>
                  <div className="form-component">
                    <div className="form-label-container">
                      <label htmlFor="inputPassword" className="form-label">
                        Password
                      </label>
                      {notice !== "" && (
                        <div className="form-error" role="alert">
                          {notice}
                        </div>
                      )}
                    </div>

                    <input
                      type="password"
                      className="form-input"
                      id="inputPassword"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        // setProfilePendingApproval(false);
                      }}
                    ></input>
                  </div>
                  <div className="form-component">
                    <div className="form-label-container">
                      <label htmlFor="confirmPassword" className="form-label">
                        Re-enter password
                      </label>
                      {/* {notice !== "" && (
                    <div className="form-error" role="alert">
                      {notice}
                    </div>
                  )} */}
                    </div>

                    <input
                      type="password"
                      className="form-input"
                      id="confirmPassword"
                      placeholder="Password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        // setProfilePendingApproval(false);
                      }}
                    ></input>
                  </div>
                  {showAlert() && (
                    <div className="form-error" role="alert">
                      {showAlert()}
                    </div>
                  )}
                  <Checkbox
                    onChange={(e) => {
                      setTermsAndCondition(e.target.checked);
                    }}
                    className="font-[500]"
                  >
                    I agree to the{" "}
                    <a
                      className="text-[#1E86FF]"
                      target="_blank"
                      href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Terms%20of%20Service.pdf"
                      id="terms-link-footer"
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      className="text-[#1E86FF]"
                      target="_blank"
                      href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Privacy%20Policy.pdf"
                      id="privacy-link-footer"
                    >
                      Privacy Policy
                    </a>
                  </Checkbox>

                  <button
                    type="button"
                    className="form-submit h-[50px] disabled:opacity-50"
                    disabled={
                      authStatus === "loading" ||
                      confirmPassword !== password ||
                      showLoading ||
                      email === "" ||
                      password === "" ||
                      confirmPassword === "" ||
                      !termsAndCondition
                    }
                    onClick={(e) => handleSubmit(e)}
                    id="signUpButton"
                  >
                    {showLoading ? <Spinner size={16} className="animate-spin text-black" /> : "CREATE ACCOUNT"}
                  </button>

                  {/* <div className="divider w-full">
                <HorizontalDevider />
              </div> */}
                </form>
                <div className="form-footer" id="navigation-login">
                  <Link to="/signin">Already have an account? Login</Link>
                </div>
              </>
            ) : (
              <p class="text-center">Please verify your email address by clicking on the link we sent to your inbox</p>
            )}
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default Signup;
