// Thread.js

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedThread } from "../../pages/chatpage/chatSlice";
import { Dropdown, Space, Tooltip } from "antd";
import { PencilSimple, ShareNetwork, Trash } from "@phosphor-icons/react";
import { resetAnimation } from "../../pages/chatpage/threadsSlice";

const items = [
  {
    label: "Rename",
    icon: <PencilSimple size={10} color="#626262" className="font-[700]" />,
    key: "0",
  },
  {
    label: "Delete",
    icon: <Trash size={10} color="#626262" className="font-[700]" />,
    key: "1",
  },
  {
    label: "Share",
    icon: <ShareNetwork size={10} color="#626262" className="font-[700]" />,
    key: "2",
  },
];
const Thread = ({
  thread,
  userType,
  viewedChats,
  source,
  toggleOpen3,
  chatId,
  mode,
  clientId,
  onPerformThreadAction
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [unreadStatus, setUnreadStatus] = useState("");
  const [displayTitle, setDisplayTitle] = useState(thread.title);
  const [isTyping, setIsTyping] = useState(false);
  const threadRef = useRef(null);

  const dispatch = useDispatch();
  const shareThreadId = useSelector((state) => state.threads.shareThreadId);
  const unreadAskThreads = useSelector((state) => state.threads.unreadAskThreads);
  const unreadDraftThreads = useSelector((state) => state.threads.unreadDraftThreads);
  const clientUnreadThreadMappings = useSelector((state) => state.chat.clientUnreadThreadMappings);
  const newThreadCreated = useSelector((state) => state.chat.newThreadCreated);

  useEffect(() => {
    if (thread) {
      setDisplayTitle(thread.title);
      // setNewTitle(thread.title);
    }
  }, []);

  // useEffect(() => {
  //   if (shareThreadStatus === "success" || shareThreadStatus === "failed") {
  //     setLoading(false);
  //   }
  // }, [shareThreadStatus]);

  // useEffect(() => {
  //   if (chatId === thread.id && threadRef.current) {
  //     threadRef.current.scrollIntoView({ block: "start" });
  //   }
  // }, [chatId, thread.id]);

  useEffect(() => {
    if (newThreadCreated) {
      setIsTyping(true);
    }
  }, [newThreadCreated]);

  const getUnreadStatus = () => {
    if (source === "lawyer") {
      if (
        clientUnreadThreadMappings &&
        clientUnreadThreadMappings[clientId] &&
        clientUnreadThreadMappings[clientId]?.indexOf(thread.id) !== -1
      ) {
        return "bg-[#46D78C]";
      } else {
        return "bg-[#F5F5F5]";
      }
    }

    if (source === "ask") {
      if (unreadAskThreads.indexOf(thread.id) !== -1) {
        return "bg-[#46D78C]";
      } else {
        return "bg-[#F5F5F5]";
      }
    } else if (source === "draft") {
      if (unreadDraftThreads.indexOf(thread.id) !== -1) {
        return "bg-[#46D78C]";
      } else {
        return "bg-[#F5F5F5]";
      }
    }
  };

  useEffect(() => {
    setUnreadStatus(getUnreadStatus());
  }, [unreadDraftThreads, unreadAskThreads, clientUnreadThreadMappings, thread]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (thread.animate && isTyping) {
      const characters = thread.title.split("");
      let currentText = "";

      setDisplayTitle("");

      const typingInterval = setInterval(() => {
        if (characters.length > 0) {
          const nextChar = characters.shift();
          currentText += nextChar;
          setDisplayTitle(currentText);
        } else {
          clearInterval(typingInterval);
          dispatch(resetAnimation({ threadId: thread.id, mode: mode.toLowerCase(), animate: false }));
        }
      }, 50); // Adjust the typing speed as needed

      // Cleanup function to clear the interval on effect cleanup
      return () => clearInterval(typingInterval);
    } else {
      // If not animating, immediately set the title
      setDisplayTitle(thread.title);
    }
  }, [thread.title, thread.animate, isTyping]);

  return (
    <div ref={threadRef} key={thread.id} className="flex relative group">
      <Tooltip title={thread.title} placement="rightTop" className="hidden md:flex">
        <Link
          to={`/${source}/${thread.id}`}
          onClick={() => {
            dispatch(updateSelectedThread(thread));
          }}
          key={thread.id}
          className={`w-full py-[13px] pl-3 pr-[21px] flex items-center cursor-pointer hover:bg-[#F5F5F5] ${chatId === thread.id && "bg-[#F5F5F5]"
            } rounded-[36px]`}
        >
          <div className={`w-2 h-2 rounded-full mr-1 ${unreadStatus}`} />
          <p
            className={`flex-1 text-sm truncate text-[#0D0D0D] text-[14px] text-[400] group-hover:text-[#5e59ff] ${thread.animate ? "typing-effect" : ""
              }`}
          >
            {displayTitle ? displayTitle : thread.title}
          </p>
        </Link>
      </Tooltip>
      <Link
        to={`/${source}/${thread.id}`}
        onClick={() => {
          dispatch(updateSelectedThread(thread));
        }}
        key={thread.id}
        className={`w-full py-[13px] pl-3 pr-[21px] flex md:hidden items-center cursor-pointer ${chatId === thread.id && "bg-[#F5F5F5]"
          } rounded-[36px]`}
      >
        <div className={`w-2 h-2 rounded-full mr-1 ${unreadStatus}`} />
        <p
          className={`flex-1 text-sm truncate text-[#0D0D0D] text-[14px] text-[400] group-hover:text-[#5e59ff] ${thread.animate ? "typing-effect" : ""
            }`}
        >
          {displayTitle ? displayTitle : thread.title}
        </p>
      </Link>

      {source !== "lawyer" && (
        <Dropdown
          onOpenChange={(open) => {
            setDropdownOpen(open);
          }}
          open={dropdownOpen}
          dropdownRender={(menu) => (
            <div className="thread-action-dropdown bg-white w-[87px] p-[10px] border border-[#F3F3F3] rounded-[2px]">
              <div className="flex flex-col justify-center items-start gap-[10px]">
                {items.map((item) => (
                  <div
                    className="flex items-center text-black text-[12px] font-[400] gap-2 hover:cursor-pointer"
                    onClick={() => {
                      if (item.label === "Rename") {
                        setDropdownOpen(false);
                        onPerformThreadAction(thread, "Rename")
                      } else if (item.label === "Delete") {
                        setDropdownOpen(false);
                        onPerformThreadAction(thread, "Delete")
                      } else {
                        setDropdownOpen(false);
                        onPerformThreadAction(thread, "Share")
                      }
                    }}
                    id="thread-action-dropdown-item"
                  >
                    <div className="flex flex-shrink-0">{item.icon}</div>
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
          )}
          trigger={["click"]}
          className={`absolute md:hidden right-2 inset-y-1/2 transform -translate-y-1/2 md:group-hover:flex cursor-pointer bg-white ${chatId === thread.id ? "flex" : "hidden"
            }`}
        >
          <Space className="">
            <div className="edit-dropdown-button w-6 h-6 rounded-full bg-white flex justify-center items-center gap-[2px]">
              <div className="w-1 h-1 rounded-full bg-[#D9D9D9]" />
              <div className="w-1 h-1 rounded-full bg-[#D9D9D9]" />
              <div className="w-1 h-1 rounded-full bg-[#D9D9D9]" />
            </div>
          </Space>
        </Dropdown>
      )}
    </div>
  );
};

export default Thread;
