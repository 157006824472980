import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { extractErrors } from "../helpers";
import axios from "axios";

const BASE_ENDPOINT = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;

export const sendReferralEmail = createAsyncThunk("myReferral/sendReferralEmail", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.post(`${BASE_ENDPOINT}/api/v0/user/trigger-email`, data, { headers: headers });
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

const myReferralSlice = createSlice({
  name: "myReferral",
  initialState: {
    sendReferralEmailStatus: "idle",
    sendReferralEmailError: "",
  },
  reducers: {
    resetMyReferralStates: (state, action) => {
      state.sendReferralEmailStatus = "idle";
      state.sendReferralEmailError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendReferralEmail.pending, (state) => {
        state.sendReferralEmailStatus = "loading";
        state.sendReferralEmailError = "";
      })
      .addCase(sendReferralEmail.fulfilled, (state, action) => {
        state.sendReferralEmailStatus = "success";
        state.sendReferralEmailError = "";
      })
      .addCase(sendReferralEmail.rejected, (state, action) => {
        state.sendReferralEmailStatus = "failed";
        state.sendReferralEmailError = action.payload?.error;
      });
  },
});

export const { resetMyReferralStates } = myReferralSlice.actions;

export default myReferralSlice.reducer;
