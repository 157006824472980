import React from "react";
import PropTypes from "prop-types";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./SignUpLayout.css";
import useHotjar from "../chat/useHotjar";

const SignUpLayout = ({ children, showGradient = true }) => {
  useHotjar();

  return (
    <div className="sign-up-layout">
      <Header />
      {/* {showGradient && <div className="sign-up-gradient" />} */}
      <main>{children}</main>
      <Footer />
    </div>
  );
};

SignUpLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SignUpLayout;
