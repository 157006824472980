import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { extractErrors } from "../helpers";
import axios from "axios";

const BASE_ENDPOINT = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;

export const getAllThreadsV2 = createAsyncThunk("threads/getAllThreadsV2", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/user/list-threads`;
    const response = await axios.get(url, { headers: headers });
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error, data: data });
  }
});

export const markThreadRead = createAsyncThunk("threads/markThreadRead", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data.threadId}/mark-read`;
    const response = await axios.get(url, { headers: headers });
    return {
      response: response.data,
      index: data.threadId,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getTitle = createAsyncThunk("threads/getTitle", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data.thread[0]}/get-title`;
    const response = await axios.get(url, { headers: headers });
    // const response = null;
    return {
      response: response.data,
      index: data.thread[1],
      mode: data.mode,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const generateSharedId = createAsyncThunk("chat/generateSharedId", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/share/${data.threadId}`;
    const response = await axios.post(url, {}, { headers: headers });
    return { response: response.data, request: data };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

const ThreadsSlice = createSlice({
  name: "chat",
  initialState: {
    askThreads_status: "idle",
    askThreads_error: null,
    askThreadsList: [],
    notUpdatedAskThreads: [],

    draftThreads_status: "idle",
    draftThreads_error: null,
    draftThreadsList: [],
    notUpdatedDraftThreads: [],

    getTitleStatus: "idle",
    getTitleError: null,

    acceptLegalReviewStatus: "idle",
    acceptLegalReviewError: null,

    rejectLegalReviewStatus: "idle",
    rejectLegalReviewError: null,

    unreadAskThreads: [],
    unreadDraftThreads: [],
    shareThreadStatus: "idle",
    shareThreadError: null,
    shareThreadId: "",
    currentThread: null,
  },
  reducers: {
    updateThreadTitleLocal: (state, action) => {
      if (action.payload.mode === "ask") {
        let tempArray = [...state.askThreadsList];
        let index = tempArray.findIndex((thread) => thread.id === action.payload.threadId);

        // Check if a thread was found
        if (index !== -1) {
          tempArray[index].title = action.payload.title;
          if (action.payload?.animate) {
            tempArray[index].animate = action.payload.animate;
          }
          state.askThreadsList = tempArray;
        } else {
          console.warn("Thread not found in askThreadsList with ID:", action.payload.threadId);
        }
      } else {
        let tempArray = [...state.draftThreadsList];
        let index = tempArray.findIndex((thread) => thread.id === action.payload.threadId);

        // Check if a thread was found
        if (index !== -1) {
          tempArray[index].title = action.payload.title;
          if (action.payload?.animate) {
            tempArray[index].animate = action.payload.animate;
          }
          state.draftThreadsList = tempArray;
        } else {
          console.warn("Thread not found in draftThreadsList with ID:", action.payload.threadId);
        }
      }
    },
    updateThreadFeedBackLocal: (state, action) => {
      if (action.payload.mode === "ask") {
        let tempArray = [...state.askThreadsList];
        let index = tempArray.findIndex((thread) => thread.id === action.payload.threadId);

        // Check if a thread was found
        if (index !== -1) {
          tempArray[index].legal_review_feedback = action.payload.legal_review_feedback;

          state.askThreadsList = tempArray;
        } else {
          console.warn("Thread not found in askThreadsList with ID:", action.payload.threadId);
        }
      } else {
        let tempArray = [...state.draftThreadsList];
        let index = tempArray.findIndex((thread) => thread.id === action.payload.threadId);

        // Check if a thread was found
        if (index !== -1) {
          tempArray[index].legal_review_feedback = action.payload.legal_review_feedback;

          state.draftThreadsList = tempArray;
        } else {
          console.warn("Thread not found in draftThreadsList with ID:", action.payload.threadId);
        }
      }
    },
    addNewThreadLocal: (state, action) => {
      if (action.payload.mode === "ask") {
        state.askThreadsList = [action.payload.data, ...state.askThreadsList];
      } else {
        state.draftThreadsList = [action.payload.data, ...state.draftThreadsList];
      }
    },
    removeDeletedThreadFromList: (state, action) => {
      if (action.payload.mode === "ask") {
        state.askThreadsList = state.askThreadsList.filter((thread) => thread.id !== action.payload.threadId);
      } else {
        state.draftThreadsList = state.draftThreadsList.filter((thread) => thread.id !== action.payload.threadId);
      }
    },
    resetLegalReviewStatus: (state) => {
      state.acceptLegalReviewStatus = "idle";
      state.acceptLegalReviewError = null;
      state.rejectLegalReviewStatus = "idle";
      state.rejectLegalReviewError = null;
    },
    addUnreadAskThread: (state, action) => {

      if (state.unreadAskThreads.indexOf(action.payload) === -1) {
        state.unreadAskThreads.push(action.payload);
      }
    },
    addUnreadDraftThread: (state, action) => {

      if (state.unreadDraftThreads.indexOf(action.payload) === -1) {
        state.unreadDraftThreads.push(action.payload);
      }
    },
    deleteUnreadAskThread: (state, action) => {
      state.unreadAskThreads = state.unreadAskThreads.filter((thread) => thread !== action.payload);
    },
    deleteUnreadDraftThread: (state, action) => {
      state.unreadDraftThreads = state.unreadDraftThreads.filter((thread) => thread !== action.payload);
    },
    resetThreadStates: (state, action) => {
      state.shareThreadId = "";
      state.shareThreadStatus = "idle";
      state.shareThreadError = null;
    },
    resetAnimation: (state, action) => {
      if (action.payload.mode === "ask") {
        let tempArray = [...state.askThreadsList];
        let index = tempArray.findIndex((thread) => thread.id === action.payload.threadId);

        // Check if a thread was found
        if (index !== -1) {
          // tempArray[index].title = action.payload.title;
          // if (action.payload?.animate) {
          tempArray[index].animate = action.payload.animate;
          // }
          state.askThreadsList = tempArray;
        } else {
          console.warn("Thread not found in askThreadsList with ID:", action.payload.threadId);
        }
      } else {
        let tempArray = [...state.draftThreadsList];
        let index = tempArray.findIndex((thread) => thread.id === action.payload.threadId);

        // Check if a thread was found
        if (index !== -1) {
          // tempArray[index].title = action.payload.title;
          // if (action.payload?.animate) {
          tempArray[index].animate = action.payload.animate;
          // }
          state.draftThreadsList = tempArray;
        } else {
          console.warn("Thread not found in draftThreadsList with ID:", action.payload.threadId);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllThreadsV2.pending, (state, action) => {
        if (action.meta.arg.mode === "ask") {
          state.askThreads_status = "loading";
          state.askThreads_error = "";
        } else {
          state.draftThreads_status = "loading";
          state.draftThreads_error = "";
        }
      })
      .addCase(getAllThreadsV2.rejected, (state, action) => {
        if (action.payload.data.mode === "ask") {
          state.askThreads_status = "rejected";
          state.askThreads_error = "";
        } else {
          state.draftThreads_status = "rejected";
          state.draftThreads_error = "";
        }
      })
      .addCase(getAllThreadsV2.fulfilled, (state, action) => {
        if (action.payload.data.mode === "ask") {
          state.askThreads_status = "success";
          state.askThreads_error = "";
          state.askThreadsList = action.payload.response.threads;
          if (action.payload.response.unread_thread_ids) {
            var unread_ids = action.payload.response.unread_thread_ids;
            var unread_ask_thread_ids = state.askThreadsList.filter((thread) => unread_ids.indexOf(thread.id) !== -1);
            state.unreadAskThreads = unread_ask_thread_ids.map((thread) => thread.id);
          }
          var notUpdatedThreads = [];
          for (var i = 0; i < state.askThreadsList.length; i++) {
            if (state.askThreadsList[i].title_source === "default") {
              notUpdatedThreads.push([state.askThreadsList[i].id, i]);
              // break
            }
          }
          state.notUpdatedAskThreads = notUpdatedThreads;
        } else {
          state.draftThreads_status = "success";
          state.draftThreads_error = "";
          state.draftThreadsList = action.payload.response.threads;
          if (action.payload.response.unread_thread_ids) {
            var unread_ids = action.payload.response.unread_thread_ids;
            var unread_draft_thread_ids = state.draftThreadsList.filter(
              (thread) => unread_ids.indexOf(thread.id) !== -1
            );
            state.unreadDraftThreads = unread_draft_thread_ids.map((thread) => thread.id);
          }
          var notUpdatedThreads = [];
          for (var i = 0; i < state.draftThreadsList.length; i++) {
            if (state.draftThreadsList[i].title_source === "default") {
              notUpdatedThreads.push([state.draftThreadsList[i].id, i]);
              // break
            }
          }
          state.notUpdatedDraftThreads = notUpdatedThreads;
        }
      })

      // getTitle handlers
      .addCase(getTitle.pending, (state) => {
        state.getTitleStatus = "loading";
        state.getTitleError = "";
      })
      .addCase(getTitle.fulfilled, (state, action) => {
        state.getTitleStatus = "idle";
        state.getTitleError = "";
        if (action.payload.mode === "ask") {
          state.askThreadsList[action.payload.index].title = action.payload.response.title;
        } else {
          state.draftThreadsList[action.payload.index].title = action.payload.response.title;
        }
      })
      .addCase(getTitle.rejected, (state, action) => {
        state.getTitleStatus = "failed";
        state.getTitleError = action.payload.error;
      })
      // shareThread handlers
      .addCase(generateSharedId.pending, (state) => {
        state.shareThreadStatus = "loading";
        state.shareThreadError = "";
      })
      .addCase(generateSharedId.fulfilled, (state, action) => {
        state.shareThreadError = "";
        const afterShared = action.payload.response?.shared_thread_url.split("/shared/")[1];
        state.shareThreadId = afterShared;
        state.shareThreadStatus = "success";

        if (action.payload.request.mode === "ask") {
          let tempArray = [...state.askThreadsList];
          let index = tempArray.findIndex((thread) => thread.id === action.payload.request.threadId);

          // Check if a thread was found
          if (index !== -1) {
            tempArray[index].shared_id = afterShared;

            state.askThreadsList = tempArray;
          } else {
            console.warn("Thread not found in askThreadsList with ID:", action.payload.request.threadId);
          }
        } else {
          let tempArray = [...state.draftThreadsList];
          let index = tempArray.findIndex((thread) => thread.id === action.payload.request.threadId);

          // Check if a thread was found
          if (index !== -1) {
            tempArray[index].shared_id = afterShared;

            state.draftThreadsList = tempArray;
          } else {
            console.warn("Thread not found in draftThreadsList with ID:", action.payload.threadId);
          }
        }
      })
      .addCase(generateSharedId.rejected, (state, action) => {
        state.shareThreadStatus = "failed";
        state.shareThreadError = extractErrors(action.payload.error);
      });
  },
});

export default ThreadsSlice.reducer;
export const {
  resetLegalReviewStatus,
  addUnreadAskThread,
  addUnreadDraftThread,
  deleteUnreadAskThread,
  deleteUnreadDraftThread,
  removeDeletedThreadFromList,
  updateThreadTitleLocal,
  addNewThreadLocal,
  updateThreadFeedBackLocal,
  resetThreadStates,
  resetAnimation,
} = ThreadsSlice.actions;
