// src/components/Header/Header.js

import React from "react";
import "./Header.css"; // Import the associated CSS file
import logoImage from "../../images/lexxa-logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../../pages/UserAuthentication/AuthenticationSlice";
import { SignOutButton } from "@clerk/clerk-react";

const Header = () => {
  const navigate = useNavigate();
  const isSignInPage = window.location.pathname.includes("signin");
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  return (
    <header className="app-header">
      <a href="https://www.inhouse.so/" target="_blank" id="back-to-landing-link">
        <img
          id="back-to-landing-link"
          src={logoImage}
          alt="Logo"
          className="logo-image"
          onClick={() => {
            navigate("");
          }}
        />
      </a>

      {true ? (
        <>
          <button
            className="logout-button"
            id="logout-button"
          >
            <SignOutButton redirectUrl="https://inhouse.so" />
          </button>
        </>
      ) : (
        !isSignInPage && (
          <>
            <button
              className="login-button-desktop"
              onClick={() => {
                window.location.href = "https://inhouse.so/auth";
              }}
              id="navigation-login-button-desktop"
            >
              Already have an account? Login
              {/* <p>{user?.first_name}</p> */}
            </button>
            <button
              className="login-button"
              onClick={() => {
                window.location.href = "https://inhouse.so/auth";
              }}
              id="navigation-login-button-mobile"
            >
              Login
              {/* <p>{user?.first_name}</p> */}
            </button>
          </>
        )
      )}
    </header>
  );
};

export default Header;
