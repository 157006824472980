import React from "react";
import spin from "../../images/spin.svg";
import ask from "../../images/ask.svg";
import draft from "../../images/draft.svg";
// import search from "../../images/search.svg";
// import paperclip from "../../images/PaperClip.svg";
import DynamicExample from "../../components/chat/DynamicExample";
import { Descriptions } from "antd";

export const sampleDynamicThreadsDraft = [
  "Draft Privacy Policy",
  "Draft Shareholder Agreements",
  "Draft Non-Disclosure Agreement",
  "Draft an Employment Contract",
  "Draft our Employee Handbook",
  "Draft Terms of Use",
  "Draft Intellectual Property Assignment Agreements",
  "Draft a Brokerage Agreement",
];

export const sampleDynamicThreadsDraftMobile = [
  "Draft Shareholder Agreements",
  "Draft an Employment Contract",
  "Draft a Brokerage Agreement",
];


// export const sampleDynamicThreadsAsk = [
//   "Draft a terms of use",
//   "Draft a privacy policy",
//   "Draft an NDA (Non Disclosure Agreement",
//   "Draft an MSA (Master Service Agreement)",
//   "Can I make this claim about our new makeup?",
//   "How do I protect my IP?",
//   "Can I use personal assets for business?",
//   "Have I selected correct business structure?",
// ];

// export const sampleDynamicThreadsAsk = [
//   {
//     title: "Is it legal to enforce a non-compete?",
//     description: "For an independent contractor who is doing design work...",
//     icon: ask,
//   },
//   {
//     title: "Write a demand letter",
//     description: "For a client who owes King Tide money for services we...",
//     icon: draft,
//   },
//   {
//     title: "Draft an Employment Agreement ",
//     description: "For a new full time designer I’m hiring in Mexico",
//     icon: draft,
//   },
//   {
//     title: "Review Terms of Service",
//     description: "For a SaaS company that is collecting personal data in the United...",
//     icon: search,
//   },
// ];.

export const sampleDynamicThreadsAsk = {
  left: [
    {
      title: "Draft a Terms of Service",
      description: "",
      icon: draft,
    },
    {
      title: "Draft a Privacy Policy",
      description: "",
      icon: draft,
    },
    {
      title: "Draft a Master Service Agreement",
      description: "",
      icon: draft,
    },
    {
      title: "Draft an Employment Contract",
      description: "",
      icon: draft,
    },
    {
      title: "Draft a Work for Hire Agreement",
      description: "",
      icon: draft,
    },
    {
      title: "Draft a Customer Contract",
      description: "",
      icon: draft,
    },
    {
      title: "Draft an NDA",
      description: "",
      icon: draft,
    },
    {
      title: "Draft an Employee Confidentiality and IP Assignment",
      description: "",
      icon: draft
    },
    {
      title: "Draft a cease and desist",
      description: "",
      icon: draft,
    },
    {
      title: "Draft a demand letter",
      description: "",
      icon: draft,
    },
    {
      title: "Draft a settlement agreement",
      description: "",
      icon: draft,
    }
  ],
  right: [
    {
      title: "Highlight redflags in this customer agreement",
      description: "",
      icon: "paperclip",
    },
    {
      title: "Explain in plain English our bylaws",
      description: "",
      icon: ask,
    },
    {
      title: "Do I need to give notice before terminating a contractor?",
      description: "",
      icon: ask,
    },
    {
      title: "What should we do in response to this letter?",
      description: "",
      icon: ask,
    },
    {
      title: "Does my employee have a viable claim against us?",
      description: "",
      icon: ask,
    },
    {
      title: "How do I secure our IP?",
      description: "",
      icon: "paperclip",
    },
    {
      title: "Should I accept this indemnification clause?",
      description: "",
      icon: ask,
    },
    {
      title: "Negotiate this agreement more aggressively",
      description: "",
      icon: ask,
    },
    {
      title: "Are these terms market?",
      description: "",
      icon: ask,
    },
    {
      title: "How much equity will I lose in a round of financing?",
      description: "",
      icon: ask,
    },
    {
      title: "Can I require employees to work weekends?",
      description: "",
      icon: ask,
    },
  ]
};

export const sampleDynamicThreadsAskMobile = sampleDynamicThreadsAsk;

const ChatHome = ({ updateMessage, handleSend }) => {
  const isDraft = window.location.pathname === "/draft";

  const sampleDynamicThreads = isDraft ? sampleDynamicThreadsDraft : sampleDynamicThreadsAsk;
  const sampleDynamicThreadsMobile = isDraft ? sampleDynamicThreadsDraftMobile : sampleDynamicThreadsAskMobile;

  return (
    <div className="overflow-y-scroll w-full">
      {/* <div className="flex flex-col items-center justify-center gap-8">
        <img width={104} height={45} src={Lexxa} alt="Lexxa Logo" />
        <p className="text-black text-[24px] md:text-[48px] font-[700] leading-[1] ">
          {isDraft ? "What do you want to draft?" : "What’s your legal question?"}
        </p>
      </div> */}
      <div className="flex items-center justify-between w-full mb-[12px] ">
        <p className="text-black text-[16px] font-[500]">Examples: </p>
        {/* <button className="text-black text-[16px] font-[500] flex items-center gap-[8px]">
          Refresh
          <img height={24} width={24} src={spin} />
        </button> */}
      </div>
      <div className="flex flex-col md:flex-row gap-4 h-[350px]  overflow-scroll">
        <div className="block grid-cols-1 gap-x-4 gap-y-3 w-full md:w-1/2  md:mb-[40px]">
          {sampleDynamicThreads.left.map((example, index) => (
            <DynamicExample
              key={index} // Add a unique key
              example={example}
              onClick={() => {
                updateMessage(example.title);
                // handleSend({ messageInput: example.title });
              }}
            />
          ))}
        </div>
        <div className="block w-full md:w-1/2 grid-cols-1 gap-x-4 gap-y-3  mb-[40px]">
          {sampleDynamicThreads.right.map((example, index) => (
            <DynamicExample
              key={index} // Add a unique key
              example={example}
              onClick={() => {
                updateMessage(example.title);
                // handleSend({ messageInput: example.title });
              }}
            />
          ))}
        </div>
      </div>
      {/* <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4 gap-y-3 md:hidden w-full px-4">
        {sampleDynamicThreadsMobile.left.map((example, index) => (
          <DynamicExample
            key={index} // Add a unique key
            example={example}
            onClick={() => {
              updateMessage(example.title);
              // handleSend({ messageInput: example.title });
            }}
          />
        ))}
      </div> */}
    </div>
  );
};

export default ChatHome;
