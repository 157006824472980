import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { extractErrors } from "../helpers";
import axios from "axios";
import { attemptAuthenticationRefresh } from "../UserAuthentication/AuthenticationSlice";

const BASE_ENDPOINT = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;

export const saveUserProfile = createAsyncThunk("onboarding/saveUserProfile", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.post(`${BASE_ENDPOINT}/api/v0/user/update-client-info`, data, { headers: headers });
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const saveUserProfileImage = createAsyncThunk("onboarding/saveUserProfileImage", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.post(`${BASE_ENDPOINT}/api/v0/user/upload-profile-pic`, data, { headers: headers });
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getMessageType = createAsyncThunk("onboarding/getMessageType", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.post(
      `${BASE_ENDPOINT}/api/v0/message/get-type/${data.message}`,
      {},
      { headers: headers }
    );
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    status: "idle",
    error: "",
  },
  reducers: {
    resetOnboardingStates: (state) => {
      state.status = "idle";
      state.error = "";
    },
    setOnboardingError: (state, data) => {
      // debugger;
      state.error = data.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveUserProfile.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(saveUserProfile.fulfilled, (state, action) => {
        const user = JSON.parse(localStorage.getItem("user"));
        var user_details = action.payload.data;
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            first_name: user_details?.first_name,
            last_name: user_details?.last_name,
            organization_name: user_details?.organization_name,
            state: user_details?.state,
            age_of_business: user_details?.age_of_business,
            num_employees: user_details?.num_employees,
            current_legal_source: user_details?.current_legal_source,
            legal_work_frequency: user_details?.legal_work_frequency,
            legal_work_type: user_details?.legal_work_type,
            annual_legal_spend: user_details?.annual_legal_spend,
            state_incorporation: user_details?.state_incorporation,
            entity_type: user_details?.entity_type,
            business_purpose: user_details?.business_purpose,
          })
        );
        state.status = "success";
        state.error = "";
        // state.user = user_details;
        localStorage.removeItem("showForm");
      })
      .addCase(saveUserProfile.rejected, (state, action) => {
        state.status = "failed";
        if (action?.payload?.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
          state.error = { genericErrors: ["Unauthorized"], fieldErrors: {} };
          return;
        }
        var errors = {
          fieldErrors: {},
          genericErrors: [],
        };
        if (action.payload?.error?.response?.data?.detail) {
          var backendErrors = action.payload?.error?.response?.data?.detail;
          backendErrors.forEach((error) => {
            if (error.type === "missing") {
              errors["fieldErrors"][error.loc[2]] = "This field is required";
            } else {
              errors["fieldErrors"][error.loc[2]] = error.msg;
            }
          });
          state.error = errors;
        }
      })
      .addCase(saveUserProfileImage.pending, (state) => {
        state.profileImageUploadStatus = "loading";
        state.profileImageUploadError = "";
      })
      .addCase(saveUserProfileImage.fulfilled, (state, action) => {
        state.profileImageUploadStatus = "success";
        state.profileImageUploadError = "";
        state.userProfileImage = action.payload.response.profile_pic;
      })
      .addCase(saveUserProfileImage.rejected, (state, action) => {
        state.profileImageUploadStatus = "failed";
        state.profileImageUploadError = action.payload?.error;
      })
      .addCase(getMessageType.pending, (state) => {
        state.getMessageTypeStatus = "loading";
        state.getMessageTypeError = "";
      })
      .addCase(getMessageType.fulfilled, (state, action) => {
        state.getMessageTypeStatus = "success";
        state.getMessageTypeError = "";
        state.messageType = action.payload.response.message_type;
        localStorage.setItem("messageType", action.payload.response.message_type);
      })
      .addCase(getMessageType.rejected, (state, action) => {
        state.getMessageTypeStatus = "failed";
        state.getMessageTypeError = action.payload?.error;
        localStorage.setItem("messageType", "ask");
      });
  },
});

export default onboardingSlice.reducer;

export const { resetOnboardingStates, setOnboardingError } = onboardingSlice.actions;
