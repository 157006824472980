import { Dropdown, Space, Button } from "antd";

import {
    NotePencil,
} from "@phosphor-icons/react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Threads from "./Threads/index.js";
import template from "../../images/templates.svg";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout, setImage, getPortalSession } from "../UserAuthentication/AuthenticationSlice";
import PREVIOUS from "../../images/previous.svg";
import { SignOutButton, useUser } from "@clerk/clerk-react";
import { clearEditTemplateLocal } from "./chatSlice.js";


const SideNav = ({ mode, chatId, newThreadCreated, displayName, subscriptionType, setNewProjectClicked }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    // const image = useSelector((state) => state.auth.image);
    // const imageLawyer = useSelector((state) => state.auth.imageLawyer);
    const askThreadsList = useSelector((state) => state.threads.askThreadsList);
    // const subscriptionStatusFetch = useSelector((state) => state.auth.subscriptionStatusFetch);
    // const portalSessionUrl = useSelector((state) => state.auth.portalSessionUrl);

    const getPortalSessionUrl = () => {
        dispatch(getPortalSession());
    }
    // const signout = () => {
    //     dispatch(handleLogout());
    // };
    const items = [
        {
            label: "My Account",
            key: "0",
        },
        // {
        //   label: "My Referral",
        //   key: "1",
        // },
        {
            label: "Logout",
            key: "2",
        },
    ];
    const { user } = useUser();

    // useEffect(() => {
    //     if (user?.profileImage) {
    //         // debugger;
    //         dispatch(setImage(user.profileImage));
    //     } else {
    //         const firstNameInitial = user?.first_name ? user.first_name[0].toUpperCase() : "";
    //         const lastNameInitial = user?.last_name ? user.last_name[0].toUpperCase() : "";
    //         const name = user?.first_name ? firstNameInitial + lastNameInitial : user?.user_email?.[0]?.toUpperCase();
    //         dispatch(setImage(name))
    //     }
    // }, [user]);

    // useEffect(() => {
    //     if (["free", null, "Free-Plan-USD-Monthly"].indexOf(subscriptionType) === -1) {
    //         getPortalSessionUrl();
    //     }
    // }, [subscriptionType, subscriptionStatusFetch]);

    return (
        <div
            className={`flex-none side-nav h-full overflow-hidden fixed md:static top-0 z-40 transform md:translate-x-0 -translate-x-full
                 md:flex w-[208px] pt-[30px] pb-[30px] px-3 flex-col justify-between transition-transform duration-300 ease-in-out md:left-0`}
        >
            <div className="side-nav-threads flex flex-col gap-6 h-[96%] md:h-full relative">
                <div className="flex flex-col w-full items-center justify-between md:justify-center">
                    <Button
                        onClick={() => {
                            window.analytics.track("New project clicked", { "num_threads": askThreadsList.length, user_email: user?.user_email });
                            dispatch(clearEditTemplateLocal());
                            navigate(`/${mode.toLowerCase()}`);
                            try {
                                setNewProjectClicked(true);
                            }
                            catch (e) {
                                console.log(e);
                            }
                        }}
                        type="text"
                        className={`new-project flex gap-[12px] items-center pl-[20px] md:pl-[10px] border-black hover:border-[#5e59ff]  flex-shrink-0  text-[14px] font-[500] hover:text-[#5E59FF]`}
                        id={mode === "Ask" ? "navigation-ask" : "navigation-draft"}
                    >
                        New Project
                        {/* <img height={18} width={18} src={write} /> */}
                        <NotePencil size={20} />
                    </Button>
                    <button
                        onClick={() => {
                            navigate(`/template`);
                        }}
                        type="text"
                        className={`mt-4 new-project w-full flex justify-start gap-[12px] items-center pl-[20px] md:pl-[19px] border-black ${location.pathname === "/template" && "text-[#5e59ff]"
                            }  flex-shrink-0  text-[14px] font-[500] hover:text-[#5E59FF] mb-[8px]`}
                    >
                        <img height={18} width={18} src={template} />
                        Templates
                        {/* <NotePencil size={20} /> */}
                    </button>
                    <h2
                        className={`mt-4 w-full flex justify-start gap-[12px] items-center pl-[20px] md:pl-[19px] border-black flex-shrink-0  text-[14px] font-[500] `}
                    >
                        <img height={18} width={18} src={PREVIOUS} />
                        Previous
                    </h2>
                </div>

                <div className="w-full h-full overflow-scroll  flex flex-col mb-4">

                    <Threads
                        mode={mode}
                        chatId={chatId}
                        newThreadCreated={newThreadCreated}

                    />

                </div>
            </div>
            <Dropdown
                overlayStyle={{ zIndex: 40 }}
                dropdownRender={(menu) => (
                    <div className="nav-footer-dropdown flex flex-col items-start w-full gap-4 p-4 bg-white rounded-[5px] border">
                        <ul>
                            {(["free", null, "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired", "trial"].indexOf(subscriptionType) === -1) ?
                                <li className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px] cursor-pointer">
                                    <span
                                        type="text"
                                        className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100"
                                        id="navigation-account"
                                        // href={portalSessionUrl} data-cb-type="portal"
                                        onClick={getPortalSessionUrl}
                                        target="blank"
                                    >
                                        Manage Subscription
                                    </span>
                                </li> : ""}
                            {items.map((item) => (
                                <>
                                    {item.label !== "Logout" ? (
                                        <li onClick={() => {
                                            if (item.label === "My Account") {
                                                navigate("/account");
                                            } else {
                                                navigate("/my-referral");
                                            }
                                        }} className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px]  cursor-pointer">
                                            <p
                                                type="text"
                                                className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100"

                                                id="navigation-account"
                                            >
                                                {item.label}
                                            </p></li>
                                    ) : (
                                        <li className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px]  cursor-pointer">
                                            {/* <p
                                                type="text"
                                                className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100"

                                                id="logout-button"
                                            >
                                                {item.label}
                                            </p> */}
                                            <SignOutButton redirectUrl="https://inhouse.so" />
                                        </li>
                                    )}
                                </>
                            ))}
                        </ul>
                    </div>
                )}
                trigger={["click"]}
                className="hover:cursor-pointer hover:text-[#5e59ff]"
            >
                <Space className="flex items-center gap-4 border-[1px] p-2 border-[#000]">
                    <div className="flex h-[35px] w-[35px] flex-shrink-0 rounded-full overflow-hidden">
                        {/* {image && image?.length > 2 ? ( */}
                        <img
                            src={user?.imageUrl}
                            alt="profile"
                            className="flex-shrink-0"
                            style={{ objectFit: "cover", width: "100%", height: "100%" }}
                        />
                    </div>
                    <div className=" text-[16px] flex truncate max-w-[104px]">
                        <p className="font-[700] w-full truncate hover:text-[#5e59ff]">{user?.unsafeMetadata?.organization_name ? user?.unsafeMetadata?.organization_name : <>{user?.firstName ? user?.firstName : user?.primaryEmailAddress.emailAddress}</>} </p>
                    </div>
                </Space>
            </Dropdown>
        </div>
    )
}

export default SideNav;